import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../core/utils';
import { EmojiGroup } from '../chat-emoji.model';
import { allEmoji } from './chat-emoji-picker.data';

@Component({
    selector: 'app-chat-emoji-picker',
    templateUrl: './chat-emoji-picker.component.html',
    styleUrls: ['./chat-emoji-picker.component.scss']
})
export class ChatEmojiPickerComponent implements OnInit {
    allEmoji: EmojiGroup[] = allEmoji;
    filteredEmoji: EmojiGroup[] = allEmoji;
    private positionRelativeToElement: ElementRef;
    constructor(private dialogRef: MatDialogRef<ChatEmojiPickerComponent>,
                @Inject(MAT_DIALOG_DATA) public options: { positionRelativeToElement: ElementRef }) {
        this.positionRelativeToElement = options.positionRelativeToElement;
    }

    ngOnInit() {
        const matDialogConfig = new MatDialogConfig();
        const rect: DOMRect = this.positionRelativeToElement.nativeElement.getBoundingClientRect();

        matDialogConfig.position = {right: `30px`, top: `${(rect.top) - 300}px`};
        this.dialogRef.updatePosition(matDialogConfig.position);
    }

    onSelection(emoji) {
        this.dialogRef.close(emoji);
    }

    fixCode(code) {
        return code.replace(/U\+/g, '&#x').replace(/\s/g, ';');
    }

    onKeyDown(search: HTMLInputElement) {
        console.log(search.value);
        const tempEmojiList: EmojiGroup[] = [];
        this.allEmoji.forEach(g => {
            const tempObj = {
                group: g.group,
                emojis: g.emojis.filter(e => e.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1)
            };
            if (!Utils.isEmpty(tempObj.emojis)) {
                tempEmojiList.push(tempObj);
            }
        });
        this.filteredEmoji = tempEmojiList;
    }
}
