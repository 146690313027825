import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Note } from '../_models/note';

@Injectable({
    providedIn: 'root'
})
export class NoteService {

    constructor(private http: HttpClient) {
    }

    getNote(id: any): Observable<Note> {
        return this.http.get<any>(`${environment.apiUrl}/api/note/${id}`).pipe(
            map(res => new Note(res))
        );
    }

    getNotesByAssignment(assignmentId: any): Observable<Note[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/note/assignment/${assignmentId}`).pipe(
            map(res => res.map(it => new Note(it)))
        );
    }

    save(note: any): Observable<Note> {
        return this.http.post<Note>(`${environment.apiUrl}/api/note`, note).pipe(
            map(res => new Note(res))
        );
    }

    update(note: any): Observable<Note> {
        return this.http.put<Note>(`${environment.apiUrl}/api/note`, note).pipe(
            map(res => new Note(res))
        );
    }
}
