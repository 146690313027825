<mat-card *ngIf='user$ | async as user' appearance='outlined' class='user'>
    <mat-card-content>
        <form (ngSubmit)='update()' [formGroup]='userForm'>
            <div style='margin: 20px;'>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='firstName' matInput placeholder=''>
                        <mat-label>First Name</mat-label>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='lastName' matInput placeholder=''>
                        <mat-label>Last Name</mat-label>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='userName' matInput placeholder=''>
                        <mat-label>Username</mat-label>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='email' matInput placeholder=''>
                        <mat-label>Email</mat-label>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngNoForm>
                        <mat-label>Roles</mat-label>
                        <mat-chip-grid #chipGridRolesBox>
                            <mat-chip-row (removed)='removeRole(role)' *ngFor='let role of roles'>
                                {{ role.roleName }}
                                <mat-icon *ngIf='removable' matChipRemove>cancel</mat-icon>
                            </mat-chip-row>
                            <input #roleInput [matAutocomplete]='role' [matChipInputFor]='chipGridRolesBox'
                                placeholder='Add role..'>
                        </mat-chip-grid>
                        <mat-autocomplete #role='matAutocomplete' (optionSelected)='selectedRole($event)'>
                            <mat-option *ngFor='let role of filteredRoles | async' [value]='role'>
                                {{ role.roleName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field ngNoForm>
                        <mat-label>Parents</mat-label>
                        <mat-chip-grid #chipGridParentBox>
                            <mat-chip-row (removed)='removeParent(parent)' *ngFor='let parent of parents'>
                                {{ parent.firstName }} {{ parent.lastName }}
                                <mat-icon *ngIf='removable' matChipRemove>cancel</mat-icon>
                            </mat-chip-row>
                            <input #roleInput [matAutocomplete]='parent' [matChipInputFor]='chipGridParentBox'
                                placeholder='Add parent..'>
                        </mat-chip-grid>
                        <mat-autocomplete #parent='matAutocomplete' (optionSelected)='selectedParent($event)'>
                            <mat-option *ngFor='let user of filteredParents | async' [value]='user'>
                                {{ user.firstName }} {{ user.lastName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!--        <div>-->
                <!--          <mat-form-field class="parents-chip-list" appearance="fill">-->
                <!--            <mat-label>Parents</mat-label>-->
                <!--            <mat-chip-grid #chipGrid aria-label="Parents">-->
                <!--              <mat-chip-row *ngFor="let parent of parents" [editable]="true"-->
                <!--                [removable]="removable" (removed)="removeParent(parent)">-->
                <!--                {{parent.firstName}} {{parent.lastName}}-->
                <!--                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
                <!--              </mat-chip-row>-->
                <!--              <input placeholder="Add parent.."-->
                <!--                     #parentInput-->
                <!--                     [matAutocomplete]="parent"-->
                <!--                     [matChipInputFor]="chipGrid"-->
                <!--                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
                <!--                     [matChipInputAddOnBlur]="addOnBlur"-->
                <!--                     (matChipInputTokenEnd)="addParent($event)">-->
                <!--            </mat-chip-grid>-->
                <!--            <mat-autocomplete #parent="matAutocomplete" (optionSelected)="selectedParent($event)">-->
                <!--              <mat-option *ngFor="let user of filteredParents | async" [value]="user">-->
                <!--                {{ user.firstName }} {{ user.lastName }}-->
                <!--              </mat-option>-->
                <!--            </mat-autocomplete>-->
                <!--          </mat-form-field>-->
                <!--        </div>-->
                <div>
                    <mat-form-field appearance='fill'>
                        <mat-label>Birthdate</mat-label>
                        <input [matDatepicker]='picker' formControlName='birthdate' matInput>
                        <mat-datepicker-toggle [for]='picker' matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <input (input)='onPasswordInput()' formControlName='password' matInput placeholder='Password'
                            type='password'>
                        <mat-error *ngIf="password.hasError('required')">Password is required</mat-error>
                        <mat-error *ngIf="password.hasError('minlength')">Password must have at least {{ minPw }}
                            characters
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <input (input)='onPasswordInput()' formControlName='password2' matInput placeholder='Confirm password'
                            type='password'>
                        <mat-error *ngIf="password2.hasError('required')">Please confirm your password</mat-error>
                        <mat-error *ngIf="password2.invalid && !password2.hasError('required')">Passwords don't match
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field [formGroup]='userForm'>
                        <input formControlName='phone' matInput placeholder='Phone Number (for SMS)' type='phone'>
                    </mat-form-field>
                </div>
                <div [formGroup]='userForm'>
                    <mat-checkbox formControlName='agree'>Has agreed to recieve work related SMS messages.
                    </mat-checkbox>
                </div>
            </div>
            <div style='text-align: center; margin: 20px;'>
                <button color='primary' mat-raised-button style='margin-right: 20px;' type='submit'>Save</button>
                <button (click)='goBack()' color='secondary' mat-raised-button type='button'>Back</button>
            </div>
        </form>
        <mat-card *ngIf='loading' appearance='outlined'>
            <mat-card-content>
                <mat-progress-bar mode='indeterminate'></mat-progress-bar>
            </mat-card-content>
        </mat-card>
    </mat-card-content>
</mat-card>
