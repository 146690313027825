import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../../_models/user';
import { Utils } from '../../core/utils';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-chat-users',
    templateUrl: './chat-users.component.html',
    styleUrls: ['./chat-users.component.scss']
})
export class ChatUsersComponent {
    allUsers = new Array<User>();
    searchForm: FormGroup;
    users$: Observable<User[]>;
    users = new Array<User>();

    constructor(private fb: FormBuilder,
                private userService: UserService) {

        this.searchForm = this.fb.group({
            search: [null]
        });

        this.users$ = this.userService.allUsers().pipe(
            tap(res => {
                res.sort(Utils.sortByFirstLastName);
                this.users = res.map(i => new User(i));
                this.allUsers = [...this.users];
            })
        );

        this.searchForm.valueChanges.subscribe((values) => {
            console.log(values);
            if (values.search) {
                this.filterUsersByName(values.search);
            } else {
                this.users = [...this.allUsers];
            }
        });
    }

    private filterUsersByName(name: string) {
        this.users = this.allUsers.filter(u => (u.firstName + ' ' + u.lastName).toLowerCase().indexOf(name.toLowerCase()) > -1);
    }
}
