import { Component } from '@angular/core';
// import { CalendarOptions, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/angular';
import { BehaviorSubject, merge, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, startWith, tap } from 'rxjs/operators';
import { Blog } from '../../../_models/blog';
// import { createEventId, INITIAL_EVENTS } from '../../../core/event-utils';
import { BlogService } from '../../../services/blog.service';

@Component({
    selector: 'app-blog-entries',
    templateUrl: './blog-entries.component.html',
    styleUrls: ['./blog-entries.component.scss']
})
export class BlogEntriesComponent {

    calendarVisible = true;
    // currentEvents: EventApi[] = [];
    isLoading$ = new BehaviorSubject<boolean>(false);
    // calendarOptions: CalendarOptions = {
    //   headerToolbar: {
    //     left: 'prev',
    //     center: 'title',
    //     right: 'next'
    //   },
    //   initialView: 'dayGridWeek', // the name of a generic view
    //   duration: { days: 3 },
    //   initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    //   // weekends: true,
    //   // editable: true,
    //   // selectable: true,
    //   // selectMirror: true,
    //   // dayMaxEvents: true
    //   // select: this.handleDateSelect.bind(this),
    //   // eventClick: this.handleEventClick.bind(this),
    //   // eventsSet: this.handleEvents.bind(this)
    //   /* you can update a remote database when these fire:
    //   eventAdd:
    //   eventChange:
    //   eventRemove:
    //   */
    // };
    localBlogs: Blog[] = [];
    pinnedAnnouncements = false;
    results$: Observable<Blog[]>;
    refresh$ = new Subject<boolean>();
    constructor(private blogService: BlogService) {
        this.results$ = merge(
            this.getBlogPosts(),
            this.refresh$.pipe(switchMap(() => this.getBlogPosts()))
        );
    }

    // initialEvents = INITIAL_EVENTS;
    getBlogPosts(): Observable<Blog[]> {
        return of(null).pipe(
            tap(() => {
                this.isLoading$.next(true);
            }),
            switchMap(() =>
                this.blogService.getAll().pipe(startWith(undefined as Blog[]))
            ),
            tap((users: Blog[]) => {
                if (users) {
                    this.localBlogs = users;
                    this.isLoading$.next(false);
                }
            }),
            catchError((error: any) => {
                this.isLoading$.next(false);
                return of(null);
            })
        );
    }

    // handleCalendarToggle() {
    //   this.calendarVisible = !this.calendarVisible;
    // }
    //
    // handleWeekendsToggle() {
    //   const { calendarOptions } = this;
    //   calendarOptions.weekends = !calendarOptions.weekends;
    // }
    //
    // handleDateSelect(selectInfo: DateSelectArg) {
    //   const title = prompt('Please enter a new title for your event');
    //   const calendarApi = selectInfo.view.calendar;
    //
    //   calendarApi.unselect(); // clear date selection
    //
    //   if (title) {
    //     calendarApi.addEvent({
    //       id: createEventId(),
    //       title,
    //       start: selectInfo.startStr,
    //       end: selectInfo.endStr,
    //       allDay: selectInfo.allDay
    //     });
    //   }
    // }
    //
    // handleEventClick(clickInfo: EventClickArg) {
    //   if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //     clickInfo.event.remove();
    //   }
    // }
    //
    // handleEvents(events: EventApi[]) {
    //   this.currentEvents = events;
    // }

    filterOutBeforeToday(events: any[]): any[] {
        return events.filter(e => new Date(e.start).getTime() > Date.now());
    }
}
