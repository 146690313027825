import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Album } from '../../_models/album';
import { ImageService } from '../../services/image.service';
import { PhotoDialogComponent } from '../photo-dialog/photo-dialog.component';

@Component({
    selector: 'app-photo-album',
    templateUrl: './photo-album.component.html',
    styleUrls: ['./photo-album.component.scss']
})
export class PhotoAlbumComponent implements OnChanges {
    @Input() shouldRefresh: boolean;
    albums: Album[];

    constructor(
        private dialog: MatDialog,
        private imageService: ImageService) {
        this.refreshAlbums();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.shouldRefresh?.currentValue) {
            this.refreshAlbums();
        }
    }

    refreshAlbums() {
        this.imageService.getAlbums().subscribe(albums => {
            this.albums = albums;
            this.albums.forEach((album) => {
                this.imageService.getImages(album.name).subscribe((images) => {
                    album.images = images.filter(i => i.url.indexOf('/') < i.url.length - 1);
                });
            });
        });
    }

    openImage(url) {
        const dialogRef = this.dialog.open(PhotoDialogComponent, {
            data: {url},
            height: '600px',
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
