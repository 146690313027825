export class AuthorizationRole {
    id: string;
    name: string;
    type: string;
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
    behalfOf: number;
    childOf: number;

    constructor(authz: string | AuthorizationRole) {
        if (authz instanceof AuthorizationRole) {
            Object.assign(this, authz);
        } else {
            const seq = authz.split(new RegExp('[#/\\\\]'));
            this.behalfOf = (seq.length >= 3) ? parseInt(seq[2], 2) : null;
            const crud = seq.length >= 2 ? seq[1] : null;
            if (crud) {
                this.create = crud.indexOf('C') !== -1;
                this.read = crud.indexOf('R') !== -1;
                this.update = crud.indexOf('U') !== -1;
                this.delete = crud.indexOf('D') !== -1;
            }
            this.name = seq[0];
            if (seq[0] === 'SP') {
                this.type = 'ServiceProviderRole';
                this.behalfOf = null;
                this.id = seq[1];
                this.childOf = parseInt(seq[2], 2);
            } else if (seq.length > 1) {
                this.id = seq[0];
                this.type = 'CrudRole';
            } else {
                this.id = seq[0];
                this.type = 'SimpleRole';
            }
        }
    }

    toAuthz(): string {
        if (this.type === 'CrudRole') {
            return `${this.name}#${this.create ? 'C' : ''}${this.read ? 'R' : ''}${this.update ? 'U' : ''}${this.delete ? 'D' : ''}`;
        } else if (this.type === 'ServiceProviderRole') {
            return `SP#${this.id}${this.childOf ? `\\${this.childOf}` : ''}`;
        }
        return this.name;
    }

    hasPermisson(authz: string) {
        let result = false;
        const tmp = new AuthorizationRole(authz);
        if (tmp.create) {
            result = this.create === tmp.create;
        }
        if (tmp.read) {
            result = this.read === tmp.read;
        }
        if (tmp.update) {
            result = this.update === tmp.update;
        }
        if (tmp.delete) {
            result = this.delete === tmp.delete;
        }
        return result;
    }
}
