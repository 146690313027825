import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EMPTY } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Blog } from '../../../_models/blog';
import { Utils } from '../../../core/utils';
import { BlogService } from '../../../services/blog.service';

@Component({
    selector: 'app-blog-add',
    templateUrl: './blog-add.component.html',
    styleUrls: ['./blog-add.component.scss']
})
export class BlogAddComponent implements OnInit {
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '10rem',
        minHeight: '5rem',
        placeholder: 'Enter text you wish to appear above the link.',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        customClasses: [
            {
                name: 'Quote',
                class: 'quoteClass',
            },
            {
                name: 'Title Heading',
                class: 'titleHead',
                tag: 'h1',
            },
        ],
    };
    form = this.fb.group({
        _id: [null],
        title: [null],
        link: [null, Validators.required],
        pinned: [null],
    });
    pinnedSelected: any;
    title: any;
    trueFalse: any[] = [{name: 'Yes', value: true}, {name: 'No', value: false}];
    loading: boolean;
    blogId: number;
    private blog: Blog;
    constructor(private blogService: BlogService,
                private fb: FormBuilder,
                private route: ActivatedRoute) {
        this.route.paramMap
            .pipe(
                map((paramMap: ParamMap) => paramMap.get('blogId')),
                tap((blogId: string) => {
                    this.loading = true;
                    this.blogId = +blogId;
                }),
                mergeMap((blogId: string) => {
                    if (blogId) {
                        return this.blogService.get(blogId)
                            .pipe(tap((classroom1: Blog) => this.loading = false));
                    } else {
                        this.loading = false;
                        return EMPTY;
                    }
                })
            ).subscribe((loadedBlog: Blog) => {
            this.blog = loadedBlog;
            this.init();
        });
        this.pinnedSelected = false;
        this.form.valueChanges.subscribe(values => {
            this.blog = new Blog(values as any);
        });
    }

    ngOnInit(): void {
    }

    save() {
        this.blog.title = this.title;
        if (!Utils.isEmpty(this.blog)) {
            this.blogService.update(this.blog).subscribe((res) => {
                console.log(res);
            });
        }
    }

    init() {
        this.title = this.blog.title;
        this.form = this.fb.group({
            _id: [this.blogId],
            title: [this.blog.title],
            link: [this.blog.link, Validators.required],
            pinned: [this.blog.pinned],
        }) as any;
    }
}
