import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-assignment-unsubmit-note',
    templateUrl: './assignment-unsubmit-note.component.html',
    styleUrls: ['./assignment-unsubmit-note.component.scss']
})
export class AssignmentUnsubmitNoteComponent {
    unSubmitNote: any;

    constructor(private dialogRef: MatDialogRef<AssignmentUnsubmitNoteComponent>) {
    }

    save(note: string) {
        if (note) {
            this.dialogRef.close(note);
        }
    }

    close() {
        this.dialogRef.close();
    }
}
