import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularMaterialDataGridModule } from '@troyaaronjones/angular_material_data_grid';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AttachmentManagerComponent } from './admin/attachment/attachment-manager/attachment-manager.component';
import { BlogAddComponent } from './admin/blog/blog-add/blog-add.component';
import { BlogEntriesComponent } from './admin/blog/blog-entries/blog-entries.component';
import { BlogGridComponent } from './admin/blog/blog-grid/blog-grid.component';
import { BlogListComponent } from './admin/blog/blog-list/blog-list.component';
import { ClassroomAssignmentComponent } from './admin/classroom/classroom-assignment/classroom-assignment.component';
import { ClassroomEditComponent } from './admin/classroom/classroom-edit/classroom-edit.component';
import { ClassroomListComponent } from './admin/classroom/classroom-list/classroom-list.component';
import { ClassroomPageComponent } from './admin/classroom/classroom-page/classroom-page.component';
import { RoleEditComponent } from './admin/role/role-edit/role-edit.component';
import { RoleListComponent } from './admin/role/role-list/role-list.component';
import { RolePageComponent } from './admin/role/role-page/role-page.component';
import { UserAddEditComponent } from './admin/users/user-add-edit/user-add-edit.component';
import { UserListComponent } from './admin/users/user-list/user-list.component';
import { UserPageComponent } from './admin/users/user-page/user-page.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssignmentEditComponent } from './assignment/assignment-edit/assignment-edit.component';
import { AssignmentGroupEditComponent } from './assignment/assignment-group-edit/assignment-group-edit.component';
import { AssignmentListComponent } from './assignment/assignment-list/assignment-list.component';
import { AssignmentPageComponent } from './assignment/assignment-page/assignment-page.component';
import { AssignmentSearchComponent } from './assignment/assignment-search/assignment-search.component';
import { AssignmentSubmitComponent } from './assignment/assignment-submit/assignment-submit.component';
import { AssignmentUnsubmitNoteComponent } from './assignment/assignment-unsubmit-note/assignment-unsubmit-note.component';
import { ParentViewComponent } from './assignment/parent-view/parent-view.component';
import { StudentViewComponent } from './assignment/student-view/student-view.component';
import { TeacherViewComponent } from './assignment/teacher-view/teacher-view.component';
import { TextAttachmentEditComponent } from './assignment/text-attachment-edit/text-attachment-edit.component';
import { AuthInterceptor } from './auth-interceptor';
import { AuthzDirective } from './authz.directive';
import { BooklogComponent } from './booklog/booklog-page/booklog.component';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { ChartsComponent } from './charts/charts.component';
import { ChatContactComponent } from './chat/chat-contact/chat-contact.component';
import { ChatDirectComponent } from './chat/chat-direct/chat-direct.component';
import { ChatEmojiPickerComponent } from './chat/chat-emoji-picker/chat-emoji-picker.component';
import { ChatListComponent } from './chat/chat-list/chat-list.component';
import { ChatRoomComponent } from './chat/chat-room/chat-room.component';
import { ChatUsersComponent } from './chat/chat-users/chat-users.component';
import { ChatComponent } from './chat/chat.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DndDirective } from './core/dnd.directive';
import { DragDropFileUploadComponent } from './core/drag-drop-file-upload/drag-drop-file-upload.component';
import { LinkPreviewComponent } from './core/link-preview/link-preview.component';
import { TimeFromPipe } from './core/pipes/time-from.pipe';
import { ProgressComponent } from './core/progress/progress.component';
import { UploadComponent } from './core/upload/upload.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { MessengerPageComponent } from './messenger-page/messenger-page.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PhotoAlbumComponent } from './photo/photo-album/photo-album.component';
import { PhotoDialogComponent } from './photo/photo-dialog/photo-dialog.component';
import { PhotoUploadComponent } from './photo/photo-upload/photo-upload.component';
import { NewsletterSubmissionComponent } from './public/newsletter-submission/newsletter-submission.component';
import { SafePipe } from './safe.pipe';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { CalendarPageComponent } from './user/calendar-page/calendar-page.component';
import { GradesComponent } from './grades/grades.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';

// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {
//     withCredentials: false,
//   }
// };

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        AssignmentSubmitComponent,
        AssignmentSearchComponent,
        AssignmentListComponent,
        AssignmentPageComponent,
        UserListComponent,
        UserAddEditComponent,
        UserPageComponent,
        AuthzDirective,
        NavigationComponent,
        SideNavigationComponent,
        ClassroomListComponent,
        ClassroomEditComponent,
        ClassroomPageComponent,
        RoleListComponent,
        RolePageComponent,
        RoleEditComponent,
        ConfirmDialogComponent,
        AttachmentManagerComponent,
        UploadComponent,
        DndDirective,
        ProgressComponent,
        AssignmentEditComponent,
        TextAttachmentEditComponent,
        MenuListItemComponent,
        AssignmentUnsubmitNoteComponent,
        ClassroomAssignmentComponent,
        SafePipe,
        BlogEntriesComponent,
        BlogAddComponent,
        LinkPreviewComponent,
        TimeFromPipe,
        BlogListComponent,
        AssignmentGroupEditComponent,
        StudentViewComponent,
        ParentViewComponent,
        TeacherViewComponent,
        CalendarPageComponent,
        ChatComponent,
        ChatRoomComponent,
        ChatListComponent,
        ChatContactComponent,
        ChatDirectComponent,
        MessengerPageComponent,
        ChatUsersComponent,
        ChatEmojiPickerComponent,
        PhotoUploadComponent,
        PhotoAlbumComponent,
        DragDropFileUploadComponent,
        PhotoDialogComponent,
        NewsletterSubmissionComponent,
        BlogGridComponent,
        ChartsComponent,
        BooklogComponent,
        GradesComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
    ],
    imports: [
        AngularEditorModule,
        AngularMaterialDataGridModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatInputModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatSortModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        HttpClientModule,
        MatSidenavModule,
        MatExpansionModule,
        MatListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSnackBarModule,
        BreadcrumbModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSortModule,
        MatDialogModule,
        MatRadioModule,
        BreadcrumbModule,
        MatTabsModule,
        SocialLoginModule,
        MatCheckboxModule,
        FontAwesomeModule,
        NgxChartsModule
    ],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '1015485363608-8b304o9obmogbha5vl4cjjbausl751u0.apps.googleusercontent.com'
                        ),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        ConfirmDialogService,
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far);
    }
}
