<ng-container>
    <mat-card appearance='outlined' style='margin: 15px;'>
        <mat-card-header>
            <mat-card-title>
                Add/Edit Blog Post
            </mat-card-title>
        </mat-card-header>
        <mat-card-content style='margin: 15px;'>
            <div>
                <angular-editor [(ngModel)]='title' [config]='config'></angular-editor>
            </div>
            <form [formGroup]='form'>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false' style='width: 100%;'>
                        <mat-label>Link:</mat-label>
                        <input formControlName='link' matInput placeholder='' type='text'>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <mat-label>Pinned:</mat-label>
                        <mat-select [(value)]='pinnedSelected' formControlName='pinned'>
                            <mat-option *ngFor='let b of trueFalse' [value]='b.value'>
                                {{ b.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div class='action-buttons'>
                <button [routerLink]="['../../']" color='accent' mat-raised-button type='button'>Back</button>
                <button (click)='save()' color='primary' mat-raised-button type='button'>Save</button>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
