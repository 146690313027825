<div class='assignment'>
    <div *ngIf='isParent'>
        <app-parent-view [parentId]='userId'></app-parent-view>
    </div>
    <div *ngIf='!isParent'>
        <mat-card appearance='outlined' fxLayout='row wrap' fxLayoutAlign='flex-start' fxLayoutGap='15px'>
            <div *ngIf='hasUserDetails' [hidden]='isAdmin'>
                <amdg-grid
                    (requestBodyEmit)='requestBodyChanged($event)'
                    (responseEmit)='responseReceived($event)'
                    (rowClickEmit)='edit($event)'
                    [entity]='entity'
                    [headings]='studentHeadings'
                    [initialFilters]='initialStudentFilters'
                    [initialSort]='initialSort'
                    [resetFilters]='resetFilters'
                    [selection]='true'
                    [serverSidePagination]='true'
                    [url]='url'>
                </amdg-grid>
            </div>
            <div *ngIf='hasUserDetails' [hidden]='!isAdmin'>
                <amdg-grid
                    (requestBodyEmit)='requestBodyChanged($event)'
                    (responseEmit)='responseReceived($event)'
                    (rowClickEmit)='edit($event)'
                    (selectionEmit)='onSelectionChange($event)'
                    [headings]='headings'
                    [initialFilters]='initialFilters'
                    [initialSort]='initialSort'
                    [selection]='true'
                    [serverSidePagination]='true'
                    [url]='url'>
                    <mat-card *ngIf='selectedRows?.length > 0' class='amdg-top-right-button'>
                        <button (click)='onDelete()' mat-raised-button>Delete</button>
                    </mat-card>
                    <mat-card *ngIf='selectedRows?.length > 0' class='amdg-top-right-button'>
                        <button (click)='onBookletComplete()' mat-raised-button>Mark Booklet Complete</button>
                    </mat-card>
                    <mat-card class='amdg-top-right-button'>
                        <button [routerLink]="['/assignment/edit/group']" mat-raised-button>Mass Edit Assignments
                        </button>
                    </mat-card>
                </amdg-grid>
            </div>
        </mat-card>
    </div>
</div>
