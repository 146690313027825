import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Blog } from '../_models/blog';
import { AuthService } from '../auth.service';
import { Utils } from '../core/utils';

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    constructor(private authService: AuthService,
                private http: HttpClient) {
    }

    getAll(): Observable<Blog[]> {
        return this.http.get<Blog[]>(`${environment.apiUrl}/api/blogs`).pipe(
            map(results => results.sort((a: Blog, b: Blog) =>
                new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())),
            map(res => res.map(it => new Blog(it))),
        );
    }

    get(id: string): Observable<Blog> {
        return this.http.get<Blog>(`${environment.apiUrl}/api/blog/${id}`).pipe(
            map(res => new Blog(res))
        );
    }

    save(blog: any): Observable<Blog> {
        const t = Object.assign({}, blog);
        if (blog?.link) {
            t.link = blog.link;
        }
        if (!Utils.isEmpty(blog?.pinned)) {
            t.pinned = blog.pinned;
        }
        if (blog?.title) {
            t.title = blog.title;
        }
        t.createdBy = (this.authService.userValue as any).id;
        t.modifiedBy = (this.authService.userValue as any).id;
        return this.http.post<any>(`${environment.apiUrl}/api/blog`, {blog: Utils.pruneEmpty(t)}).pipe(
            map(res => new Blog(res))
        );
    }

    update(blog: any): Observable<Blog> {
        const t = Object.assign({}, blog);
        if (blog?._id !== null) {
            t._id = blog._id;
        } else {
            return this.save(blog);
        }
        if (blog?.link) {
            t.link = blog.link;
        }
        if (!Utils.isEmpty(blog?.pinned)) {
            t.pinned = blog.pinned;
        }
        if (t.pinned === true) {
            t.pinnedOn = Date.now();
        }
        if (blog?.title) {
            t.title = blog.title;
        }
        t.createdBy = (this.authService.userValue as any).id;
        t.modifiedBy = (this.authService.userValue as any).id;
        return this.http.put<any>(`${environment.apiUrl}/api/blog`, {blog: Utils.pruneEmpty(t)}).pipe(
            map(res => new Blog(res))
        );
    }

    delete(blog: any): Observable<Blog> {
        return this.http.delete<any>(`${environment.apiUrl}/api/blog/${blog._id}`).pipe(
            map(res => new Blog(res))
        );
    }
}
