<div class='chat-rooms-list-container'>
    <div class='rooms-search-container'>
        <form [formGroup]='searchForm' class='rooms-search-form'>
            <mat-icon style='float: left'>search</mat-icon>
            <input
                data-lpignore='true'
                formControlName='search'
                placeholder='Search'
                type='search'>
        </form>
    </div>
    <div *ngIf='(rooms$ | async)' class='rooms-list'>
        <div *ngFor='let room of rooms'>
            <div (click)='setActiveRoom(room)' [ngClass]="{'active': activeRoom?._id === room._id}" class='room-card'>
                <span *ngIf='room._id'>{{ getRoomName(room) }}</span>
                <span *ngIf='!room._id'>
          <input (blur)='saveNewRoom($event)' (mwlKeydownEnter)='saveNewRoom($event)'
              placeholder='Enter Room Name' type='text'>
        </span>
            </div>
        </div>
    </div>
<!--    <div class='footer'>-->
<!--        <mat-icon (click)='createRoom()'>add</mat-icon>-->
<!--    </div>-->
</div>
