import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Attachment } from '../_models/attachment';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    constructor(private http: HttpClient) {
    }

    getAttachments(): Observable<Attachment[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/attachments`).pipe(
            map(res => res.map(it => new Attachment(it)))
        );
    }

    getAttachment(id: any): Observable<Attachment> {
        return this.http.get<any>(`${environment.apiUrl}/api/attachment/${id}`).pipe(
            map(res => new Attachment(res))
        );
    }

    getAttachmentsByUser(userId: any): Observable<Attachment[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/attachment/user/${userId}`).pipe(
            map(res => res.map(it => new Attachment(it)))
        );
    }

    save(attachment: any): Observable<Attachment> {
        return this.http.post<Attachment>(`${environment.apiUrl}/api/attachment`, attachment).pipe(
            map(res => new Attachment(res))
        );
    }

    update(attachment: any): Observable<Attachment> {
        return this.http.put<Attachment>(`${environment.apiUrl}/api/attachment`, attachment).pipe(
            map(res => new Attachment(res))
        );
    }

    delete(attachment: any): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/api/attachment/${attachment._id}`).pipe(
            map(res => new Attachment(res))
        );
    }
}
