import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Room } from '../../_models/room';
import { AuthService } from '../../auth.service';
import { Utils } from '../../core/utils';
import { ChatService } from '../../services/chat.service';

@Component({
    selector: 'app-chat-room',
    templateUrl: './chat-room.component.html',
    styleUrls: ['./chat-room.component.scss']
})
export class ChatRoomComponent {
    allRooms = new Array<Room>();
    searchForm: FormGroup;
    rooms$: Observable<Room[]>;
    rooms = new Array<Room>();
    currentRoom: Room;
    currentRoom$ = new BehaviorSubject<Room[]>([]);
    activeRoom: Room;
    saving: boolean;

    constructor(private auth: AuthService,
                private chatService: ChatService,
                private fb: FormBuilder) {

        this.searchForm = this.fb.group({
            search: [null]
        });

        this.rooms$ = merge(
            this.chatService.getAllRoomsByUser(this.auth.userValue.id).pipe(
                tap(v => {
                    this.rooms = v;
                    this.allRooms = [...this.rooms];
                    this.currentRoom$.next(v);
                })
            ),
            this.currentRoom$.pipe(switchMap(v => of(v)),
                tap(v => {
                    this.rooms = v;
                    this.setActiveRoom(this.rooms.filter(r => r.name === 'General')[0]);
                })
            )
        );

        this.searchForm.valueChanges.subscribe((values) => {
            console.log(values);
            if (values.search) {
                this.filterRoomsByName(values.search);
            } else {
                this.rooms = [...this.allRooms];
            }
        });
    }

    createRoom() {
        const tmpRoom: Room = new Room();
        tmpRoom.users = [this.auth.userValue];
        tmpRoom.name = '';
        tmpRoom.topic = '';
        const existing = this.currentRoom$.value;
        existing.push(tmpRoom);
        console.log(existing);
        this.currentRoom$.next(existing);
    }

    saveNewRoom($event) {
        if (!this.saving) {
            this.currentRoom = this.currentRoom$.value.filter(r => Utils.isEmpty(r._id))[0];
            if (!Utils.isEmpty($event.currentTarget.value)) {
                this.currentRoom.name = $event.currentTarget.value;
                this.currentRoom.topic = $event.currentTarget.value;
                if (this.currentRoom && (!this.currentRoom?._id || !Utils.isEmpty(this.currentRoom._id))) {
                    this.saving = true;
                    this.chatService.createRoom(this.currentRoom).subscribe(res => {
                        this.currentRoom = res;
                        const existing = this.currentRoom$.value.filter(r => !Utils.isEmpty(r._id));
                        existing.unshift(res);
                        this.currentRoom$.next(existing);
                        this.saving = false;
                    }, error => {
                        this.saving = false;
                    });
                }
            }
        }
    }

    setActiveRoom(room: Room) {
        this.activeRoom = room;
        this.chatService.activeRoom.next(this.activeRoom);
    }

    private filterRoomsByName(name: string) {
        this.rooms = this.allRooms.filter(r => r.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
    }

    getRoomName(room: Room) {
        return room.name === 'REPLACE' ? this.convertToRoomName(room) : room.name;
    }

    convertToRoomName(room: Room) {
        if (room.users.length === 2) {
            if (room.users[0]._id.toString() !== this.auth.userValue.id) {
                return room.users[0].firstName + ' ' + room.users[0].lastName;
            }
            else {
                return room.users[1].firstName + ' ' + room.users[1].lastName;
            }
        }
        else {
            return room.topic;
        }
    }
}
