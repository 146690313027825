import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Room } from '../../_models/room';
import { AuthService } from '../../auth.service';
import { Utils } from '../../core/utils';
import { HazelwoodSocket } from '../../hazelwood-socket';
import { ChatService } from '../../services/chat.service';
import { ChatEmojiPickerComponent } from '../chat-emoji-picker/chat-emoji-picker.component';

@Component({
    selector: 'app-chat-direct',
    templateUrl: './chat-direct.component.html',
    styleUrls: ['./chat-direct.component.scss']
})
export class ChatDirectComponent implements OnDestroy {
    @ViewChild('emojiPicker', {static: false}) public emojiPicker: ElementRef;
    @ViewChild('divInputMessage', {static: false}) public input: ElementRef;
    messageForm: FormGroup;
    messages: any[] = [];
    sender: {};
    currentRoom: string;
    symbol = '&#x1F600';
    contentEditable = true;
    private dialogRef: MatDialogRef<ChatEmojiPickerComponent, any>;
    // tslint:disable-next-line:variable-name
    private _room: Room;

    constructor(private authService: AuthService,
                private chatService: ChatService,
                private fb: FormBuilder,
                private matDialog: MatDialog,
                private socketService: HazelwoodSocket) {
        this.sender = {
            id: this.authService.userValue.id ? this.authService.userValue.id : this.authService.userValue._id,
            name: this.authService.userValue.firstName + ' ' + this.authService.userValue.lastName,
        };
        this.socketService.subscribeToMessages((err, data) => {
            console.log('NEW MESSAGE ', data);
            this.messages = [...this.messages, data];
        });
        this.messageForm = this.fb.group({
            message: [null]
        });
        this.chatService.activeRoom.subscribe(active => {
            // add code to load messages from room
            console.log('active', active);
            this._room = active;
            this.chatService.getMessagesFrom(active).subscribe((messages) => {
                console.log(messages);
                this.messages = messages;
            });
            if (!Utils.isEmpty(active)) {
                this.currentRoom = active.name !== 'REPLACE' ? active.name : this.convertToRoomName(active);
                this.socketService.joinRoom(this.currentRoom);
            }
        });
    }

    ngOnDestroy() {
        this.socketService.disconnect();
    }

    convertToRoomName(room: Room) {
        if (room.users.length === 2) {
            if (room.users[0]._id.toString() !== this.authService.userValue.id) {
                return room.users[0].firstName + ' ' + room.users[0].lastName;
            }
            else {
                return room.users[1].firstName + ' ' + room.users[1].lastName;
            }
        }
        else {
            return room.topic;
        }
    }

    submitMessage(value) {
        const message = this.fixMessage(value.innerHTML);
        if (message) {
            this.socketService.sendMessage({message, roomName: this._room._id}, cb => {
                console.log('ACKNOWLEDGEMENT ', cb);
            });
            this.messages = [
                ...this.messages,
                {
                    message,
                    ...this.sender,
                },
            ];
            // clear the input after the message is sent
            // this.messageForm.reset();
            value.innerHTML = '';
        }
    }

    userIsMe(user: any) {
        // console.log(user);
        // console.log(this.authService.userValue);
        const id = this.authService.userValue.id ? this.authService.userValue.id : this.authService.userValue._id.toString();
        return id === user.id;
    }

    emojiMenu() {
        // openEmojiSelectionDiv();
        if (!this.dialogRef) {
            this.dialogRef = this.matDialog.open(ChatEmojiPickerComponent, {
                hasBackdrop: false,
                height: '300px',
                width: '300px',
                data: {positionRelativeToElement: this.emojiPicker},
                panelClass: 'picker-dialog-container'
            });

            this.dialogRef.afterClosed()
                .pipe()
                .subscribe((emoji) => {
                    // setEmojiChoosen();
                    console.log(emoji);
                    this.input.nativeElement.innerHTML += this.fixCode(emoji.code) + '&nbsp;';
                    this.setCursorToEnd(this.input.nativeElement);
                    this.dialogRef = null;
                });
        }
    }

    fixCode(code) {
        return code.replace(/U\+/g, '&#x').replace(/\s/g, ';') + ';';
    }

    onFocus(value) {
        console.log(value.innerHTML);
        if (Utils.isEmpty(value.innerHTML)) {
            value.innerHTML = '&nbsp';
        }
        this.setCursorToEnd(value);
    }

    fixMessage(message: string): string {
        return message.replace(/&nbsp;&nbsp;/g, '&nbsp;').replace(/&nbsp;/g, ' ').replace(/\s\s/g, ' ').trim();
    }

    setCursorToEnd(ele) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(ele, 1);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        ele.focus();
    }

    onKeydown(divInputMessage: HTMLDivElement) {
        if (divInputMessage.innerHTML.indexOf('@') > -1) {
            console.log(divInputMessage.innerHTML);
        }
    }
}
