import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-user-page',
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPageComponent {

    constructor(private route: ActivatedRoute,
                private router: Router) {
    }

    addUser() {
        this.router.navigate(['edit'], {relativeTo: this.route});
    }
}
