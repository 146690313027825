import { AfterContentInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { AuthService } from './auth.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[authz]'
})
export class AuthzDirective implements AfterContentInit {
    perms = '';

    @Input('authz') main: any;
    private takenCareOf = false;

    constructor(private el: ElementRef,
                private authService: AuthService) {
        if (el.nativeElement.attributes.authz?.nodeValue) {
            this.perms = el.nativeElement.attributes.authz.nodeValue.split(/ *, */);
            this.takenCareOf = true;
        }
    }

    ngAfterContentInit(): void {
        if (this.main?.authz && !this.takenCareOf) {
            this.perms = this.main?.authz.split(/ *, */);
            this.takenCareOf = true;
        } else if (this.main && !this.takenCareOf) {
            this.perms = this.main?.split(/ *, */);
            this.takenCareOf = true;
        }
    }

    @HostBinding('class.is-valid')
    public get isValid(): boolean {
        return this.valid;
    }

    @HostBinding('class.hidden')
    public get isInvalid(): boolean {
        return this.invalid;
    }

    public get valid(): boolean {
        return this.authService.permitted(...this.perms);
    }

    public get invalid(): boolean {
        return !this.authService.permitted(...this.perms);
    }
}

