import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeFrom'
})
export class TimeFromPipe implements PipeTransform {

    monthDiff(d1, d2) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    daysDiff(dt1, dt2) {
        // calculate the time difference of two dates
        const diffTime = (dt2.getTime() - dt1.getTime());
        // calculate the number of days between two dates
        return diffTime / (1000 * 3600 * 24);
    }

    hoursDiff(dt1, dt2) {
        // calculate the time difference of two dates JavaScript
        const diffTime = (dt2.getTime() - dt1.getTime());
        // calculate the number of days between hours dates javascript
        return diffTime / (1000 * 3600);
    }

    minutesDiff(dt1, dt2) {
        const diffMin = (dt2.getTime() - dt1.getTime()) / (1000 * 60);
        console.log(diffMin);
        return Math.abs(Math.round(diffMin));
    }

    formatTimeFromToday(dt1, dt2) {
        if (this.minutesDiff(dt1, dt2) > 60) {
            if (this.hoursDiff(dt1, dt2) > 12) {
                if (this.daysDiff(dt1, dt2) > 30) {
                    if (this.monthDiff(dt1, dt2) > 12) {
                        return Math.round(dt1.toDateString()) + '';
                    } else {
                        return Math.round(this.monthDiff(dt1, dt2)) + ' Months';
                    }
                } else {
                    return Math.round(this.daysDiff(dt1, dt2)) + ' Days';
                }
            } else {
                return Math.round(this.hoursDiff(dt1, dt2)) + ' Hours';
            }
        } else {
            return Math.round(this.minutesDiff(dt1, dt2)) + ' Mins';
        }
    }

    transform(value: Date, ...args: string[]): string {
        return this.formatTimeFromToday(new Date(value), new Date(Date.now()));
    }

}
