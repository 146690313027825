<!--<app-assignment-submit *ngIf="selected" [assignment]="selected" (changes)="changes()" (myclose)="closeEdit()"></app-assignment-submit>-->

<app-assignment-list (acceptAssignment)='acceptAssignment($event)'
    (deleteAssignment)='deleteAssignment($event)'
    (selectAssignment)='selectAssignment($event)'
    (unacceptAssignment)='unacceptAssignment($event)'
    *ngIf='!selected'>
</app-assignment-list>
<div authz='Admin#R' class='fab-container'>
    <button [routerLink]="['edit']" aria-label='Add' color='primary' mat-fab>
        <mat-icon>add</mat-icon>
    </button>
</div>
