import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { StorageService } from '../core/storage.service';
import { menu } from '../model/menu';
import { NavItem } from '../model/nav-item';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit, OnChanges {
    @Input() user: any;
    @Input() title: any;
    @Output() logoutMethod = new EventEmitter();

    get route(): string {
        return this.router.url;
    }

    loggedIn = false;
    menu: NavItem[] = menu;
    isMenuOpen = true;
    contentMargin = 225;

    constructor(
        public authService: AuthService,
        public storage: StorageService,
        public router: Router) {
    }

    ngOnInit(): void {
        if (this.user?.token) {
            this.loggedIn = true;
        } else {
            this.loggedIn = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.user?.token) {
            this.loggedIn = true;
        } else {
            this.loggedIn = false;
        }
    }

    shrinkMainMenu() {
        this.isMenuOpen = this.storage.get('isMenuOpen') === null ? true : this.storage.get('isMenuOpen');
        this.isMenuOpen = !this.isMenuOpen;
        this.storage.save('isMenuOpen', this.isMenuOpen);
        if (this.isMenuOpen) {
            this.contentMargin = 225;
        } else {
            this.contentMargin = 80;
        }
    }
}
