<div class='users'>
    <amdg-grid
        (requestBodyEmit)='requestBodyChanged($event)'
        (responseEmit)='responseReceived($event)'
        (rowClickEmit)='edit($event)'
        (selectionEmit)='onSelectionChange($event)'
        [entity]='entity'
        [headings]='headings'
        [initialFilters]='initialFilters'
        [initialSort]='initialSort'
        [resetFilters]='resetFilters'
        [selection]='true'
        [serverSidePagination]='true'
        [url]='url'>
        <mat-card *ngIf='selectedRows?.length > 0' class='amdg-top-right-button'>
            <button (click)='onDelete()' mat-raised-button>Delete</button>
        </mat-card>
        <mat-card class='amdg-top-right-button'>
            <button (click)='loginAs()' mat-raised-button>Login As</button>
        </mat-card>
    </amdg-grid>
</div>

<!--<div *ngIf="results$ | async as results" class="search-results">-->
<!--  <table mat-table [dataSource]="dataSource" matSort matSortActive="userName"-->
<!--         matSortDisableClear matSortDirection="asc" class="mat-elevation-z8">-->

<!--    &lt;!&ndash; Username Column &ndash;&gt;-->
<!--    <ng-container matColumnDef="userName">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.userName}} </td>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash; First Name Column &ndash;&gt;-->
<!--    <ng-container matColumnDef="firstName">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash; Last Name Column &ndash;&gt;-->
<!--    <ng-container matColumnDef="lastName">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash; Email Column &ndash;&gt;-->
<!--    <ng-container matColumnDef="email">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.email}} </td>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash; Delete Column &ndash;&gt;-->
<!--    <ng-container matColumnDef="delete">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>-->
<!--      <td mat-cell *matCellDef="let element" style="text-align: center; margin: 20px;">-->
<!--        <button type="button" mat-raised-button class="mat-primary" (click)="delete(element)">Delete</button>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash; Login &ndash;&gt;-->
<!--    <ng-container matColumnDef="login">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>-->
<!--      <td mat-cell *matCellDef="let element" style="text-align: center; margin: 20px;">-->
<!--        <button type="button" mat-raised-button class="mat-primary" (click)="loginAs(element)">Login</button>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>-->
<!--    <tr mat-row class="assignment-row" *matRowDef="let row; columns: displayedColumns;"  (click)="edit(row)"></tr>-->
<!--  </table>-->
<!--</div>-->
