import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/api/user/forgot-password`, {email});
    }

    resetPassword(email: string, token: string, newPassword: string, userId: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/api/user/reset-password`, {
            email,
            resetToken: token,
            newPassword,
            userId
        });
    }

    getUserFromToken(token: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/user/token/${token}`).pipe(
            map(res => new User(res.user))
        );
    }

    allUsers(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/api/users`).pipe(
            map(res => res.map(it => new User(it)))
        );
    }

    getParentUsers(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/api/users/parents`).pipe(
            map(res => res.map(it => new User(it)))
        );
    }

    getStudentsByParent(id: any): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/api/childrenByParent/${id}`).pipe(
            map(res => res.map(it => new User(it)))
        );
    }

    getAllTeachers(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/api/users/teachers`).pipe(
            map(res => res.map(it => new User(it)))
        );
    }

    getAllStudents(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/api/users/students`).pipe(
            map(res => res.map(it => new User(it)))
        );
    }

    getUser(id: any): Observable<User> {
        return this.http.get<any>(`${environment.apiUrl}/api/user/${id}`).pipe(
            map(res => new User(res.user))
        );
    }

    save(user: any): Observable<User> {
        const t = user as any;
        if (user.user?.roles?.length > 0) {
            t.user.roles = user.user?.roles.map(r => r._id);
        }
        if (user.user?.parents?.length > 0) {
            t.user.parents = user.user?.parents.map(p => p._id);
        }
        return this.http.post<User>(`${environment.apiUrl}/api/user`, t).pipe(
            map(res => new User(res))
        );
    }

    update(user: any): Observable<User> {
        const t = user as any;
        if (user.user?.roles?.length > 0) {
            t.user.roles = user.user?.roles.map(r => r._id);
        }
        if (user.user?.parents?.length > 0) {
            t.user.parents = user.user?.parents.map(p => p._id);
        }
        return this.http.put<User>(`${environment.apiUrl}/api/user`, t).pipe(
            map(res => new User(res))
        );
    }

    updateWithPasswordChange(user: any): Observable<User> {
        const t = user as any;
        if (user.user?.roles?.length > 0) {
            t.user.roles = user.user?.roles.map(r => r._id);
        }
        if (user.user?.parents?.length > 0) {
            t.user.parents = user.user?.parents.map(p => p._id);
        }
        return this.http.put<User>(`${environment.apiUrl}/api/user/p/reset`, t).pipe(
            map(res => new User(res))
        );
    }

    delete(user: User) {
        return this.http.delete<any>(`${environment.apiUrl}/api/user/${user._id}`).pipe(
            map(res => new User(res))
        );
    }
}

