import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { menu } from '../model/menu';
import { NavItem } from '../model/nav-item';
import { UrlService } from '../services/url.service';
import { Breadcrumb } from './breadcrumb';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    previousUrl: string[] = [];
    breadcrumbs: Breadcrumb[] = [];
    currentUrl = '';

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private urlService: UrlService,
                private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this.setDefaultBreadcrumb();
        this.listenForRouteChange();
        this.listenForBreadcrumbUpdate();
    }

    private setDefaultBreadcrumb() {
        this.setCurrentUrl();

        if (this.currentUrl) {
            const navItem: NavItem = this.findRoute();

            if (navItem) {
                const breadcrumb: Breadcrumb = {name: navItem.displayName, url: this.currentUrl};
                this.breadcrumbs.push(breadcrumb);
            }
        }
    }

    private listenForBreadcrumbUpdate() {
        this.breadcrumbService.onUpdate().subscribe((str: string) => {
            this.handleBreadcrumbUpdate(str);
        });
    }

    private handleBreadcrumbUpdate(str: string) {
        // breadcrumb updates only happen on current route
        // so the breadcrumb we need to update is the latest one
        const lastBreadcrumb: Breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];

        if (lastBreadcrumb.pauseDisplay) {
            lastBreadcrumb.pauseDisplay = false;
            lastBreadcrumb.name = str;
        }
    }

    private listenForRouteChange() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            tap((event: NavigationEnd) => {
                console.log('prev:', event.url);
                this.previousUrl.push(event.url);
            }),
            map(() => this.activatedRoute),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            distinctUntilChanged()
        ).subscribe(
            (route: ActivatedRoute) => this.handleCurrentRoute(route)
        );
    }

    private setCurrentUrl() {
        const url: string = this.router.url;

        if (url) {
            // don't need the query parameter list here
            // nor do we need the initial /
            this.currentUrl = this.urlService.shortenUrlIfNecessary(url.substring(1));
        }
    }

    private handleCurrentRoute(route: ActivatedRoute) {
        this.setCurrentUrl();

        const navItem: NavItem = this.findRoute(menu);

        if (navItem) {
            // if we get here, the user clicked on item on the sidebar
            // we'll reset the breadcrumbs to start over
            this.handleTopLevelBreadcrumb(navItem);
        } else {
            // if we get here, the user clicked a link in the main content section
            // we'll add to the breadcrumbs
            this.addBreadcrumb(route);
        }
    }

    private addBreadcrumb(route: ActivatedRoute) {
        if (this.breadcrumbs.length < 6) {
            let breadcrumb: Breadcrumb = null;

            route.data.subscribe((data: any) => {
                breadcrumb = {name: data.breadcrumb, url: this.currentUrl, pauseDisplay: data.pauseDisplay};
            });

            if (breadcrumb) {
                // we only add a new breadcrumb if the person isn't visiting a page that's
                // already in the breadcrumbs array
                if (breadcrumb.url !== (this.breadcrumbs?.length > 1 ? this.breadcrumbs[this.breadcrumbs.length - 1]?.url : '')) {
                    route.queryParams.subscribe((queryParams: any) => {
                        if (queryParams) {
                            breadcrumb.queryParams = queryParams;
                        }
                    });

                    this.breadcrumbs.push(breadcrumb);
                }
            }
        }
    }

    private handleTopLevelBreadcrumb(navItem: NavItem) {
        this.breadcrumbs = [];

        const breadcrumb: Breadcrumb = {name: navItem.displayName, url: navItem.route};

        this.breadcrumbs.push(breadcrumb);
    }

    private findRoute(navItems?: NavItem[]): NavItem {
        if (!navItems) {
            navItems = menu;
        }

        let returnedItem: NavItem = null;

        if (this.currentUrl) {
            for (const item of navItems) {
                if (this.currentUrl === item.route) {
                    returnedItem = item;
                    break;
                } else if (item.children) {
                    returnedItem = this.findRoute(item.children);
                    if (returnedItem != null) {
                        break;
                    }
                }
            }
        } else {
            this.breadcrumbs.length = 0;
        }

        return returnedItem;
    }

    routeTo(index: number) {
        if (index < this.breadcrumbs.length - 1) {
            // if the person clicked on a link in the breadcrumbs list,
            // get rid of every breadcrumb after that point
            this.breadcrumbs.splice(index + 1);
        }

        const breadcrumb: Breadcrumb = this.breadcrumbs[index];

        const route = breadcrumb.url;

        if (this.previousUrl.length > 1 && this.previousUrl[this.previousUrl.length - 2]?.indexOf(route) > -1) {
            breadcrumb.queryParams = this.queryStringToObject(this.previousUrl[this.previousUrl.length - 2].split('?')[1]);
        }

        this.router.navigate([route], {queryParams: breadcrumb.queryParams});
    }

    queryStringToObject(qstring: string): any {
        const result = {};
        if (qstring) {
            const pairs = qstring.split('&');
            pairs.forEach((pair) => {
                const newpair = pair.split('=');
                result[newpair[0]] = decodeURIComponent(newpair[1] || '');
            });
        }
        return result;
    }
}
