import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifyService } from '../../core/notify.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    forgotPasswordForm: FormGroup;
    emailSent = false;

    constructor(private formBuilder: FormBuilder,
                private userService: UserService,
                private route: Router,
                private notify: NotifyService) {
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    onSubmit(): void {
        if (this.forgotPasswordForm.valid) {
            this.userService.forgotPassword(this.forgotPasswordForm.value.email).subscribe(
                () => {
                    this.emailSent = true;
                    this.route.navigate(['/login']);
                },
                error => {
                    this.emailSent = false;
                    this.notify.warning('Error sending email', error.error.message);
                }
            );
        }
    }
}
