import { Component, OnInit } from '@angular/core';
import { Assignment } from '../../_models/assignment';
import { AuthService } from '../../auth.service';
import { NotifyService } from '../../core/notify.service';
import { AssignmentService } from '../../services/assignment.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-assignment-page',
    templateUrl: './assignment-page.component.html',
    styleUrls: ['./assignment-page.component.scss']
})
export class AssignmentPageComponent implements OnInit {
    loading: boolean;
    isAdmin: boolean;
    selected: Assignment;
    assignments: Assignment[];

    constructor(private authService: AuthService,
                private userService: UserService,
                private notifyService: NotifyService,
                private assignmentService: AssignmentService) {
        this.isAdmin = this.authService.isAdmin;
    }

    ngOnInit(): void {
        console.log('this is init');
    }

    addAssignment() {
        console.log('adding assignment');
        this.selected = null;
        this.selected = new Assignment();
    }

    selectAssignment(row: Assignment) {
        console.log(row);
        this.selected = row;
    }

    deleteAssignment(assignment: Assignment) {
        if (assignment?._id) {
            if (this.selected?._id === assignment._id) {
                this.selected = null;
            }
            this.loading = true;
            this.assignmentService.delete(assignment).subscribe(resp => {
                console.log('Deleted Assignment', resp);
                this.loading = false;
                this.notifyService.success('Assignment', 'Deleted successfully');
            }, error => {
                this.loading = false;
                this.notifyService.warning('Assignment', 'Delete failed ' + error);
            }, () => {
                this.loading = false;
            });
        }
    }

    closeEdit() {
        this.selected = null;
    }

    acceptAssignment(assignment: Assignment) {
        assignment.accepted = true;
        this.assignmentService.update(assignment).subscribe(r => {
        });
    }

    unacceptAssignment(assignment: Assignment) {
        assignment.accepted = false;
        this.assignmentService.update(assignment).subscribe(r => {
        });
    }
}
