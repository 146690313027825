<mat-card appearance='outlined'>
    <mat-card-content>
        <h2>Forgot Password</h2>
        <p>Enter your email address below and we will send you a password reset link.</p>
        <form (ngSubmit)='onSubmit()' [formGroup]='forgotPasswordForm'>
            <mat-form-field class='full-width-input'>
                <input formControlName='email' matInput placeholder='Email Address' required>
                <mat-error>
                    Please provide a valid email address
                </mat-error>
            </mat-form-field>
            <button color='primary' mat-raised-button>Reset Password</button>
        </form>
    </mat-card-content>
</mat-card>
