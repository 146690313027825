import { Role } from './role';

export class User {
    // tslint:disable-next-line:variable-name
    _id: number;
    id: string;
    authz: string;
    email: string;
    userName: string;
    password: string;
    firstName: string;
    lastName: string;
    authdata?: string;
    salt?: string;
    token: string;
    birthdate: Date;
    roles: Array<Role>;
    parents: Array<User>;
    phone: string;
    agree: boolean;
    constructor(o?: User) {
        Object.assign(this, o);
        if (o) {
            if (!o?.id && o._id) {
                this.id = o._id.toString();
            }
        }
    }
    get displayUsername() {
        return this.firstName + ' ' + this.lastName;
    }
}
