<mat-card appearance='outlined'>
    <mat-card-header>
        Why are you un-submitting this assignment? (Parents will see this note)
    </mat-card-header>
    <mat-card-content>
        <textarea [(ngModel)]='unSubmitNote' matInput placeholder='' style='border: 1px solid black;'></textarea>
    </mat-card-content>
    <mat-card-footer style='text-align: center; margin: 10px;'>
        <button (click)='close()' mat-button type='button'>Cancel</button>
        <button (click)='save(unSubmitNote);' color='primary' mat-raised-button type='button'>Save</button>
    </mat-card-footer>
</mat-card>
