import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import { Classroom } from '../../../_models/classroom';
import { User } from '../../../_models/user';
import { ClassroomService } from '../../../services/classroom.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-classroom-edit',
    templateUrl: './classroom-edit.component.html',
    styleUrls: ['./classroom-edit.component.scss']
})
export class ClassroomEditComponent implements OnInit, OnChanges {
    loading: any;
    addOnBlur: any;
    teachers: User[];
    students: User[];
    removable = true;
    selectable = true;
    allTeachers: User[];
    allStudents: User[];
    classroomId: number;
    @Input() classroom: Classroom;
    teachersCtrl = new FormControl();
    studentsCtrl = new FormControl();
    filteredTeachers: Observable<User[]>;
    filteredStudents: Observable<User[]>;
    @Output() myclose = new EventEmitter<Classroom>();
    @Output() changes = new EventEmitter<Classroom>();
    floatLabelControl = new FormControl('auto');
    hideRequiredControl = new FormControl(false);
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    classroomForm = this.fb.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        teachers: this.teachersCtrl,
        students: this.studentsCtrl
    });

    @ViewChild('teacherInput') teacherInput: ElementRef;
    @ViewChild('studentInput') studentInput: ElementRef;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private userService: UserService,
                private classroomService: ClassroomService) {
        this.route.paramMap
            .pipe(
                map((paramMap: ParamMap) => paramMap.get('classroomId')),
                tap((classroomId: string) => {
                    this.loading = true;
                    this.classroomId = +classroomId;
                }),
                mergeMap((classroomId: string) => {
                    if (classroomId) {
                        return this.classroomService.getClassroom(classroomId)
                            .pipe(tap((classroom1: Classroom) => this.loading = false));
                    } else {
                        this.loading = false;
                        return EMPTY;
                    }
                })
            ).subscribe((loadedClassroom: Classroom) => {
            this.classroom = loadedClassroom;
            this.init();
        });
    }

    ngOnInit(): void {
        this.addUsers();
        this.userService.getAllTeachers().subscribe(users => {
            this.allTeachers = users.map(it => new User(it));
            this.filteredTeachers = this.teachersCtrl.valueChanges.pipe(
                startWith(null),
                map((teacher: string | null) => teacher ? this.filterTeacher(teacher) : this.allTeachers.slice()));
        });
        this.userService.getAllStudents().subscribe(users => {
            this.allStudents = users.map(it => new User(it));
            this.filteredStudents = this.studentsCtrl.valueChanges.pipe(
                startWith(null),
                map((students: string | null) => students ? this.filterStudent(students) : this.allStudents.slice()));
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.classroom) {
            this.init();
        }
    }

    init() {
        this.addUsers();
        this.teachersCtrl = new FormControl(this.teachers);
        this.studentsCtrl = new FormControl(this.students);
        this.classroomForm = this.fb.group({
            name: [this.classroom.name, Validators.required],
            description: [this.classroom.description],
            teachers: this.teachersCtrl,
            students: this.studentsCtrl
        });
    }

    addUsers() {
        this.teachers = [];
        this.students = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.classroom?.teachers?.length; i++) {
            this.teachers.push(this.classroom.teachers[i]);
        }
        for (let j = 0; j < this.classroom?.students?.length; j++) {
            this.students.push(this.classroom.students[j]);
        }
    }

    addTeacher(event: MatChipInputEvent): void {
        console.log(event);
        // const input = event.input;
        // const value = event.value;
        //
        // // Add our teacher
        // if ((value || '').trim()) {
        //   this.teachers.push(value);
        // }
        //
        // // Reset the input value
        // if (input) {
        //   input.value = '';
        // }
        //
        // this.teachersCtrl.setValue(null);
    }

    removeTeacher(teacher: any): void {
        const index = this.teachers.indexOf(teacher);

        if (index >= 0) {
            this.teachers.splice(index, 1);
        }
    }

    filterTeacher(name: string) {
        return this.allTeachers.filter(teacher =>
            teacher.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selectedTeacher(event: MatAutocompleteSelectedEvent): void {
        this.teachers.push(event.option.value);
        this.teacherInput.nativeElement.value = '';
        this.teachersCtrl.setValue(null);
    }

    addStudent(event: MatChipInputEvent): void {
        console.log(event);
        // const input = event.input;
        // const value = event.value;
        //
        // // Add our student
        // if ((value || '').trim()) {
        //   this.students.push(value);
        // }
        //
        // // Reset the input value
        // if (input) {
        //   input.value = '';
        // }
        //
        // this.studentsCtrl.setValue(null);
    }

    removeStudent(student: any): void {
        const index = this.students.indexOf(student);

        if (index >= 0) {
            this.students.splice(index, 1);
        }
    }

    filterStudent(name: string) {
        return this.allStudents.filter(student =>
            student.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selectedStudent(event: MatAutocompleteSelectedEvent): void {
        this.students.push(event.option.value);
        this.studentInput.nativeElement.value = '';
        this.studentsCtrl.setValue(null);
    }

    mergeChangesFromForm(classroom): Classroom {
        const myclassroom = new Classroom(classroom);
        // tslint:disable-next-line:forin
        for (const key in this.classroomForm.controls) {
            if (key === 'teachers') {
                myclassroom.teachers = [];
                this.teachers.forEach(teacher => {
                    myclassroom.teachers.push(teacher);
                });
            } else if (key === 'students') {
                myclassroom.students = [];
                this.students.forEach(student => {
                    myclassroom.students.push(student);
                });
            } else {
                myclassroom[key] = this.classroomForm.controls[key].value;
            }
        }
        return myclassroom;
    }

    create() {
        this.classroomService.save(this.mergeChangesFromForm(this.classroom)).subscribe(resp => {
            this.changes.emit(resp);
        });
    }

    update() {
        this.classroomService.update(this.mergeChangesFromForm(this.classroom)).subscribe(resp => {
            this.changes.emit(resp);
        });
    }

    copy() {
    }
}
