<mat-card class='booklog-main-container'>
    <mat-form-field authz='Admin#R' fxFlex>
        <mat-label>User</mat-label>
        <mat-select (selectionChange)='select($event)' [(ngModel)]='selectedUser' [ngModelOptions]='{standalone: true}'>
            <mat-option *ngFor='let user of users' [value]='user'>
                {{ user.firstName + ' ' + user.lastName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class='booklog-form'>
        <div class='booklog-add-title'>
            <button (click)='addBookTitle()' color='primary' mat-raised-button>Add Book Title</button>
        </div>
        <form [formGroup]='booklogForm'>
            <div class='book-list'>
                <div formArrayName='bookAuthor'>
                    <div *ngFor='let book of bookAuthor.controls; index as i'>
                        <mat-form-field floatLabel='auto'
                            hideRequiredMarker='false'>
                            <input formControlName='{{i}}' matInput placeholder=''>
                            <mat-label>Author</mat-label>
                        </mat-form-field>
                    </div>
                </div>
                <div formArrayName='bookTitles'>
                    <div *ngFor='let book of bookTitles.controls; index as i'>
                        <mat-form-field floatLabel='auto'
                            hideRequiredMarker='false'>
                            <input formControlName='{{i}}' matInput placeholder=''>
                            <mat-label>Book Name</mat-label>
                        </mat-form-field>
                    </div>
                </div>
                <div formArrayName='bookReadDate'>
                    <div *ngFor='let book of bookReadDate.controls; index as i'>
                        <mat-form-field floatLabel='auto'
                            hideRequiredMarker='false'>
                            <input formControlName='{{i}}' matInput placeholder=''>
                            <mat-label>Read Date</mat-label>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class='button-container'>
                <button (click)='save()' color='primary' mat-raised-button>Save</button>
            </div>
        </form>
    </div>
</mat-card>
