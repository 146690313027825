import { Component, Input } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import { ChartService } from '../services/chart.service';

@Component({
    selector: 'app-charts',
    templateUrl: './charts.component.html',
    styleUrls: ['./charts.component.scss']
})
export class ChartsComponent {
    @Input() colorScheme: any = {
        domain: [
            '#FDC3B9FF',
            '#FFF3D8FF',
            '#62CECEFF',
            '#E6E6FA'],
    };
    @Input() data = {};
    @Input() legendPosition = LegendPosition.Right;
    @Input() showLegend = true;
    @Input() showLabels = true;
    @Input() type = 'column';
    @Input() view: any[] = [600, 300];
    @Input() explodeSlices = false;
    @Input() doughnut = false;

    gradient: any;
    multi: any[];
    single: any[];

    constructor(private chartService: ChartService) {
        // Object.assign(this, {single, multi});
        this.chartService.getAssignmentCompletionChart().subscribe(chartData => {
            this.single = chartData;
        });
    }
    onSelect($event: any) {
        console.log($event);
    }
}
