<mat-card appearance='outlined'>
    <mat-card-content>
        <h2>Reset Password</h2>
        <p>Enter your new password below.</p>
        <form (ngSubmit)='onSubmit()' [formGroup]='resetPasswordForm'>
            <mat-form-field class='full-width-input'>
                <input [type]="hide ? 'password' : 'text'" formControlName='password' matInput
                    placeholder='New Password' required>
                <mat-icon (click)='onHidePassword()' matSuffix
                    style='display:inline-block; z-index: 100000;'>{{ hide ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error>
                    Please provide a valid password
                </mat-error>
            </mat-form-field>
            <mat-form-field class='full-width-input'>
                <input [type]="hide ? 'password' : 'text'" formControlName='confirmPassword' matInput
                    placeholder='Confirm Password' required>
                <mat-icon (click)='onHidePassword()' matSuffix
                    style='z-index: 100000;'>{{ hide ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error>
                    Passwords do not match
                </mat-error>
            </mat-form-field>
            <button color='primary' mat-raised-button>Submit</button>
        </form>
    </mat-card-content>
</mat-card>
