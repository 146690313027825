<div class='submission-page'>
    <h1>Submissions Now Open</h1>
    <div class='submission-page-title'>
        <div class='submission-page-title-content'>
            <h1>Submissions for the Fall 2023 Edition of the Raven's Review are due by August 15th</h1>
            <div class='eligibility'>
                <h2>Applicants should be 18 years or old or younger and a student in Gwinnett County</h2>
            </div>
        </div>
    </div>
    <form #formDirective='ngForm' #submission (ngSubmit)='saveSubmission(submissionForm, formDirective)'
        [formGroup]='submissionForm'>
        <div style='display: flex; justify-content: center;'>
            <div style='margin: 20px;'>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='studentName' matInput placeholder=''>
                        <mat-label>Student Name</mat-label>
                        <mat-error *ngIf="studentName.hasError('required')">Student Name is Required</mat-error>
                        <mat-error *ngIf="studentName.invalid && !studentName.hasError('required')">Student Name Error
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field (input)='onDateValidatorInput()' floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='studentBirthDate' matInput placeholder=''>
                        <mat-label>Student Data of Birth</mat-label>
                        <mat-error *ngIf="studentBirthDate.hasError('required')">Student Birth Date Required</mat-error>
                        <mat-error *ngIf="studentBirthDate.invalid && !studentBirthDate.hasError('required')">Student
                            Birth Date Error
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='studentStreet' matInput placeholder=''>
                        <mat-label>Street</mat-label>
                        <mat-error *ngIf="studentStreet.hasError('required')">Student Street is Required</mat-error>
                        <mat-error *ngIf="studentStreet.invalid && !studentStreet.hasError('required')">Student Street
                            Error
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='studentCity' matInput placeholder=''>
                        <mat-label>City</mat-label>
                        <mat-error *ngIf="studentCity.hasError('required')">Student City is Required</mat-error>
                        <mat-error *ngIf="studentCity.invalid && !studentCity.hasError('required')">Student City Error
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='studentState' matInput placeholder=''>
                        <mat-label>State</mat-label>
                        <mat-error *ngIf="studentState.hasError('required')">Student State is Required</mat-error>
                        <mat-error *ngIf="studentState.invalid && !studentState.hasError('required')">Student State
                            Error
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div style='margin: 20px;'>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='studentZip' matInput placeholder=''>
                        <mat-label>Zip Code</mat-label>
                        <mat-error *ngIf="studentZip.hasError('required')">Student Zip is Required</mat-error>
                        <mat-error *ngIf="studentZip.invalid && !studentZip.hasError('required')">Student Zip Error
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='parentName' matInput placeholder=''>
                        <mat-label>Parent Name</mat-label>
                        <mat-error *ngIf="parentName.hasError('required')">Parent Name is Required</mat-error>
                        <mat-error *ngIf="parentName.invalid && !parentName.hasError('required')">Parent Name Error
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field (input)='onDateParentEmailInput()' floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='parentEmail' matInput placeholder=''>
                        <mat-label>Parent Email</mat-label>
                        <mat-error *ngIf="parentEmail.hasError('required')">Email Required</mat-error>
                        <mat-error *ngIf="parentEmail.invalid && !parentEmail.hasError('required')">Email Format Error
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='auto' hideRequiredMarker='false'>
                        <input formControlName='parentPhone' matInput placeholder=''>
                        <mat-label>Parent Phone</mat-label>
                        <mat-error *ngIf="parentPhone.hasError('required')">Parent Phone is Required</mat-error>
                        <mat-error *ngIf="parentPhone.invalid && !parentPhone.hasError('required')">Parent Phone Error
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class='written-submissions'>
            <div formArrayName='textSubmissons'>
                <div *ngFor='let note of textSubmissons.controls; let i=index'>
                    <mat-form-field floatLabel='true' hideRequiredMarker='false' style='width: 64vw;'>
                        <mat-label>Text Submissions {{ i + 1 }}</mat-label>
                        <textarea [formControlName]='i' matInput placeholder='' rows='10'></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div style='margin: 20px;'>
            <button (click)='addTextSubmission()' color='primary' mat-raised-button style='margin-right: 20px;'
                type='button'>Add Text Submission
            </button>
        </div>
        <div class='file-attachments'>
            <span>File Attachments</span>
            <div *ngFor='let image of submissionFormImages'>
                <span>{{ image.name }} {{ print(image) }}</span>
            </div>
        </div>
        <div>
            <app-drag-drop-file-upload (saveFile)='save($event)'
                style='flex: inherit;width: inherit;'>
            </app-drag-drop-file-upload>
        </div>
        <div style='text-align: center; margin: 20px;'>
            <button color='primary' mat-raised-button style='margin-right: 20px;' type='submit'>Submit</button>
        </div>
    </form>
</div>
