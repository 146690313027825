import { Component } from '@angular/core';

@Component({
    selector: 'app-messenger-page',
    templateUrl: './messenger-page.component.html',
    styleUrls: ['./messenger-page.component.scss']
})
export class MessengerPageComponent {

}
