import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Message } from '../_models/message';
import { Room } from '../_models/room';
import { User } from '../_models/user';
import { AuthService } from '../auth.service';

export class ChatMessage {
    message: string;
    id: string;
    name: string;

    constructor(o?: ChatMessage) {
        Object.assign(this, o);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    activeRoom = new BehaviorSubject<Room>(new Room());

    constructor(private authService: AuthService,
                private http: HttpClient) {
    }

    getAllRooms(): Observable<Room[]> {
        return this.http.get<Room[]>(`${environment.apiUrl}/api/rooms`).pipe(
            map(results => results.sort((a: Room, b: Room) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())),
            map(res => res.map(it => new Room(it))),
        );
    }

    getAllRoomsByUser(id: string): Observable<Room[]> {
        return this.http.get<Room[]>(`${environment.apiUrl}/api/rooms/user/${id}`).pipe(
            map(results => results.sort((a: Room, b: Room) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())),
            map(res => res.map(it => new Room(it))),
        );
    }

    createRoom(room: Room): Observable<Room> {
        const tempRoom: any = Object.assign({}, room) as any;
        tempRoom.users = room.users.map((u: any) => u.id);
        return this.http.post<Room>(`${environment.apiUrl}/api/room`, {room: tempRoom}).pipe(
            map(result => new Room(result))
        );
    }

    getMessagesFrom(room: Room): Observable<any[]> {
        if (room && room._id) {
            return this.http.get<Message[]>(`${environment.apiUrl}/api/messages/room/${room._id}`).pipe(
                map(res => {
                    return res?.map((it) =>
                        new ChatMessage({message: it.message, name: it.user.firstName + ' ' + it.user.lastName, id: it.user._id.toString()})
                    );
                }),
            );
        } else {
            return of([]);
        }
    }

    getAllContacts(): Observable<any> {
        return this.http.get<User[]>(`${environment.apiUrl}/api/users`).pipe(
            map(res => res.map(it => new User(it)))
        );
    }

    directChatExists(userList: User[]) {
        const users = userList.map((u: any) => u.id);
        return this.http.post<Room[]>(`${environment.apiUrl}/api/room/with/users/exists`, { users }).pipe(
            map(res => res.map(it => new Room(it)))
        );
    }
}
