<app-classroom-edit (changes)='hasChanges()'
    (myclose)='closeEdit()'
    *ngIf='selectedClassroom'
    [classroom]='selectedClassroom'>
</app-classroom-edit>
<app-classroom-list (deleteClassroom)='deleteClassroom($event);'
    *ngIf='!selectedClassroom'>
</app-classroom-list>
<div class='fab-container'>
    <button [routerLink]="['edit']" aria-label='Add' color='primary' mat-fab>
        <mat-icon>add</mat-icon>
    </button>
</div>
