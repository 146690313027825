<ngx-charts-pie-chart (select)='onSelect($event)'
    *ngIf="type === 'pie'"
    [doughnut]='doughnut'
    [explodeSlices]='explodeSlices'
    [gradient]='gradient'
    [labels]='showLabels'
    [legendPosition]='legendPosition'
    [legend]='showLegend'
    [results]='single'
    [scheme]='colorScheme'
    [view]='view'>
</ngx-charts-pie-chart>
