import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, merge, Observable, of, switchMap } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { Role } from '../../../_models/role';
import { User } from '../../../_models/user';
import { AuthService } from '../../../auth.service';
import { NotifyService } from '../../../core/notify.service';
import { RoleService } from '../../../services/role.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-user-add-edit',
    templateUrl: './user-add-edit.component.html',
    styleUrls: ['./user-add-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAddEditComponent {
    minPw = 4;
    roles: Role[];
    addOnBlur: any;
    parents: User[];
    allRoles: Role[];
    removable = true;
    loading: boolean;
    selectable = true;
    allParents: User[];
    filteredRoles: any;
    initializing = false;
    // agree = new FormControl();
    // phone = new FormControl();
    rolesCtrl = new FormControl();
    parentsCtrl = new FormControl();
    filteredParents: Observable<User[]>;
    @Output() changes = new EventEmitter<User>();
    @Output() myclose = new EventEmitter<User>();
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    userForm = this.fb.group({
        authz: [''],
        email: ['', Validators.required],
        birthdate: ['', Validators.required],
        userName: ['', Validators.required],
        lastName: ['', Validators.required],
        firstName: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(this.minPw)]],
        password2: ['', [Validators.required]],
        parents: this.parentsCtrl,
        roles: this.rolesCtrl,
        phone: ['', Validators.required],
        agree: ['']
    }, {validator: passwordMatchValidator});
    @ViewChild('roleInput') roleInput: ElementRef;
    @ViewChild('parentInput') parentInput: ElementRef;
    user$: Observable<User>;
    user: User;
    isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private roleService: RoleService,
                private authService: AuthService,
                private userService: UserService,
                private notifyService: NotifyService) {
        this.user$ = merge(
            this.route.paramMap.pipe(
                map((params: ParamMap) => params.get('userId')),
                tap((val: string) => {
                    this.isLoading$.next(true);
                }),
                switchMap((userId: string) => userId ? this.userService.getUser(userId)
                    .pipe(
                        catchError((err: any) => {
                            this.isLoading$.next(false);
                            return EMPTY;
                        }),
                    ) : of(new User())),
                tap((res: any) => {
                    this.init(res);
                    this.isLoading$.next(false);
                })
            )
        );
        this.roleService.allRoles().subscribe(roles => {
            this.allRoles = roles.map(it => new Role(it));
            this.filteredRoles = this.rolesCtrl.valueChanges.pipe(
                startWith(null),
                map((r: string | null) => r ? this.filterRole(r) : this.allRoles.slice()));
        });
        this.userService.getParentUsers().subscribe(users => {
            this.allParents = users.map(it => new User(it));
            this.filteredParents = this.parentsCtrl.valueChanges.pipe(
                startWith(null),
                map((parents: string | null) => parents ? this.filterParent(parents) : this.allParents.slice()));
        });
    }

    get password() {
        return this.userForm.get('password');
    }

    get password2() {
        return this.userForm.get('password2');
    }

    getChangedFields(): object {
        const changed = {};
        // tslint:disable-next-line:forin
        for (const key in this.userForm.controls) {
            console.log(key);
            if (this.userForm.controls[key].dirty) {
                changed[key] = this.userForm.controls[key].value;
            }
            if (key === 'roles') {
                if (this.roles.length === this.user.roles.length) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.user.roles.length; i++) {
                        if (this.roles.indexOf(this.roles[i]) === -1) {
                            changed[key] = this.roles;
                            break;
                        }
                    }
                } else {
                    changed[key] = this.roles;
                }
            }
            if (key === 'parents') {
                if (this.parents.length === this.user.parents.length) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.user.parents.length; i++) {
                        if (this.parents.indexOf(this.parents[i]) === -1) {
                            changed[key] = this.parents;
                            break;
                        }
                    }
                } else {
                    changed[key] = this.parents;
                }
            }
        }
        return changed;
    }

    update() {
        const user = this.mergeChangesFromForm(this.user);
        if (user?._id) {
            const changes = this.getChangedFields();
            console.log(changes);
            // @ts-ignore
            changes._id = this.user._id;
            this.isLoading$.next(true);
            this.userService.updateWithPasswordChange({user: changes}).subscribe((res) => {
                if (typeof res === 'object') {
                    console.log('saved');
                    this.changes.emit();
                    this.notifyService.success('User', 'Saved successfully');
                    this.isLoading$.next(false);
                } else {
                    this.notifyService.warning('User', res);
                    this.isLoading$.next(false);
                }
            });
        } else {
            this.loading = true;
            this.userService.save({user}).subscribe((res) => {
                if (typeof res === 'object') {
                    console.log('saved');
                    this.changes.emit();
                    this.notifyService.success('User', 'Saved successfully');
                    this.isLoading$.next(false);
                } else {
                    this.notifyService.warning('User', res);
                    this.isLoading$.next(false);
                }
            });
        }
    }

    onPasswordInput() {
        if (this.userForm.hasError('passwordMismatch')) {
            this.password2.setErrors([{passwordMismatch: true}]);
        } else {
            this.password2.setErrors(null);
        }
    }

    addParent(event: MatChipInputEvent): void {
        console.log(event);
    }

    removeParent(parent: any): void {
        const index = this.parents.indexOf(parent);

        if (index >= 0) {
            this.parents.splice(index, 1);
        }
    }

    filterParent(name: string) {
        return this.allParents.filter(parent =>
            parent.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selectedParent(event: MatAutocompleteSelectedEvent): void {
        this.parents.push(event.option.value);
        this.parentInput.nativeElement.value = '';
        this.parentsCtrl.setValue(null);
    }

    addRole(event: MatChipInputEvent): void {
        console.log(event);
        // const input = event.input;
        // const value = event.value;
        //
        // // Add our role
        // if ((value || '').trim()) {
        //   this.roles.push(value);
        // }
        //
        // // Reset the input value
        // if (input) {
        //   input.value = '';
        // }
        //
        // this.rolesCtrl.setValue(null);
    }

    removeRole(role: any): void {
        const index = this.roles.indexOf(role);

        if (index >= 0) {
            this.roles.splice(index, 1);
        }
    }

    filterRole(name: string) {
        return this.allRoles.filter(role =>
            role.roleName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selectedRole(event: MatAutocompleteSelectedEvent): void {
        this.roles.push(event.option.value);
        this.roleInput.nativeElement.value = '';
        this.rolesCtrl.setValue(null);
    }

    mergeChangesFromForm(user): any {
        const myuser = new User(user);
        // tslint:disable-next-line:forin
        for (const key in this.userForm.controls) {
            if (key === 'roles') {
                myuser.roles = [];
                this.roles.forEach(role => {
                    myuser.roles.push(role);
                });
            } else if (key === 'parents') {
                myuser.parents = [];
                this.parents.forEach(parent => {
                    myuser.parents.push(parent);
                });
            } else {
                myuser[key] = this.userForm.controls[key].value;
            }
        }
        return myuser;
    }
    goBack() {
        this.router.navigate(['admin/user']);
    }
    private init(user: User) {
        // console.log(this.user);
        this.user = new User(user);
        if (!this.initializing) {
            this.initializing = true;
            this.roles = [];
            if (this.user?.roles) {
                this.roles = this.user.roles.map(r => new Role(r));
            }
            this.parents = [];
            if (this.user?.parents) {
                this.parents = this.user.parents.map(p => new User(p));
            }
            this.rolesCtrl = new FormControl(this.roles);
            this.parentsCtrl = new FormControl(this.parents);
            this.userForm = this.fb.group({
                authz: [this.user.authz],
                email: [this.user.email, Validators.required],
                birthdate: [this.user.birthdate, Validators.required],
                userName: [this.user.userName],
                lastName: [this.user.lastName, Validators.required],
                firstName: [this.user.firstName, Validators.required],
                password: [this.user.password?.substring(0, 10), [Validators.required, Validators.minLength(this.minPw)]],
                password2: [this.user.password?.substring(0, 10), [Validators.required]],
                parents: this.parentsCtrl,
                roles: this.rolesCtrl,
                phone: this.user.phone,
                agree: this.user.agree
            }, {validator: passwordMatchValidator});
        }
    }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
    if (formGroup.get('password').value === formGroup.get('password2').value) {
        return null;
    } else {
        return {passwordMismatch: true};
    }
};
