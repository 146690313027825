<mat-sidenav-container>
    <mat-toolbar color='primary' fxFlex='100'></mat-toolbar>
    <mat-sidenav
        [(opened)]='loggedIn'
        [class.menu-close]='!isMenuOpen'
        [class.menu-open]='isMenuOpen'
        [disableClose]='true'
        class='hideIfMobile'
        fxFlex='100'
        id='sidenav'
        mode='side'>
        <div class='logo'>
            <a [routerLink]="['/']" mat-button>
                <img class='brand' src='assets/images/hazelwoodHollowLogo.png'>
            </a>
        </div>
        <mat-nav-list class='menu' fxFlex='' style='overflow-x: clip;'>
            <mat-toolbar class='toolbar' color='primary'></mat-toolbar>
            <app-menu-list-item *ngFor='let item of menu' [item]='item'
                [ngClass]="{'just-icons': !isMenuOpen}"></app-menu-list-item>
            <div *ngIf="route.indexOf('assignment') > -1" [ngClass]="{'no-legend': !isMenuOpen}" class='legend'>
                <div fxLayout='row' fxLayoutAlign='start center'><img src='../../assets/images/lavender_hexagon.png'>&nbsp;Not
                    Submitted & Not Accepted
                </div>
                <div fxLayout='row' fxLayoutAlign='start center'><img src='../../assets/images/cream_hexagon.png'>&nbsp;Submitted
                    & Not Accepted
                </div>
                <div fxLayout='row' fxLayoutAlign='start center'><img src='../../assets/images/teal_hexagon.png'>&nbsp;Submitted
                    & Accepted
                </div>
                <div fxLayout='row' fxLayoutAlign='start center'><img src='../../assets/images/pink_hexagon.png'>&nbsp;Not
                    Submitted & Overdue
                </div>
            </div>
            <div style='height: 100%'>&nbsp;</div>
            <div
                style='position: sticky; bottom: 0; text-align: center; padding-bottom: 20px; color: black; cursor: pointer'>
                <mat-icon (click)='shrinkMainMenu()'>{{ isMenuOpen ? 'arrow_back_ios' : 'arrow_forward_ios' }}
                </mat-icon>
            </div>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [ngStyle]="{'margin-left.px': contentMargin}" fxFlex='grow'>
        <div style='position: absolute; width: 100%; overflow-x: hidden'>
            <app-navigation (logoutMethod)='logoutMethod.emit()' [title]='title' [user]='user'></app-navigation>
            <!--      <app-breadcrumb *ngIf="loggedIn"></app-breadcrumb>-->
        </div>
        <div fxFlex='grow' style='overflow: auto; margin-top: 65px;'>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
