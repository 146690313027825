import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class HazelwoodSocket extends Socket {

    constructor(
        private authService: AuthService,
    ) {
        super({url: environment.urlSocket + '/socket', options: {}});
        if (this.authService?.userValue?.token) {
            this.ioSocket.auth = {token: this.authService.userValue.token};
        }
    }

    setupSocketConnection(token: string) {
        this.ioSocket = this.ioSocket(environment.urlSocket + '/socket', {
            auth: {
                token: this.authService.userValue.token
            }
        });
    }

    // Handle message receive event
    subscribeToMessages = (cb) => {
        if (!this.ioSocket) {
            return (true);
        }
        this.ioSocket.on('message', msg => {
            console.log('Room event received!');
            return cb(null, msg);
        });
    }

    sendMessage = ({message, roomName}, cb) => {
        if (this.ioSocket) {
            this.ioSocket.emit('message', {message, roomName}, cb);
        }
    }

    joinRoom = (roomName) => {
        this.ioSocket.emit('join', roomName);
    }
}
