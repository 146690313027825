import { User } from './user';

export class Classroom {
    constructor(o?: Classroom) {
        Object.assign(this, o);
    }
    // tslint:disable-next-line:variable-name
    _id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    teachers: User[];
    students: User[];
    description: string;
    displayUsername?: string;

    get listOfTeachers() {
        return this.teachers.map(t => ' ' + t.firstName + ' ' + t.lastName);
    }
}
