<ng-container>
    <div *ngIf='assignmentList$ | async as assignmentList'>
        <div class='assignment-list'>
            <div *ngFor='let assignment of assignmentList'>
                <div class='assignment-column-small'>{{ assignment.dueDate | date }}</div>
                <div class='assignment-column-medium'>{{ assignment.displayUsername }}</div>
                <div class='assignment-column'>{{ assignment.name }}</div>
                <div class='assignment-column-small'>
                    <select (ngModelChange)='save(assignment)' [(ngModel)]='assignment.classroomId'>
                        <option *ngFor='let classroom of classrooms' [ngValue]='classroom.id'>{{ classroom.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</ng-container>
