export class Attachment {
    constructor(o?: Attachment) {
        Object.assign(this, o);
    }
    // tslint:disable-next-line:variable-name
    _id: string;
    name: string;
    linkUrl: string;
    attachmentType: string;
    text: string;
    path: string;
    size: number;
    filename: string;
    encoding: string;
    mimetype: string;
    fieldname: string;
    destination: string;
    originalname: string;
}
