<div class='route-content-top'>
    <ol class='breadcrumbs'>
        <li *ngFor='let breadcrumb of breadcrumbs; index as i; last as isLast'>
            <ng-container *ngIf='!breadcrumb.pauseDisplay'>
                <span (click)='routeTo(i)' [class.breadcrumb-history-link]='!isLast'
                    class='breadcrumb-current-route'>{{ breadcrumb.name }}</span>
                <span *ngIf='!isLast'>&nbsp;»&nbsp;</span>
            </ng-container>
        </li>
    </ol>
</div>
