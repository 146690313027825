import { Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isValid } from 'date-fns';
import { NotifyService } from '../../core/notify.service';
import { SubmissionService } from '../../services/submission.service';

@Component({
    selector: 'app-newsletter-submission',
    templateUrl: './newsletter-submission.component.html',
    styleUrls: ['./newsletter-submission.component.scss']
})
export class NewsletterSubmissionComponent {
    @ViewChild('submission', {static: false}) public submission: ElementRef;
    submissionForm: FormGroup;
    submissionFormData = new FormData();

    get submissionFormImages(): any {
        return this.submissionFormData.getAll('image');
    }

    get studentName() {
        return this.submissionForm.get('studentName');
    }

    get studentBirthDate() {
        return this.submissionForm.get('studentBirthDate');
    }

    get studentStreet() {
        return this.submissionForm.get('studentStreet');
    }

    get studentCity() {
        return this.submissionForm.get('studentCity');
    }

    get studentState() {
        return this.submissionForm.get('studentState');
    }

    get studentZip() {
        return this.submissionForm.get('studentZip');
    }

    get parentPhone() {
        return this.submissionForm.get('parentPhone');
    }

    get parentName() {
        return this.submissionForm.get('parentName');
    }

    get parentEmail() {
        return this.submissionForm.get('parentEmail');
    }

    get textSubmissons() {
        return this.submissionForm.get('textSubmissons') as FormArray;
    }

    constructor(private fb: FormBuilder,
                private notifyService: NotifyService,
                private submissionService: SubmissionService) {
        this.submissionForm = this.fb.group({
            studentName: [null, Validators.required],
            studentBirthDate: [null, [Validators.required, dateValidator]],
            studentStreet: [null, Validators.required],
            studentCity: [null, Validators.required],
            studentState: [null, Validators.required],
            studentZip: [null, Validators.required],
            parentName: [null, Validators.required],
            parentEmail: [null, [Validators.required, emailValidator(
                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)]],
            parentPhone: [null, Validators.required],
            textSubmissons: this.fb.array([
                this.fb.control<string>('')
            ]),
        }, {validator: dateValidator, emailValidator});
        this.submissionFormData.append('textSubmission', '');
    }

    onDateValidatorInput() {
        if (this.submissionForm.hasError('invalidDate')) {
            this.studentBirthDate.setErrors([{invalidDate: true}]);
        } else {
            this.studentBirthDate.setErrors(null);
        }
    }

    onDateParentEmailInput() {
        if (this.submissionForm.hasError('invalidEmail')) {
            this.parentEmail.setErrors([{invalidEmail: true}]);
        } else {
            this.parentEmail.setErrors(null);
        }
    }

    saveSubmission(myForm, formDirective) {
        let isFormValid = true;
        isFormValid = isFormValid && !this.studentName.invalid;
        isFormValid = isFormValid && !this.studentBirthDate.invalid;
        isFormValid = isFormValid && !this.studentStreet.invalid;
        isFormValid = isFormValid && !this.studentCity.invalid;
        isFormValid = isFormValid && !this.studentState.invalid;
        isFormValid = isFormValid && !this.studentZip.invalid;
        isFormValid = isFormValid && !this.parentName.invalid;
        isFormValid = isFormValid && !this.parentEmail.invalid;
        isFormValid = isFormValid && !this.parentPhone.invalid;

        if (isFormValid) {
            console.log('Submitting');
            this.submissionFormData.append('studentName', this.studentName.value);
            this.submissionFormData.append('studentBirthDate', this.studentBirthDate.value);
            this.submissionFormData.append('studentStreet', this.studentStreet.value);
            this.submissionFormData.append('studentCity', this.studentCity.value);
            this.submissionFormData.append('studentState', this.studentState.value);
            this.submissionFormData.append('studentZip', this.studentZip.value);
            this.submissionFormData.append('parentName', this.parentName.value);
            this.submissionFormData.append('parentEmail', this.parentEmail.value);
            this.submissionFormData.append('parentPhone', this.parentPhone.value);
            const textSubmissionList = this.textSubmissons.controls;
            let i = 1;
            textSubmissionList.forEach(ts => {
                if (ts.value) {
                    this.submissionFormData.append(`textSubmission${i}`, ts.value);
                    i++;
                }
            });
            this.submissionService.submit(this.submissionFormData).subscribe((res) => {
                console.log(res);
                this.notifyService.success('Success', 'Submission send successful');
                this.submissionForm.reset();
                formDirective.resetForm();

                // this.studentName.setValue('');
                // this.studentBirthDate.setValue('');
                // this.studentStreet.setValue('');
                // this.studentCity.setValue('');
                // this.studentState.setValue('');
                // this.studentZip.setValue('');
                // this.parentName.setValue('');
                // this.parentEmail.setValue('');
                // this.parentPhone.setValue('');
                // this.textSubmissons.setValue([
                //   this.fb.control<string>('')
                // ]);
                this.submissionFormData = new FormData();
            });
        } else {
            console.log('There are errors');
            this.notifyService.warning('Failure', 'Make sure to fill out all fields');
        }
    }

    save(file: File) {
        console.log('upload');
        this.submissionFormData.append('image', file);
    }
    print(image: any) {
        console.log(image);
    }
    addTextSubmission() {
        const newlist = this.textSubmissons.controls;
        newlist.push(this.fb.control(''));
    }
}

export function dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        console.log(control);
        if (!isValid(control.value)) {
            return {invalidDate: true};
        }
    };
}

export function emailValidator(emailReg: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        console.log(control);
        const allowed = !emailReg.test(control.value);
        return allowed ? {invalidEmail: {value: control.value}} : null;
    };
}
