import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { User } from './_models/user';
import { AuthorizationRole } from './authorization.role';

@Injectable({providedIn: 'root'})
export class AuthService {
    public isLoggedIn = false;
    public user: Observable<User>;
    private userSubject: BehaviorSubject<User>;

    constructor(private router: Router,
                private http: HttpClient,
                private socialAuthService: SocialAuthService) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    set IsLoggedIn(value) {
        this.isLoggedIn = value;
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    public get isAdmin(): boolean {
        return this.permitted('Admin#R');
    }

    login(userName: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/user/login`, {user: {userName, password}})
            .pipe(map(resp => {
                if (resp?.token) {
                    console.log(resp);
                    // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                    resp.authdata = window.btoa(userName + ':' + password);
                    const tempUser = new User(resp.user);
                    tempUser.authdata = resp.authdata;
                    tempUser.token = resp.token;
                    localStorage.setItem('user', JSON.stringify(tempUser));
                    this.userSubject.next(tempUser);
                    this.isLoggedIn = true;
                    return tempUser;
                }
                return {};
            }));
    }

    loginAs(email: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/user/login-as`, {user: {email}})
            .pipe(map(resp => {
                if (resp?.token) {
                    console.log(resp);
                    // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                    resp.authdata = window.btoa(resp.userName + ':' + resp.email);
                    const tempUser = new User(resp.user);
                    tempUser.authdata = resp.authdata;
                    tempUser.token = resp.token;
                    localStorage.setItem('user', JSON.stringify(tempUser));
                    this.userSubject.next(tempUser);
                    this.isLoggedIn = true;
                    return tempUser;
                }
                return {};
            }));
    }

    async logout() {
        // remove user from local storage to log user out
        const self = this;
        this.isLoggedIn = false;
        this.userSubject.next(null);
        localStorage.removeItem('user');
        setTimeout(() => {
            this.socialAuthService.signOut(true).then(() => {
                console.log('social media logout');
                self.router.navigate(['login']);
            });
        }, 1000);
        this.router.navigate(['login']);
    }

    checkStatus(headers?: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/api/jwt-valid`, {headers});
    }

    permitted(...perms: string[]) {
        let isPermitted = false;
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const authzList = user.authz.map(it => new AuthorizationRole((it)));
            authzList.forEach(authz => {
                perms.forEach(perm => {
                    const permit = new AuthorizationRole(perm);
                    if (permit.name === authz.name) {
                        isPermitted = authz.hasPermisson(perm);
                        if (!isPermitted) {
                            return isPermitted;
                        }
                    }
                });
            });
            return isPermitted;
        }
        return false;
    }
}
