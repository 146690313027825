<div>
    <mat-card style='margin: 20px;padding: 20px;'>
        <mat-form-field>
            <mat-select [(value)]='albumSelected' placeholder='Album'>
                <mat-option value='select'>Select an Album</mat-option>
                <mat-option *ngFor='let album of albums' [value]='album.prefix'>{{ album.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div style='display: flex; width: -webkit-fill-available;'>
            <app-drag-drop-file-upload (saveFile)='save($event)' *ngIf="albumSelected !== 'select'"
                style='flex: inherit;width: inherit;'>
            </app-drag-drop-file-upload>
        </div>
        <div>
            <div *ngIf='imageUrl'>
                Preview Image from AWS
                <br/>
                <img src='https://hazelwoodprepphotos.s3.amazonaws.com/{{ imageUrl }}' width='200px'/>
            </div>
        </div>
    </mat-card>
    <mat-card style='margin: 20px;padding: 20px;'>
        <app-photo-album [shouldRefresh]='shouldRefresh'></app-photo-album>
    </mat-card>
</div>
