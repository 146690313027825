export class ChartDataSingle {
    name: string;
    value: string;

    constructor(o?: ChartDataSingle) {
        Object.assign(this, o);
    }
}

export class ChartDataMulti {
    name: string;
    series: Array<ChartDataSingle>;

    constructor(o?: ChartDataMulti) {
        Object.assign(this, o);
    }
}
