<div>
    <div *ngFor='let album of albums'>
        <span>{{ album.name }}</span>
        <div style='display: flex; flex-wrap: wrap;'>
            <div *ngFor='let imageName of album.images' style='margin: 20px;'>
                <img (click)='openImage(imageName.url)' src='https://hazelwoodprepphotos.s3.amazonaws.com/{{ imageName.url }}'
                    style='cursor: pointer' width='200px'/>
            </div>
        </div>
    </div>
</div>
