import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Classroom } from '../../../_models/classroom';
import { StorageService } from '../../../core/storage.service';
import { ClassroomService } from '../../../services/classroom.service';
import { ConfirmDialogService } from '../../../services/confirm-dialog.service';

@Component({
    selector: 'app-classroom-list',
    templateUrl: './classroom-list.component.html',
    styleUrls: ['./classroom-list.component.scss']
})
export class ClassroomListComponent implements OnInit, OnChanges {
    path = 'edit/';
    busy = false;
    hasResults: boolean;
    classrooms: Classroom[];
    @Input() linkTo: string;
    isDeleteClassroomUsed: boolean;
    dataSource: MatTableDataSource<Classroom>;
    @Output() deleteClassroom = new EventEmitter<Classroom>();
    displayedColumns: string[] = ['name', 'description', 'displayUsername', 'createAssignment', 'delete'];

    constructor(private classroomService: ClassroomService,
                private dialogService: ConfirmDialogService,
                private route: ActivatedRoute,
                private router: Router,
                private storage: StorageService) {
    }

    ngOnInit(): void {
        this.isDeleteClassroomUsed = this.deleteClassroom.observers.length > 0;
        this.classroomService.getClassrooms().subscribe(rooms => {
            this.classrooms = rooms;
            this.init();
        });
        if (this.linkTo) {
            this.path = this.linkTo;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.init();
    }

    init() {
        console.log(this.classrooms);
        this.dataSource = new MatTableDataSource<Classroom>(this.classrooms);
        this.hasResults = this.classrooms?.length > 0;
    }

    delete(element) {
        this.busy = true;
        const options = {
            title: 'Delete classroom "' + element.name + '" ? ',
            message: 'By deleting you will permanently lose this classroom.',
            cancelText: 'CANCEL',
            confirmText: 'YES, DELETE'
        };

        this.dialogService.open(options);

        this.dialogService.confirmed().subscribe(confirmed => {
            if (confirmed) {
                console.log('Delete;', element);
                this.deleteClassroom.emit(element);
            }
        });
    }

    createAssignment(element) {
        this.busy = true;
        console.log('create assignment clicked', element);
        this.storage.save('defaulAssignmentName', element.name + ' - ');
        this.storage.save('defaultTeachers', element.teachers);
        this.storage.save('defaultStudents', element.students);
        if (window.location.href.includes(':')) {
            const port = window.location.href.substring(window.location.href.indexOf(':') + 1, 5);
            window.location.href = window.location.protocol + '//' + window.location.host + port + '/#/assignment/edit';
        } else {
            window.location.href = window.location.protocol + '//' + window.location.host + '/#/assignment/edit';
        }
    }
}
