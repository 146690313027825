import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Classroom } from '../_models/classroom';

@Injectable({
    providedIn: 'root'
})
export class ClassroomService {

    constructor(private http: HttpClient) {
    }

    getClassroom(id: any): Observable<Classroom> {
        return this.http.get<any>(`${environment.apiUrl}/api/classroom/${id}`).pipe(
            map(res => new Classroom(res))
        );
    }

    getClassrooms(): Observable<Classroom[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/classrooms`).pipe(
            map(res => res.map(it => new Classroom(it)))
        );
    }

    save(classroom: any): Observable<Classroom> {
        const t = Object.assign({}, classroom);
        if (classroom?.teachers?.length > 0) {
            t.teachers = classroom?.teachers.map(p => p._id);
        }
        if (classroom?.students?.length > 0) {
            t.students = classroom?.students.map(p => p._id);
        }
        return this.http.post<Classroom>(`${environment.apiUrl}/api/classroom`, {classroom: t}).pipe(
            map(res => new Classroom(res))
        );
    }

    update(classroom: any): Observable<Classroom> {
        const t = Object.assign({}, classroom);
        if (classroom?.teachers?.length > 0) {
            t.teachers = classroom?.teachers.map(p => p._id);
        }
        if (classroom?.students?.length > 0) {
            t.students = classroom?.students.map(p => p._id);
        }
        return this.http.put<Classroom>(`${environment.apiUrl}/api/classroom`, {classroom: t}).pipe(
            map(res => new Classroom(res))
        );
    }

    delete(classroom: Classroom) {
        return this.http.delete<any>(`${environment.apiUrl}/api/classroom/${classroom._id}`).pipe(
            map(res => new Classroom(res))
        );
    }
}
