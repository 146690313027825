<div *ngIf='classrooms && hasResults' class='search-results' fxLayout='column' fxLayoutAlign='space-around center'>
    <table [dataSource]='dataSource' class='mat-elevation-z8' mat-table matSort>
        <!-- Name Column -->
        <ng-container matColumnDef='name'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
            <td *matCellDef='let element' mat-cell> {{ element.name }}</td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef='description'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Description</th>
            <td *matCellDef='let element' mat-cell> {{ element.description }}</td>
        </ng-container>

        <!-- Display Column -->
        <ng-container matColumnDef='displayUsername'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Teachers</th>
            <td *matCellDef='let element' mat-cell> {{ element.listOfTeachers }}</td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef='delete'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef='let element' mat-cell style='text-align: center; margin: 20px;'>
                <button (click)='delete(element)' *ngIf='isDeleteClassroomUsed' authz='Admin#D'>
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Create Assignment Column -->
        <ng-container matColumnDef='createAssignment'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef='let element' mat-cell style='text-align: center; margin: 20px;'>
                <button (click)='createAssignment(element)' authz='Admin#D' class='mat-primary' mat-raised-button>Create
                    Assignment
                </button>
            </td>
        </ng-container>

        <tr *matHeaderRowDef='displayedColumns; sticky: true' mat-header-row></tr>
        <tr *matRowDef='let row; columns: displayedColumns;' [routerLink]='!busy ? [path + row._id ] : []' class='assignment-row'
            mat-row></tr>
    </table>
</div>
