import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Album } from '../_models/album';

class Photo {
    url: string;
    constructor(o?) {
        Object.assign(this, o);
    }

}

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    constructor(private http: HttpClient) {
    }

    getImages(album: string) {
        return this.http.get<any>(`${environment.apiUrl}/api/photos/${album}`).pipe(
            map(res => res.map(it => new Photo(it)))
        );
    }

    getAlbums(): Observable<Album[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/photo/albums`).pipe(
            map(res => res.albums.map(it => new Album(it)))
        );
    }

    imageUpload(imageForm: FormData) {
        console.log('image uploading');
        return this.http.post(`${environment.apiUrl}/api/v1/upload`, imageForm);
    }

    photoUpload(album: string, imageForm: FormData) {
        console.log('image uploading');
        return this.http.post(`${environment.apiUrl}/api/upload/${album}`, imageForm);
    }
}
