import { Attachment } from './attachment';
import { Note } from './note';
import { User } from './user';

export class Assignment {
    accepted: boolean;
    attachments: Attachment[];
    bookletComplete: boolean;
    createdBy: string;
    createdAt: Date;
    description: string;
    dueDate: Date;
    googleDocId: string;
    // tslint:disable-next-line:variable-name
    _id: string;
    modifiedBy: string;
    name: string;
    notes: Note[];
    quiz: string[];
    reminders: bigint;
    submitted = false;
    teachers: User[];
    user: User[];
    isSheet = false;
    grade: number;
    classroomId: string;

    get displayUsername() {
        return this.user.map(u => u.firstName).join(',');
    }

    constructor(o?: Assignment) {
        Object.assign(this, o);
        if (o?.user) {
            Object.assign(this.user, o?.user);
        } else {
            this.user = [];
            Object.assign(this.user, o?.user);
        }
    }
}
