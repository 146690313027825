import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class StorageService {
    private localStorage: Storage = window.localStorage;
    private sessionStorage: Storage = window.sessionStorage;

    get<T>(key: string): T {
        const data = this.sessionStorage.getItem(key) || this.localStorage.getItem(key);
        return data ? JSON.parse(data) : null;
    }

    getSession<T>(key: string): T {
        const data = this.sessionStorage.getItem(key);
        return data ? JSON.parse(data) : null;
    }

    save(key: string, value: any): void {
        if (value == null) {
            return this.remove(key);
        }

        this.localStorage.setItem(key, JSON.stringify(value));
        return value;
    }

    saveSession(key: string, value: any): void {
        if (value == null) {
            this.remove(key);
        }
        this.sessionStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string): void {
        this.removeSession(key);
        this.localStorage.removeItem(key);
    }

    removePrefix(prefix: string): void {
        for (const key in this.localStorage) {
            if (key.startsWith(prefix)) {
                this.remove(key);
            }
        }
    }

    removeSession(key: string): void {
        this.sessionStorage.removeItem(key);
    }
}
