<mat-toolbar class='expanded-toolbar' color='primary'>
    <button [matMenuTriggerFor]='menu' aria-label='' class='hideIfNotMobile' mat-icon-button>
        <mat-icon>menu</mat-icon>
    </button>
    <app-breadcrumb *ngIf='loggedIn'></app-breadcrumb>
    <mat-menu #menu='matMenu'>
        <button authz='Admin#R' mat-menu-item routerLink='/admin-classroom'>
            <mat-icon>class</mat-icon>
            <span>Classroom Management</span>
        </button>
        <button authz='Admin#R' mat-menu-item routerLink='/user-admin'>
            <mat-icon>person_add</mat-icon>
            <span>User Management</span>
        </button>
        <button mat-menu-item routerLink='/assignment'>
            <mat-icon>notes</mat-icon>
            <span>Assignment</span>
        </button>
        <button mat-menu-item routerLink='/photo/upload'>
            <mat-icon>photo</mat-icon>
            <span>Photo Upload</span>
        </button>
    </mat-menu>
    <span>
        <button mat-button routerLink='/'>{{title}}</button>
    </span>
    <span class='filler'></span>
    <div fxLayout='row' fxShow='false' fxShow.gt-sm>
        <button *ngIf='!user' mat-button routerLink='/login'>
            <mat-icon>power_settings_new</mat-icon>
            Login
        </button>
        <button *ngIf='user' [matMenuTriggerFor]='accMenu' aria-label='' fxFlex='grow' mat-icon-button
                style='width: 100%'>
            <span class='login-name-icon'><mat-icon>person</mat-icon>&nbsp;{{user.firstName}}
            </span>
        </button>
        <mat-menu #accMenu='matMenu'>
            <button (click)='logout()' *ngIf='user' mat-button>
                <mat-icon>exit_to_app</mat-icon>
                Logout
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
