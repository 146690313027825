<div class='emoji-picker'>
    <div class='emoji-search'>
        <input #search (keydown)='onKeyDown(search)' class='emoji-search-input'>
    </div>
    <div class='emoji-list'>
        <div *ngFor='let allEmojiGroups of filteredEmoji'>
            {{ allEmojiGroups.group }}
            <div class='emojis'>
                <div (click)='onSelection(emojis)' *ngFor='let emojis of allEmojiGroups.emojis' class='select-emoji'>
                    <span [innerHTML]='fixCode(emojis.code)' class='emoji'></span>
                </div>
            </div>
        </div>
    </div>
</div>
