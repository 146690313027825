<div class='users-list-container'>
    <div class='users-search-container'>
        <form [formGroup]='searchForm' class='users-search-form'>
            <input data-lpignore='true' formControlName='search' placeholder='User Name. Ex John Doe' type='search'>
        </form>
    </div>
    <div *ngIf='(users$ | async)'>
        <div *ngFor='let user of users'>
            <div class='user-card'>
                <span>{{ user.firstName }} {{ user.lastName }}</span>
            </div>
        </div>
    </div>
</div>
