import { Message } from './message';
import { User } from './user';

export class Room {
    constructor(o?: Room) {
        Object.assign(this, o);
    }

    // tslint:disable-next-line:variable-name
    _id: number;
    name: string;
    topic: string;
    users: Array<User>;
    messages: Message[];
    createdAt: Date;
    updatedAt: Date;
}
