<div style='display: flex; align-items: center; margin: 20px; justify-content: center'>
    <div>
        <button (viewDateChange)='closeOpenMonthViewDay()' [(viewDate)]='viewDate'
            [view]='view'
            mat-raised-button
            mwlCalendarPreviousView>Previous
        </button>
        <button [(viewDate)]='viewDate' mat-raised-button
            mwlCalendarToday>Today
        </button>
        <button (viewDateChange)='closeOpenMonthViewDay()' [(viewDate)]='viewDate'
            [view]='view'
            mat-raised-button
            mwlCalendarNextView>Next
        </button>
    </div>
    <div class='calendar-title'>
        <h2>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h2>
    </div>
    <div>
        <button (click)='setView(CalendarView.Month)' [class.active]='view === CalendarView.Month' mat-raised-button>
            Month
        </button>
        <button (click)='setView(CalendarView.Week)' [class.active]='view === CalendarView.Week' mat-raised-button>Week
        </button>
        <button (click)='setView(CalendarView.Day)' [class.active]='view === CalendarView.Day' mat-raised-button>Day
        </button>
    </div>
</div>
<div class='calendar'>
    <mat-card class='calendar-card'>
        <div [ngSwitch]='view'>
            <mwl-calendar-month-view
                (dayClicked)='dayClicked($event.day)'
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)='eventTimesChanged($event)'
                *ngSwitchCase='CalendarView.Month'
                [activeDayIsOpen]='activeDayIsOpen'
                [events]='events'
                [refresh]='refresh'
                [viewDate]='viewDate'>
            </mwl-calendar-month-view>
            <mwl-calendar-week-view
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)='eventTimesChanged($event)'
                *ngSwitchCase='CalendarView.Week'
                [events]='events'
                [refresh]='refresh'
                [viewDate]='viewDate'>
            </mwl-calendar-week-view>
            <mwl-calendar-day-view
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)='eventTimesChanged($event)'
                *ngSwitchCase='CalendarView.Day'
                [events]='events'
                [refresh]='refresh'
                [viewDate]='viewDate'>
            </mwl-calendar-day-view>
        </div>
    </mat-card>
</div>


