import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridFilterItem, GridHeading } from '@troyaaronjones/angular_material_data_grid';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BlogService } from '../../../services/blog.service';
import { ConfirmDialogService } from '../../../services/confirm-dialog.service';

@Component({
    selector: 'app-blog-grid',
    templateUrl: './blog-grid.component.html',
    styleUrls: ['./blog-grid.component.scss']
})
export class BlogGridComponent {
    deletedBlogPosts: any[];
    headings: GridHeading[] = [
        {fieldName: 'createdDate', display: 'Created', type: 'date', width: '40px', sort: 'desc'},
        {fieldName: 'pinned', display: 'Pinned', type: 'string', width: '30px', textAlign: 'left'},
        {fieldName: 'title', display: 'Title', type: 'string', width: '100px', textAlign: 'left'},
        {fieldName: 'body', display: 'Body', type: 'string', width: '150px', textAlign: 'left'},
    ];
    initialSort = {sort: 'desc', sortField: 'createdDate'} as any;
    initialFilters: GridFilterItem[] = [];
    isBusy: boolean;
    selectedRows: any;
    selecting = false;
    url = `${environment.apiUrl}/api/blogs`;

    constructor(
        private blogService: BlogService,
        private dialogService: ConfirmDialogService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    onSelectionChange(selectedRows: any) {
        this.selecting = true;
        setTimeout(() => {
            this.selecting = false;
        }, 3000);
        this.selectedRows = selectedRows;
    }

    responseReceived(response: any) {
        response.gridData.forEach((item) => {
            item.createdDate = formatDate(new Date(item.createdDate), 'MM/dd/yyyy', 'en-US');
            item.pinned = item.pinned ? 'true' : 'false';
        });
    }

    requestBodyChanged($event: any) {

    }

    edit(row: any) {
        if (!this.selecting) {
            // this.selectAssignment.emit(row);
            this.router.navigate([`edit/${row.item._id}`], {relativeTo: this.route});
        }
    }

    delete() {
        if (this.selectedRows) {
            this.isBusy = true;
            const options = {
                title: 'Delete Blog Posts? ',
                message: 'By deleting you will permanently lose ' + this.selectedRows.length + ' blog/s',
                cancelText: 'CANCEL',
                confirmText: 'YES, DELETE'
            };

            this.dialogService.open(options);

            this.dialogService.confirmed().subscribe(confirmed => {
                if (confirmed) {
                    console.log('Delete;', this.selectedRows);
                    this.deleteBlogPosts(this.selectedRows, () => {
                        this.isBusy = false;
                        window.location.reload();
                    });
                } else {
                    this.isBusy = false;
                }
            });
        }
    }

    deleteBlogPosts(selected, callback) {
        this.deletedBlogPosts = [];
        const deletes = new Array<Observable<any>>();
        this.selectedRows.forEach((a) => {
            deletes.push(this.blogService.delete(a));
        });
        const observable = forkJoin(deletes);
        observable.subscribe({
            next: value => this.deletedBlogPosts.push(value),
            complete: () => callback(),
        });
    }
}
