import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { Booklog } from '../../_models/booklog';
import { AuthService } from '../../auth.service';
import { StorageService } from '../../core/storage.service';
import { Utils } from '../../core/utils';
import { BooklogService } from '../../services/booklog.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-booklogService',
    templateUrl: './booklog.component.html',
    styleUrls: ['./booklog.component.scss']
})
export class BooklogComponent {
    booklogForm: FormGroup;
    hasUserDetails = false;
    isAdmin = false;
    isParent = false;
    selectedUser: any;
    users: any;
    books: Booklog[];

    get bookAuthor() {
        return this.booklogForm.get('bookAuthor') as FormArray;
    }

    get bookReadDate() {
        return this.booklogForm.get('bookReadDate') as FormArray;
    }

    get bookIds() {
        return this.booklogForm.get('bookIds') as FormArray;
    }

    get bookTitles() {
        return this.booklogForm.get('bookTitles') as FormArray;
    }

    constructor(private authService: AuthService,
                private booklogService: BooklogService,
                private userService: UserService,
                private storageService: StorageService) {

        this.initBookLogForm();

        this.userService.getAllStudents().subscribe((res) => {
            res.sort(Utils.sortByFirstLastName);
            this.users = res;
            this.loadBooklog((this.authService.userValue as any).id);
            this.userService.getUser((this.authService.userValue as any).id).subscribe(resp => {
                if (resp.roles.map(r => r.roleName).indexOf('Parent') > -1) {
                    if (!this.isAdmin) {
                        this.isParent = true;
                    }
                } else {
                    const temp = JSON.parse(this.storageService.get('selectedStudent'))?.filters;
                    if (temp) {
                        console.log(temp);
                    } else {
                        if (!this.isAdmin) {

                        } else {
                            this.hasUserDetails = true;
                        }
                    }
                }
            });
        });
    }

    initBookLogForm() {
        this.booklogForm = new FormGroup({
            bookAuthor: new FormArray([]),
            bookReadDate: new FormArray([]),
            bookIds: new FormArray([]),
            bookTitles: new FormArray([])
        });
    }

    addBookTitle() {
        this.books.push(new Booklog());
        this.bookAuthor.push(new FormControl());
        this.bookReadDate.push(new FormControl());
        this.bookIds.push(new FormControl());
        this.bookTitles.push(new FormControl());
    }

    loadBooklog(userId: string) {
        if (userId) {
            this.booklogService.getAllByUser(userId).subscribe((books) => {
                this.books = books;
                console.log(books);
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < books.length; i++) {
                    this.bookAuthor.push(new FormControl());
                    this.bookReadDate.push(new FormControl());
                    this.bookIds.push(new FormControl());
                    this.bookTitles.push(new FormControl());
                }
                const bookIds = books.map(b => b._id);
                const bookTitles = books.map(b => b.title);
                const bookAuthor = books.map(b => b.author);
                const bookReadDate = books.map(b => b.readDate);
                let tmpFormArray = new FormArray([]);
                bookAuthor.forEach(bauth => {
                    tmpFormArray.push(new FormControl(bauth));
                });
                this.booklogForm.controls.bookAuthor.setValue(tmpFormArray.value);
                tmpFormArray = new FormArray([]);
                bookReadDate.forEach(bread => {
                    tmpFormArray.push(new FormControl(new Date(+bread).toLocaleDateString()));
                });
                this.booklogForm.controls.bookReadDate.setValue(tmpFormArray.value);
                tmpFormArray = new FormArray([]);
                bookIds.forEach(bid => {
                    tmpFormArray.push(new FormControl(bid));
                });
                this.booklogForm.controls.bookIds.setValue(tmpFormArray.value);
                tmpFormArray = new FormArray([]);
                bookTitles.forEach(bTitle => {
                    tmpFormArray.push(new FormControl(bTitle));
                });
                this.booklogForm.controls.bookTitles.setValue(tmpFormArray.value);
            });
        }
    }

    select(user: any) {
        this.initBookLogForm();
        this.loadBooklog(user.value._id);
    }

    save() {
        console.log(this.booklogForm);
        // tslint:disable-next-line:forin
        for (const key in this.booklogForm.controls) {
            console.log(this.booklogForm.get(key).value);
            if (key === 'bookIds') {
                for (let i = 0; i < this.booklogForm.get(key).value.length; i++) {
                    if (this.books?.length <= i) {
                        this.books.push(new Booklog());
                    }
                    this.books[i]._id = this.booklogForm.get(key)?.value[i] || null;
                }
            } else if (key === 'bookAuthor') {
                for (let i = 0; i < this.booklogForm.get(key).value.length; i++) {
                    if (this.books?.length <= i) {
                        this.books.push(new Booklog());
                    }
                    this.books[i].author = this.booklogForm.get(key)?.value[i] || '';
                }
            } else if (key === 'bookReadDate') {
                for (let i = 0; i < this.booklogForm.get(key).value.length; i++) {
                    if (this.books?.length <= i) {
                        this.books.push(new Booklog());
                    }
                    this.books[i].readDate = this.booklogForm.get(key)?.value[i] || '';
                }
            } else if (key === 'bookTitles') {
                for (let i = 0; i < this.booklogForm.get(key).value.length; i++) {
                    if (this.books?.length <= i) {
                        this.books.push(new Booklog());
                    }
                    this.books[i].title = this.booklogForm.get(key)?.value[i] || '';
                }
            }
        }

        console.log('books:', this.books);

        this.forkJoinSaveBooks(() => {
            this.initBookLogForm();
            this.loadBooklog(this.selectedUser._id);
        });
    }

    forkJoinSaveBooks(callback: any) {
        const saved = [];
        const saves = new Array<Observable<any>>();
        this.books.forEach(book => {
            if (this.selectedUser) {
                book.user = this.selectedUser;
            }
            if (book?._id && book._id !== '') {
                saves.push(this.booklogService.update(book));
            } else {
                saves.push(this.booklogService.save(book));
            }
        });
        const observable = forkJoin(saves);
        observable.subscribe({
            next: value => saved.push(value),
            complete: () => callback(),
        });
    }
}
