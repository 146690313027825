<ng-container>
    <mat-card appearance='outlined' class='blog-entries-card'>
        <mat-card-header class='card-header sticky' fxLayout='row' fxLayoutAlign='center center'>
            <mat-card-title>Important Announcements</mat-card-title>
        </mat-card-header>
        <div style='display: flex; overflow-x: hidden'>
            <div>
                <mat-card-content>
                    <div *ngIf='results$ | async as blogs'>
                        <div *ngFor='let blog of blogs'>
                            <div *ngIf='blog.pinned' class='blog-container'>
                                <div fxLayout='row' fxLayoutAlign='space-between center'>
                                    <span>{{ blog.createdBy.firstName }} {{ blog.createdBy.lastName }} says...</span>
                                    <span>{{ blog.createdDate | timeFrom }}</span>
                                </div>
                                <div [innerHTML]='blog.title'></div>
                                <app-link-preview [link]='blog.link'></app-link-preview>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-header class='card-header sticky' fxLayout='row' fxLayoutAlign='center center'>
                    <mat-card-title>Trailheads</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf='results$ | async as blogs'>
                        <div *ngFor='let blog of blogs'>
                            <div *ngIf='!blog.pinned' class='blog-container'>
                                <div fxLayout='row' fxLayoutAlign='space-between center'>
                                    <span>{{ blog.createdBy.firstName }} {{ blog.createdBy.lastName }} says...</span>
                                    <span>{{ blog.createdDate | timeFrom }}</span>
                                </div>
                                <div [innerHTML]='blog.title'></div>
                                <app-link-preview [link]='blog.link'></app-link-preview>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </div>
    </mat-card>
</ng-container>
