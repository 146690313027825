<div class='search-results' fxLayout='column' fxLayoutAlign='space-around center'>
    <table (matSortChange)='sortChange($event)' [dataSource]='dataSource' class='mat-elevation-z8' mat-table matSort>
        <!-- Name Column -->
        <ng-container matColumnDef='firstName'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header> First Name</th>
            <td *matCellDef='let element' mat-cell> {{ element.user.firstName }}</td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef='lastName'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Last Name</th>
            <td *matCellDef='let element' mat-cell> {{ element.user.lastName }}</td>
        </ng-container>

        <!-- Display Column -->
        <ng-container matColumnDef='grade'>
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Grade</th>
            <td *matCellDef='let element' mat-cell> {{ element.grade }}</td>
        </ng-container>

        <tr *matHeaderRowDef='displayedColumns; sticky: true' mat-header-row></tr>
        <tr *matRowDef='let row; columns: displayedColumns;' class='assignment-row' mat-row></tr>
    </table>
</div>
