<a (click)='onItemSelected(item)' [authz]='{authz: item.authz}' [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
   [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
   class='menu-list-item'
   mat-list-item>
    <mat-icon class='routeIcon' id='menu-icon'>{{item.iconName}}</mat-icon>
    <span class='menu-item-text' id='menu-text'>{{item.displayName}}</span>
    <span class='twistie-separator' fxflex></span>
    <span *ngIf='item.children && item.children.length'>
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class='menu-twistie'>
            expand_more
        </mat-icon>
    </span>
</a>
<div *ngIf='expanded'>
    <app-menu-list-item *ngFor='let child of item.children' [depth]='depth+1' [item]='child'
                        class='menu-child'></app-menu-list-item>
</div>
