<mat-card appearance='outlined'>
    <mat-card-header>
        <mat-card-title>{{ data.title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p class='dialog-message'>{{ data.message }}</p>
    </mat-card-content>
    <mat-card-actions class='action-buttons'>
        <button (click)='cancel()' color='accent' mat-button mat-raised-button>{{ data.cancelText }}</button>
        <button (click)='confirm()' color='primary' mat-button mat-raised-button>{{ data.confirmText }}</button>
    </mat-card-actions>
</mat-card>
