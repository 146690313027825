import * as i0 from '@angular/core';
import { Injectable, InjectableDecorator } from '@angular/core';

export class UrlService {
    static decorators: { args: { providedIn: string }[]; type: InjectableDecorator }[];
    static ɵprov: unknown;
    shortenUrlIfNecessary(returnUrl) {
        const questionMark = returnUrl.indexOf('?');
        if (questionMark > -1) {
            returnUrl = returnUrl.substring(0, questionMark);
        }
        return returnUrl;
    }
    getQueryParams(returnUrl) {
        const queryParams = {
            queryParams: undefined
        };
        const questionMark = returnUrl.indexOf('?');
        if (questionMark > -1) {
            const paramString = returnUrl.substring(questionMark + 1, returnUrl.length);
            const queryMap = this.getMapFromParamString(paramString);
            queryParams.queryParams = queryMap;
        }
        return queryParams;
    }
    getMapFromParamString(paramString) {
        const pairs = paramString.split('&');
        const paramMap = new Map();
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i];
            const map = pair.split('=');
            const key = map[0];
            const val = map[1];
            paramMap.set(key, val);
        }
        return paramMap;
    }
}

UrlService.ɵprov = i0.ɵɵdefineInjectable({
    factory: function UrlService_Factory() {
        return new UrlService();
    }, token: UrlService, providedIn: 'root'
});
UrlService.decorators = [
    {type: Injectable, args: [{providedIn: 'root'},]}
];
