import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    hide = true;
    form: FormGroup;
    private error: any;
    private isLoggedin: boolean;
    private loading: boolean;
    private returnUrl: string;
    public loginInvalid: boolean;
    private formSubmitAttempt: boolean;
    private socialUser: SocialUser;

    constructor(private authService: AuthService,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private socialAuthService: SocialAuthService) {
        if (this.authService.userValue) {
            this.router.navigate(['/']);
        }
    }

    async ngOnInit() {
        this.socialAuthService.authState.subscribe((user) => {
            this.socialUser = user;
            this.isLoggedin = (user != null);
            if (user) {
                console.log(this.socialUser);
                this.authService.loginAs(user.email)
                    .pipe(first())
                    .subscribe(
                        data => {
                            this.router.navigate([this.returnUrl]);
                        },
                        error => {
                            this.error = error;
                            this.loginInvalid = true;
                            this.loading = false;
                        });
            }
        });

        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        const returnUrl = 'returnUrl';
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams[returnUrl] || '/';
    }

    async onSubmit() {
        this.loginInvalid = false;
        this.formSubmitAttempt = false;

        if (!this.form.valid) {
            this.formSubmitAttempt = true;
        } else {
            this.loading = true;
            const username = this.form.get('username').value;
            const password = this.form.get('password').value;
            this.authService.login(username, password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.router.navigate([this.returnUrl]);
                    },
                    error => {
                        this.error = error;
                        console.log('error', error);
                        this.loginInvalid = true;
                        this.loading = false;
                    });
        }
    }
}
