import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../core/notify.service';
import { User } from '../../_models/user';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    resetPasswordForm: FormGroup;
    hide = true;
    token: string;
    user: User;

    constructor(private formBuilder: FormBuilder,
                private userService: UserService,
                private notify: NotifyService,
                private router: Router,
                private route: ActivatedRoute) {
        const token = this.route.snapshot.paramMap.get('resetToken');
        console.log('token', token);
        this.token = token;
        this.userService.getUserFromToken(token).subscribe(data => {
            this.user = data;
        });
    }

    ngOnInit(): void {
        this.resetPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
        }, {validator: this.passwordMatchValidator});
    }

    passwordMatchValidator(form: FormGroup): { mismatch: boolean } {
        const password = form.get('password').value;
        const confirmPassword = form.get('confirmPassword').value;

        return password === confirmPassword ? null : {mismatch: true};
    }

    onSubmit(): void {
        if (this.resetPasswordForm.valid) {
            this.userService.resetPassword(this.user.email, this.token, this.resetPasswordForm.get('password').value, this.user._id)
                .subscribe(
                    data => {
                        this.hide = true;
                        this.router.navigate(['/login']);
                    },
                    error => {
                        this.hide = false;
                        this.notify.warning('Error sending email', error.error.message);
                    }
                );
        }
    }

    onHidePassword() {
        this.hide = !this.hide;
    }
}
