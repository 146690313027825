<mat-card appearance='outlined'>
    <section *ngFor='let attachment of filteredAttachments' style='margin: 20px'>
        <div style='width: 100%'>
            <span>ID: {{ attachment._id }}&nbsp;</span>
            <button (click)='delete(attachment)' class='mat-primary' mat-raised-button style='float: right'>Delete
            </button>
        </div>
        <div>
            <span *ngIf='attachment?.filename'>Filename: {{ attachment.filename }}</span>
            <span *ngIf='!attachment?.filename'>Body: </span>
            <div *ngIf='!attachment?.filename'>{{ attachment.text }}</div>
        </div>
    </section>
</mat-card>
