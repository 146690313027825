import { Component } from '@angular/core';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent {
    showChatList = false;
    showUsersList = false;
    showRoomsList = true;
    showContactsList = false;

    showUsers() {
        this.showChatList = false;
        this.showUsersList = true;
        this.showRoomsList = false;
        this.showContactsList = false;
    }

    showChats() {
        this.showChatList = true;
        this.showUsersList = false;
        this.showRoomsList = false;
        this.showContactsList = false;
    }

    showRooms() {
        this.showChatList = false;
        this.showUsersList = false;
        this.showRoomsList = true;
        this.showContactsList = false;
    }
    showContacts() {
        this.showChatList = false;
        this.showUsersList = false;
        this.showRoomsList = false;
        this.showContactsList = true;
    }
}
