<div (fileDropped)='onFileDropped($event)' appDnd class='container'>
    <input #fileDropRef (change)='onChangeLocal($event)' id='fileDropRef' multiple type='file'/>
    <svg height='64' viewBox='0 0 63 64' width='63' xmlns='http://www.w3.org/2000/svg'>
        <g fill='#3B454F' fill-rule='nonzero'>
            <path
                d='M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z'/>
            <path
                d='M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z'/>
            <path
                d='M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z'/>
        </g>
    </svg>

    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <label for='fileDropRef'>Browse for file</label>
</div>
<div class='files-list'>
    <div *ngFor='let file of files; let i = index' class='single-file'>
        <div class='file-icon' style='width: 50px'>
            <svg id='Capa_1' style='enable-background:new 0 0 58 58;' version='1.1'
                viewBox='0 0 58 58' x='0px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' y='0px'>
				<polygon points='51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 ' style='fill:#EDEADA;'/>
                <g>
					<path d='M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z'
                        style='fill:#CEC9AE;'/>
                    <path d='M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z'
                        style='fill:#CEC9AE;'/>
                    <path d='M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z'
                        style='fill:#CEC9AE;'/>
                    <path d='M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z'
                        style='fill:#CEC9AE;'/>
                    <path d='M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z'
                        style='fill:#CEC9AE;'/>
				</g>
                <polygon points='37.5,0 37.5,14 51.5,14 ' style='fill:#CEC9AE;'/>
			</svg>
        </div>
        <div class='info'>
            <h4 class='name'>
                {{ file?.name }}
            </h4>
            <p class='size'>
                {{ formatBytes(file?.size, 2) }}
            </p>
            <app-progress [progress]='file?.progress'></app-progress>
        </div>

        <div (click)='deleteFile(i)' class='delete'>
            <svg height='18' viewBox='0 0 14 18' width='14' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z' fill='#B1B1B1'
                    fill-rule='nonzero'/>
            </svg>
        </div>
    </div>
</div>
