import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotifyService {
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    private notifications: MatSnackBarRef<any>[];
    constructor(private snackBar: MatSnackBar) {
        this.notifications = [];
    }

    addNotification(title: string, message: string, type: string, durationInSeconds: number) {
        this.notifications.push(this.snackBar.open(message, title, {
            duration: +durationInSeconds * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        }));
    }

    info(title, message, duration?): void {
        this.addNotification(title, message, 'info', (duration === undefined) ? 5 : +duration);
    }

    success(title, message, duration?): void {
        this.addNotification(title, message, 'success', (duration === undefined) ? 5 : +duration);
    }

    warning(title, message, duration?): void {
        this.addNotification(title, message, 'warning', (duration === undefined) ? 5 : +duration);
    }

    danger(title, message, duration?): void {
        this.addNotification(title, message, 'danger', (duration === undefined) ? 5 : +duration);
    }

    clearAll(): void {
        this.notifications.forEach(o => {
            o.dismiss();
        });
    }
}
