import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
    selector: 'app-drag-drop-file-upload',
    templateUrl: './drag-drop-file-upload.component.html',
    styleUrls: ['./drag-drop-file-upload.component.scss']
})
export class DragDropFileUploadComponent {
    @Output() saveFile = new EventEmitter<File>();
    error: string;
    dragAreaClass: string;
    onFileChange(event: any) {
        const files: FileList = event.target.files;
        this.saveFiles(files);
    }
    ngOnInit() {
        this.dragAreaClass = 'dragarea';
    }
    @HostListener('dragover', ['$event']) onDragOver(event: any) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }
    @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }
    @HostListener('dragend', ['$event']) onDragEnd(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }
    @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }
    @HostListener('drop', ['$event']) onDrop(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            const files: FileList = event.dataTransfer.files;
            this.saveFiles(files);
        }
    }

    saveFiles(files: FileList) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < files.length; i++) {
            this.saveFile.emit(files[i]);
        }
    }
}
