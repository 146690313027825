import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ChartDataSingle } from '../_models/chart';

@Injectable({
    providedIn: 'root'
})
export class ChartService {
    constructor(private http: HttpClient) {
    }

    getAssignmentCompletionChart(): Observable<ChartDataSingle[]> {
        return this.http.get<ChartDataSingle[]>(`${environment.apiUrl}/api/chart/assignment/completion`).pipe(
            map(res => res.map(it => new ChartDataSingle(it))),
        );
    }
}
