import { Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOptionSelectionChange } from '@angular/material/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Assignment } from '../../_models/assignment';
import { Attachment } from '../../_models/attachment';
import { User } from '../../_models/user';
import { AuthService } from '../../auth.service';
import { NotifyService } from '../../core/notify.service';
import { Utils } from '../../core/utils';
import { AssignmentService } from '../../services/assignment.service';
import { AttachmentService } from '../../services/attachment.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-assignment-group-edit',
    templateUrl: './assignment-group-edit.component.html',
    styleUrls: ['./assignment-group-edit.component.scss']
})
export class AssignmentGroupEditComponent {
    @ViewChild('teacherInput') teacherInput: ElementRef;

    private readOnly = false;
    assignments$: Observable<Assignment[][]>;
    groupEditForm = this.fb.group({
        name: [null, Validators.required, {disabled: this.readOnly}],
        description: [null, {disabled: this.readOnly}],
        dueDate: [null, Validators.required, {disabled: this.readOnly}],
        attachments: [null],
        notes: [null],
        teachers: [null],
        isGroupAssignment: [null]
    });
    assignment$ = new BehaviorSubject<Assignment>(null);
    allStudents$: Observable<User[]>;
    teachers$: Observable<User[]>;

    allTeachers: User[] = [];
    removable = true;
    students: User[];
    selectedUsers = [];
    teachers: User[] = [];
    teachersCtrl = new FormControl();
    selectable = true;
    addOnBlur: any;
    separatorKeysCodes: any;
    filteredTeachers: Observable<User[]>;
    isGroupAssignment = false;
    instructionsBody: any;
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '10rem',
        minHeight: '5rem',
        placeholder: 'Enter text in this rich text editor....',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        customClasses: [
            {
                name: 'Quote',
                class: 'quoteClass',
            },
            {
                name: 'Title Heading',
                class: 'titleHead',
                tag: 'h1',
            },
        ],
    };
    fileAttachements = new Array<Attachment>();
    showAddAttachments = false;
    assignment: any;
    isLoading$ = new BehaviorSubject<boolean>(false);
    isReadOnly$ = new BehaviorSubject<boolean>(false);
    isSaving$ = new BehaviorSubject<boolean>(false);
    private initialized = false;
    duplicateList = {} as any;
    selectedAssignments: Assignment[] = [];
    private files: any;
    private savedAttachments: any[];
    private savedAssignments: any[];
    private assignments: any[];

    compare(c1: { _id: string }, c2: { _id: string }) {
        return c1 && c2 && c1._id === c2._id;
    }

    get name(): AbstractControl {
        return this.groupEditForm.get('name');
    }

    get description(): AbstractControl {
        return this.groupEditForm.get('description');
    }

    get dueDate(): AbstractControl {
        return this.groupEditForm.get('dueDate');
    }

    constructor(private authService: AuthService,
                private fb: FormBuilder,
                private notifyService: NotifyService,
                private userService: UserService,
                private attachmentService: AttachmentService,
                private assignmentService: AssignmentService) {
        this.assignmentService.getAssignments().subscribe(resp => {
            this.duplicateList = {};
            this.duplicateList = this.groupByTime(resp);
            const tempList = [];
            const tempKeys = Object.keys(this.duplicateList);
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < tempKeys.length; i++) {
                tempList.push(this.duplicateList[tempKeys[i]]);
            }
            // console.log(tempList);
            this.assignments$ = of(tempList) as any;
            this.assignments = tempList;
        });

        this.allStudents$ = this.userService.getAllStudents().pipe(
            tap(res => {
                this.students = res.map(i => new User(i));
            })
        );

        this.teachers$ = this.userService.getAllTeachers().pipe(
            tap((users: User[]) => {
                this.allTeachers = users.map(it => new User(it));
                this.filteredTeachers = this.teachersCtrl.valueChanges.pipe(
                    startWith(null),
                    map((teachers: string | null) => teachers ? this.filterTeacher(teachers) : this.allTeachers.slice()));
            })
        );
    }

    groupByTime(rows: Assignment[]): any {
        let currentIndex = 0;
        let current: Assignment = null;
        const groups: any = {};

        let index = 0;
        while (rows.length > 0 && index < rows.length && currentIndex < rows.length) {
            if (current) {
                // console.log(new Date(current.createdAt));
                // console.log(new Date(rows[index].createdAt));
                // console.log(this.secondsDiff(new Date(current.createdAt), new Date(rows[index].createdAt)));
                if (Utils.isDifferent(current, rows[index])) {
                    if (this.secondsDiff(new Date(current.createdAt), new Date(rows[index].createdAt)) < 100) {
                        if (Object.keys(groups).indexOf(current.name) > -1) {
                            groups[current.name].push(rows[index]);
                            rows.splice(index, 1);
                        } else {
                            groups[current.name] = [...[current], rows[index]];
                            rows.splice(index, 1);
                            rows.splice(currentIndex, 1);
                        }
                    }
                }
            } else {
                currentIndex = index;
                current = rows[index];
            }
            if (index + 1 < rows.length) {
                index++;
            } else {
                index = 0;
                currentIndex++;
                current = rows[currentIndex];
            }
        }
        // console.log(groups);
        return groups;
    }

    secondsDiff(dt1: Date, dt2: Date) {
        const diffSeconds = (dt2.getTime() - dt1.getTime()) / (100 * 60);
        // console.log(diffSeconds);
        return Math.abs(Math.round(diffSeconds));
    }

    selectAssignment() {
        // console.log('something');
    }

    addTeacher(event: MatChipInputEvent): void {
        // console.log(event);
    }

    removeTeacher(teacher: any): void {
        const index = this.teachers.indexOf(teacher);

        if (index >= 0) {
            this.teachers.splice(index, 1);
        }
    }

    filterTeacher(name: string) {
        return this.allTeachers.filter(teacher =>
            teacher.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selectedTeacher(event: MatAutocompleteSelectedEvent): void {
        this.teachers.push(event.option.value);
        this.teacherInput.nativeElement.value = '';
        this.teachersCtrl.setValue(null);
    }

    addFilesToAssignment($event: any) {
        if (this.files) {
            this.files.push(new Attachment($event));
        } else {
            this.files = [];
            this.files.push(new Attachment($event));
        }
        this.update();
        // console.log($event);
    }

    update() {
        this.mergeChangesFromForm();
        this.actualUpdate();
    }

    mergeChangesFromForm() {
        if (this.files) {
            this.files.forEach((file) => {
                this.selectedAssignments.forEach((assign) => {
                    assign.attachments.push(new Attachment(file));
                });
            });
        }
        this.selectedAssignments.forEach((assign) => {
            assign.name = this.name.value;
            assign.description = this.instructionsBody;
            assign.dueDate = this.dueDate.value;
        });
    }

    actualUpdate() {
        this.forkJoinSaveAttachments(() => {
            // console.log(this.savedAttachments);
            this.selectedAssignments.forEach((assign) => {
                assign.attachments = this.savedAttachments[0];
            });
            this.forkJoinSaveAssignments(() => {
                this.notifyService.success(`${this.savedAssignments?.length} Assignments`, 'Saved successfully');
                window.location.reload();
            });
        });
    }

    forkJoinSaveAssignments(callback: any) {
        this.savedAssignments = [];
        const saves = new Array<Observable<any>>();
        this.selectedAssignments.forEach(assign => {
            if (assign?._id && assign._id !== '') {
                saves.push(this.assignmentService.update(assign));
            } else {
                saves.push(this.assignmentService.save(assign));
            }
        });
        const observable = forkJoin(saves);
        observable.subscribe({
            next: value => this.savedAssignments.push(value),
            complete: () => callback(),
        });
    }

    forkJoinSaveAttachments(callback: any) {
        this.savedAttachments = [];
        const saves = new Array<Observable<any>>();
        this.assignment?.attachments.forEach(att => {
            if (att?._id && att._id !== '') {
                saves.push(this.attachmentService.update({attachment: new Attachment(att)}));
            } else {
                saves.push(this.attachmentService.save({attachment: new Attachment(att)}));
            }
        });
        const observable = forkJoin(saves);
        observable.subscribe({
            next: value => this.savedAttachments.push(value),
            complete: () => callback(),
        });
    }

    init(force: boolean = false) {
        this.isLoading$.next(true);
        this.assignment = new Assignment(this.assignment);
        // console.log('Assignment selected:', this.assignment);
        if (!this.initialized || force) {
            if (!this.assignment) {
                this.assignment = new Assignment();
            }
            if (this.assignment?.user?.length > 0) {
                Object.assign(this.selectedUsers, this.assignment.user);
                // console.log(this.selectedUsers);
                if (this.assignment?.user?.length > 1) {
                    this.isGroupAssignment = true;
                }
            }
            this.teachers = [];
            if (this.assignment?.teachers) {
                this.teachers = this.assignment.teachers.map(p => new User(p));
            }
            this.fileAttachements = [];
            let attachments = this.fb.array([this.fb.control('')]);
            if (this.assignment?.attachments && this.assignment?.attachments?.length > 0) {
                attachments = this.fb.array<string>([]);
                this.assignment.attachments.forEach(at => {
                    if (at?.filename && at.filename !== '') {
                        this.fileAttachements.push(at);
                    } else {
                        attachments.push(this.fb.control(''));
                    }
                });
                if (attachments.length <= 0) {
                    attachments = this.fb.array([this.fb.control('')]);
                    this.assignment.attachments.push(new Attachment());
                }
            } else {
                this.assignment.attachments = [];
                this.assignment.attachments.push(new Attachment());
            }
            this.isReadOnly$.next(!this.authService.isAdmin);
            this.groupEditForm.reset({
                name: null,
                description: null,
                dueDate: null,
                attachments: null,
                notes: null,
                teachers: null,
                isGroupAssignment: null
            });
            this.groupEditForm = this.fb.group({
                name: new FormControl({value: this.assignment.name, disabled: this.isReadOnly$.getValue()}),
                description: new FormControl({value: this.assignment.description, disabled: this.isReadOnly$.getValue()}),
                dueDate: new FormControl({value: this.assignment.dueDate, disabled: this.isReadOnly$.getValue()}),
                teachers: this.teachers
            }) as any;
            this.instructionsBody = this.assignment?.description;
        }
        this.isLoading$.next(false);
    }

    assignmentChange($event: any) {
        if ($event.isUserInput) {
            this.selectedAssignments = $event.source.value;
            this.assignment = this.selectedAssignments[0];
            this.init(true);
            this.assignment$.next(this.assignment);
        }
    }
}
