import { Component } from '@angular/core';
import { ImageService } from '../../services/image.service';

@Component({
    selector: 'app-photo-upload',
    templateUrl: './photo-upload.component.html',
    styleUrls: ['./photo-upload.component.scss']
})
export class PhotoUploadComponent {
    imageObj: File;
    imageUrl: string;
    albumSelected: any;
    albums: any;
    shouldRefresh: boolean;

    constructor(private imageService: ImageService,
                private imageUploadService: ImageService) {
        this.albumSelected = 'select';
        this.imageService.getAlbums().subscribe(albums => {
            this.albums = albums;
        });
    }

    onImagePicked(event: Event): void {
        const FILE = (event.target as HTMLInputElement).files[0];
        this.imageObj = FILE;
        console.log(this.albumSelected);
    }

    onImageUpload() {
        const imageForm = new FormData();
        imageForm.append('image', this.imageObj);
        this.imageUploadService.photoUpload(this.albumSelected.replace('/', ''), imageForm).subscribe((res: any) => {
            this.imageUrl = res.image;
        });
    }

    save(file: File) {
        this.imageObj = file;
        const imageForm = new FormData();
        imageForm.append('image', this.imageObj);
        this.imageUploadService.photoUpload(this.albumSelected.replace('/', ''), imageForm).subscribe((res: any) => {
            console.log(res);
            this.shouldRefresh = !this.shouldRefresh;
        });
    }
}
