import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Booklog } from '../_models/booklog';
import { AuthService } from '../auth.service';
import { Utils } from '../core/utils';

@Injectable({
    providedIn: 'root'
})
export class BooklogService {

    constructor(private authService: AuthService,
                private http: HttpClient) {
    }

    getAll() {
        return this.http.get<Booklog[]>(`${environment.apiUrl}/api/booklog`).pipe(
            map(res => res?.map(it => new Booklog(it))),
        );
    }

    getAllByUser(id) {
        return this.http.get<Booklog[]>(`${environment.apiUrl}/api/booklog/user/${id}`).pipe(
            map(res => res?.map(it => new Booklog(it))),
        );
    }

    save(booklog: any) {
        const tempBooklog = {
            author: booklog.author,
            createdBy: this.authService.userValue.id,
            modifiedBy: this.authService.userValue.id,
            readDate: new Date(booklog.readDate)?.getTime() ? new Date(booklog.readDate).getTime() : Date.now(),
            title: booklog.title,
            user: Utils.isEmpty(booklog.user) ? this.authService.userValue.id : booklog.user
        };
        return this.http.post<any>(`${environment.apiUrl}/api/booklog`, {Booklog: tempBooklog}).pipe(
            map(res => new Booklog(res))
        );
    }

    update(booklog: any) {
        if (Utils.isEmpty(booklog)) {
            return of();
        }
        if (Utils.isEmpty(booklog._id)) {
            return this.save(booklog);
        } else {
            const tempBooklog = {
                _id: booklog._id,
                modifiedBy: this.authService.userValue.id,
                modifiedDate: Date.now(),
                title: booklog.title,
                user: booklog.user
            };
            return this.http.put<any>(`${environment.apiUrl}/api/booklog`, {Booklog: tempBooklog}).pipe(
                map(res => new Booklog(res))
            );
        }
    }

    delete(booklog: any): Observable<Booklog> {
        return this.http.delete<any>(`${environment.apiUrl}/api/booklog/${booklog._id}`).pipe(
            map(res => new Booklog(res))
        );
    }
}
