import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Assignment } from '../_models/assignment';

@Injectable({
    providedIn: 'root'
})
export class AssignmentService {

    constructor(private http: HttpClient) {
    }

    getAssignment(id: any): Observable<Assignment> {
        return this.http.get<any>(`${environment.apiUrl}/api/assignment/${id}`).pipe(
            map(res => new Assignment(res))
        );
    }

    getAssignments(): Observable<Assignment[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/assignments`).pipe(
            map(res => res.map(it => new Assignment(it)))
        );
    }

    getAssignmentsByUser(userId: any): Observable<Assignment[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/assignment/user/${userId}`).pipe(
            map(res => res.map(it => new Assignment(it)))
        );
    }

    getAssignmentsByParent(userId: any, request): Observable<Assignment[]> {
        return this.http.post<any>(`${environment.apiUrl}/api/assignment/parent/${userId}`, request).pipe(
            map(res => res.map(it => new Assignment(it)))
        );
    }

    createGoogleDoc(title: string, email: string, isSheet = false): Observable<string> {
        return this.http.post<string>(`${environment.apiUrl}/api/createNewDocument`, {title, email, isSheet});
    }

    createGoogleSheet(title: string, email: string, isSheet = true): Observable<string> {
        return this.http.post<string>(`${environment.apiUrl}/api/createNewDocument`, {title, email, isSheet});
    }

    save(assignment: any): Observable<Assignment> {
        const t = Object.assign({}, assignment);
        if (assignment?.attachments?.length > 0) {
            t.attachments = assignment?.attachments.map(r => r._id);
        }
        if (assignment?.notes?.length > 0) {
            t.notes = assignment?.notes.map(p => p._id);
        }
        if (assignment?.teachers?.length > 0) {
            t.teachers = assignment?.teachers.map(p => p._id);
        }
        if (assignment?.user?.length > 0) {
            Object.assign(t.user, assignment?.user);
        } else {
            if (assignment?.user) {
                t.user = assignment?.user;
            }
        }
        if (assignment?.googleDocId) {
            t.googleDocId = assignment.googleDocId;
        }
        if (assignment?.isSheet) {
            t.isSheet = assignment.isSheet;
        }
        return this.http.post<Assignment>(`${environment.apiUrl}/api/assignment`, {assignment: t}).pipe(
            map(res => new Assignment(res))
        );
    }

    update(assignment: any): Observable<Assignment> {
        const t = Object.assign({}, assignment);
        if (assignment?.attachments?.length > 0) {
            t.attachments = assignment?.attachments.map(r => r._id);
        }
        if (assignment?.notes?.length > 0) {
            t.notes = assignment?.notes.map(p => p._id);
        }
        if (assignment?.user?._id) {
            t.user = assignment?.user?._id;
        }
        if (assignment?.googleDocId) {
            t.googleDocId = assignment.googleDocId;
        }
        if (assignment?.isSheet) {
            t.isSheet = assignment.isSheet;
        }
        return this.http.put<Assignment>(`${environment.apiUrl}/api/assignment`, {assignment: t}).pipe(
            map(res => new Assignment(res))
        );
    }

    delete(assignment: Assignment) {
        return this.http.delete<any>(`${environment.apiUrl}/api/assignment/${assignment._id}`).pipe(
            map(res => new Assignment(res))
        );
    }

    getAssignmentsByQueryString(s: string): Observable<Assignment[]> {
        return this.http.get<any>(`${environment.apiUrl}/api/assignments?${s}`).pipe(
            map(res => res.map(it => new Assignment(it)))
        );
    }

    markCompletedBooklet(assignment, complete = true) {
        assignment.bookletComplete = complete;
        return this.http.put<Assignment>(`${environment.apiUrl}/api/assignment`, {assignment}).pipe(
            map(res => new Assignment(res))
        );
    }
}
