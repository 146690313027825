<div class='chat-main-container'>
    <div class='left-nav'>
<!--        <div (click)="showUsers()" class="menu-item" [ngClass]="{'selected': showUsersList}">-->
<!--          <i class='fas fa-user-friends'></i>-->
<!--          <span>Users</span>-->
<!--        </div>-->
        <!--    <div (click)="showChats()" class="menu-item" [ngClass]="{'selected': showChatList}">-->
        <!--      <i class='fas fa-comments'></i>-->
        <!--      <span>&nbsp;Chat&nbsp;</span>-->
        <!--    </div>-->
        <div (click)='showContacts()' [ngClass]="{'selected': showContactsList}" class='menu-item'>
            <i class='fas fa-comments'></i>
            <span>&nbsp;Contacts&nbsp;</span>
        </div>
        <div (click)='showRooms()' [ngClass]="{'selected': showRoomsList}" class='menu-item'>
            <i class='fas fa-door-open'></i>
            <span>Rooms</span>
        </div>
    </div>
    <div class='chat-main-content'>
        <div class='list'>
            <app-chat-list *ngIf='showChatList'></app-chat-list>
            <app-chat-users *ngIf='showUsersList'></app-chat-users>
            <app-chat-room *ngIf='showRoomsList'></app-chat-room>
            <app-chat-contact *ngIf='showContactsList'></app-chat-contact>
        </div>
        <app-chat-direct style='width: 100%;'></app-chat-direct>
    </div>
</div>
