<div class='chat-contacts-list-container'>
    <div class='contacts-search-container'>
        <form [formGroup]='searchForm' class='contacts-search-form'>
            <mat-icon style='float: left'>search</mat-icon>
            <input
                data-lpignore='true'
                formControlName='search'
                placeholder='Search'
                type='search'>
        </form>
    </div>
    <div *ngIf='(contacts$ | async)' class='contacts-list'>
        <div *ngFor='let contact of contacts'>
            <div (click)='setActiveContact(contact)' [ngClass]="{'active': activeContact?._id === contact._id}"
                class='contact-card'>
                <span *ngIf='contact._id'>{{ contact.firstName }} {{ contact.lastName }}</span>
            </div>
        </div>
    </div>
</div>
