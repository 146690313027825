import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { GridFilterItem, GridHeading, GridResponse } from '@troyaaronjones/angular_material_data_grid';
import { BehaviorSubject, forkJoin, merge, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, first, startWith, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { User } from '../../../_models/user';
import { AuthService } from '../../../auth.service';
import { NotifyService } from '../../../core/notify.service';
import { StorageService } from '../../../core/storage.service';
import { ConfirmDialogService } from '../../../services/confirm-dialog.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent {
    @Input() users: Array<User>;
    @Output() deleteUser = new EventEmitter<User>();
    @Output() selectedUser = new EventEmitter<User>();

    dataSource: MatTableDataSource<User>;
    displayedColumns: string[] = ['userName', 'firstName', 'lastName', 'email', 'delete', 'login'];
    @ViewChild(MatSort) sort: MatSort;
    isLoading$ = new BehaviorSubject<boolean>(false);
    results$: Observable<User[]>;
    refresh$ = new Subject<boolean>();
    headings: GridHeading[] = [
        {fieldName: 'userName', display: 'Username', type: 'string', width: '85px', sort: 'asc'},
        {fieldName: 'firstName', display: 'First Name', type: 'string', width: '120px'},
        {fieldName: 'lastName', display: 'Last Name', type: 'string', width: '120px'},
        {fieldName: 'email', display: 'Email', type: 'string', width: '120px'},
    ];
    entity: any = null;
    resetFilters: any = null;
    initialFilters: GridFilterItem[] = [];
    initialSort = {sort: 'asc', sortField: 'lastName'} as any;
    url = `${environment.apiUrl}/api/users`;
    selectedRows: any[];
    private deleting: boolean;
    private localUsers: User[];
    private deletedUsers: any[];

    constructor(private authService: AuthService,
                private dialogService: ConfirmDialogService,
                private notifyService: NotifyService,
                private userService: UserService,
                private route: ActivatedRoute,
                private router: Router,
                private storageService: StorageService) {
        this.results$ = merge(
            this.getUsers(),
            this.refresh$.pipe(switchMap(() => this.getUsers()))
        );
    }

    getUsers(): Observable<User[]> {
        return of(null).pipe(
            tap(() => {
                this.isLoading$.next(true);
            }),
            switchMap(() =>
                this.userService.allUsers().pipe(startWith(undefined as User[]))
            ),
            tap((users: User[]) => {
                if (users) {
                    this.localUsers = users;
                    this.dataSource = new MatTableDataSource<User>(this.localUsers);
                    this.isLoading$.next(false);
                }
            }),
            catchError((error: any) => {
                this.isLoading$.next(false);
                return of(null);
            })
        );
    }

    responseReceived(response: GridResponse): void {
        console.log('this is recieved');
        console.log(response); // If necessary manipulate the data or use data in the parent component
    }

    toCamelCase(str) {
        const words = str.split(' ');

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(' ');
    }

    refreshChanges() {
        this.refresh$.next(true);
    }

    edit(row) {
        if (!this.deleting) {
            this.selectedUser.emit(row.item);
            this.router.navigate(['edit', row.item._id], {relativeTo: this.route});
        }
        this.deleting = false;
    }

    loginAs() {
        if (this.selectedRows?.length > 0) {
            this.authService.loginAs(this.selectedRows[0].email)
                .pipe(first())
                .subscribe(
                    data => {
                        this.router.navigate(['/']);
                    },
                    error => {

                    });
        }
    }

    requestBodyChanged($event: any) {
        this.storageService.save('userGridFilterAndSort', JSON.stringify($event));
        console.log($event);
    }

    onSelectionChange(selectedRows) {
        this.deleting = true;
        setTimeout(() => {
            this.deleting = false;
        }, 1000);
        this.selectedRows = selectedRows;
    }

    onDelete() {
        if (this.selectedRows) {
            this.deleting = true;
            const options = {
                title: 'Delete Users? ',
                message: 'By deleting you will permanently lose ' + this.selectedRows.length + ' user/s',
                cancelText: 'CANCEL',
                confirmText: 'YES, DELETE'
            };

            this.dialogService.open(options);

            this.dialogService.confirmed().subscribe(confirmed => {
                if (confirmed) {
                    console.log('Delete;', this.selectedRows);
                    this.deleteAssignments(this.selectedRows, () => {
                        this.deleting = false;
                        window.location.reload();
                    });
                } else {
                    this.deleting = false;
                }
            });
        }
    }

    deleteAssignments(selected, callback) {
        this.deletedUsers = [];
        const deletes = new Array<Observable<any>>();
        this.selectedRows.forEach((a) => {
            deletes.push(this.userService.delete(a));
        });
        const observable = forkJoin(deletes);
        observable.subscribe({
            next: value => this.deletedUsers.push(value),
            complete: () => callback(),
        });
    }
}
