import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private http: HttpClient) {
    }

    upload(file: any): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        file.inProgress = true;
        console.log(formData);
        return this.http.post<any>(`${environment.apiUrl}/api/file`, formData, {
            responseType: 'json',
            reportProgress: true,
            observe: 'events'
        });
    }
}
