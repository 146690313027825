<mat-card appearance='outlined' class='classroom'>
    <mat-card-content>
        <form [formGroup]='classroomForm' class='classroom-form'>
            <div style='margin: 20px;'>
                <div>
                    <mat-form-field floatLabel='true'
                        hideRequiredMarker='false'>
                        <input formControlName='name' matInput placeholder=''>
                        <mat-label>Classroom Name</mat-label>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field floatLabel='true'
                        hideRequiredMarker='false'>
                        <input formControlName='description' matInput placeholder=''>
                        <mat-label>Classroom Description</mat-label>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance='fill' class='teachers-chip-list'>
                        <mat-label>Teachers</mat-label>
                        <mat-chip-grid #chipListTeachers aria-label='Teachers'>
                            <mat-chip-row (removed)='removeTeacher(teacher)' *ngFor='let teacher of teachers'>
                                {{ teacher.firstName }} {{ teacher.lastName }}
                                <mat-icon *ngIf='removable' matChipRemove>cancel</mat-icon>
                            </mat-chip-row>
                            <input #teacherInput
                                (matChipInputTokenEnd)='addTeacher($event)'
                                [matAutocomplete]='teacher'
                                [matChipInputFor]='chipListTeachers'
                                [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
                                placeholder='Add teacher..'>
                        </mat-chip-grid>
                        <mat-autocomplete #teacher='matAutocomplete' (optionSelected)='selectedTeacher($event)'>
                            <mat-option *ngFor='let user of filteredTeachers | async' [value]='user'>
                                {{ user.firstName }} {{ user.lastName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance='fill' class='students-chip-list'>
                        <mat-label>Students</mat-label>
                        <mat-chip-grid #chipListStudents aria-label='Students'>
                            <mat-chip-row (removed)='removeStudent(student)' *ngFor='let student of students'>
                                {{ student.firstName }} {{ student.lastName }}
                                <mat-icon *ngIf='removable' matChipRemove>cancel</mat-icon>
                            </mat-chip-row>
                            <input #studentInput
                                (matChipInputTokenEnd)='addStudent($event)'
                                [matAutocomplete]='student'
                                [matChipInputAddOnBlur]='addOnBlur'
                                [matChipInputFor]='chipListStudents'
                                [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
                                placeholder='Add student..'>
                        </mat-chip-grid>
                        <mat-autocomplete #student='matAutocomplete' (optionSelected)='selectedStudent($event)'>
                            <mat-option *ngFor='let user of filteredStudents | async' [value]='user'>
                                {{ user.firstName }} {{ user.lastName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div style='text-align: center; margin: 20px;'>
                <button (click)='create()' *ngIf='!classroom?._id' color='primary' mat-raised-button
                    style='margin-right: inherit;'>Save
                </button>
                <button (click)='update()' *ngIf='classroom?._id' color='primary' mat-raised-button
                    style='margin-right: inherit;'>Save
                </button>
                <button (click)='copy()' *ngIf='classroom?._id' authz='Admin#R' color='primary'
                    mat-raised-button style='margin-right: inherit;'>Copy
                </button>
            </div>
        </form>
        <mat-card *ngIf='loading' appearance='outlined'>
            <mat-card-content>
                <mat-progress-bar mode='indeterminate'></mat-progress-bar>
            </mat-card-content>
        </mat-card>
    </mat-card-content>
</mat-card>
