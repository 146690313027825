import { Attachment } from './attachment';
import { User } from './user';

export class Blog {
    // tslint:disable-next-line:variable-name
    _id: string;
    active: boolean;
    attachments: Attachment[];
    body: string;
    createdBy: User;
    createdDate: Date;
    link: string;
    modifiedBy: User;
    modifiedDate: Date;
    pinned: boolean;
    pinnedOn: Date;
    title: string;

    constructor(o?: Blog) {
        Object.assign(this, o);
    }
}
