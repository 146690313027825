import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

export interface IlinkPreview {
    description: string;
    image: string;
    title: string;
    url: string;
}

@Component({
    selector: 'app-link-preview',
    templateUrl: './link-preview.component.html',
    styleUrls: ['./link-preview.component.scss']
})
export class LinkPreviewComponent implements OnInit {
    @Input() link: string;
    preview: IlinkPreview;

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        if (this.link) {
            this.onPreview();
        }
    }

    getLinkPreview(link: string): Observable<any> {
        const api = 'https://api.linkpreview.net/?key=b6ccc00017564e9c8362c54b1514c764&q=' + link;
        return this.http.get(api);
    }

    onPreview() {
        this.getLinkPreview(this.link).subscribe((preview: IlinkPreview) => {
            this.preview = preview;

            if (!this.preview.title) {
                this.preview.title = this.preview.url;
            }
        });
    }
}
