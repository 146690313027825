import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-photo-dialog',
    templateUrl: './photo-dialog.component.html',
    styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent {
    url: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<PhotoDialogComponent>) {
        this.url = data.url;
    }
}
