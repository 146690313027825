import { Component, OnInit } from '@angular/core';
import { Assignment } from '../../../_models/assignment';
import { Attachment } from '../../../_models/attachment';
import { AssignmentService } from '../../../services/assignment.service';
import { AttachmentService } from '../../../services/attachment.service';
import { ConfirmDialogService } from '../../../services/confirm-dialog.service';

@Component({
    selector: 'app-attachment-manager',
    templateUrl: './attachment-manager.component.html',
    styleUrls: ['./attachment-manager.component.scss']
})
export class AttachmentManagerComponent implements OnInit {
    attachments = new Array<Attachment>();
    filteredAttachments = new Array<Attachment>();
    assignments = new Array<Assignment>();
    deleting = false;

    constructor(private dialogService: ConfirmDialogService,
                private attachmentService: AttachmentService,
                private assignmentService: AssignmentService) {
    }

    ngOnInit(): void {
        this.reset();
    }

    reset(): void {
        this.attachments = [];
        this.attachmentService.getAttachments().subscribe(resp => {
            this.attachments = resp;
            this.assignmentService.getAssignments().subscribe(list => {
                this.assignments = list.map(item => item);
                this.findNotAttached();
            }, error => {
            }, () => {
            });
        });
    }

    private findNotAttached() {
        let found = false;
        this.attachments.forEach(at => {
            found = false;
            this.assignments.forEach(as => {
                if (as.attachments) {
                    if (as.attachments?.length > 0) {
                        if (as.attachments[0]?._id === at?._id) {
                            found = true;
                        }
                    }
                }
            });
            if (!found) {
                this.filteredAttachments.push(at);
            }
        });
    }

    delete(attachment: Attachment) {
        this.deleting = true;
        const options = {
            title: 'Delete attachment? ',
            message: 'By deleting you will permanently lose this attachment.',
            cancelText: 'CANCEL',
            confirmText: 'YES, DELETE'
        };

        this.dialogService.open(options);

        this.dialogService.confirmed().subscribe(confirmed => {
            if (confirmed) {
                console.log('Delete;', attachment);
                this.attachmentService.delete(attachment).subscribe(resp => {
                    console.log(resp);
                    this.reset();
                }, error => {
                }, () => {
                    this.deleting = false;
                });
            } else {
                this.deleting = false;
            }
        });
    }
}
