import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Role } from '../_models/role';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(private http: HttpClient) {
    }

    allRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(`${environment.apiUrl}/api/roles`).pipe(
            map(res => res.map(it => new Role(it)))
        );
    }

    getRole(id: any): Observable<Role> {
        return this.http.get<any>(`${environment.apiUrl}/api/role/${id}`).pipe(
            map(res => new Role(res))
        );
    }

    save(role: any): Observable<Role> {
        return this.http.post<Role>(`${environment.apiUrl}/api/role`, role).pipe(
            map(res => new Role(res))
        );
    }

    update(role: any): Observable<Role> {
        return this.http.put<Role>(`${environment.apiUrl}/api/role`, role).pipe(
            map(res => new Role(res))
        );
    }

    delete(role: Role) {
        return this.http.delete<any>(`${environment.apiUrl}/api/role/${role._id}`).pipe(
            map(res => new Role(res))
        );
    }
}
