<div class='assignment'>
    <mat-card>
        <div *ngIf='hasUserDetails'>
            <amdg-grid
                (requestBodyEmit)='requestBodyChanged($event)'
                (responseEmit)='responseReceived($event)'
                (rowClickEmit)='edit($event)'
                [headings]='headings'
                [initialFilters]='initialFilters'
                [initialSort]='initialSort'
                [selection]='true'
                [serverSidePagination]='true'
                [url]='url'>
            </amdg-grid>
        </div>
    </mat-card>
</div>
