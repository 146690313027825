import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Assignment } from '../_models/assignment';
import { Classroom } from '../_models/classroom';
import { User } from '../_models/user';
import { Utils } from '../core/utils';
import { AssignmentService } from '../services/assignment.service';
import { UserService } from '../services/user.service';

const sortingDataAccessor = (item, property) => {
    switch (property) {
        case 'firstName':
            return item.user.firstName;
        case 'lastName':
            return item.user.lastName;
        default:
            return item[property];
    }
};

@Component({
    selector: 'app-grades',
    templateUrl: './grades.component.html',
    styleUrls: ['./grades.component.scss']
})
export class GradesComponent {
    assignments: Assignment[];
    students: User[];
    assignmentsByStudent: any = [];
    hasResults = false;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['firstName', 'lastName', 'grade'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(private userService: UserService,
                private assignmentService: AssignmentService) {
        this.getStudents();
    }

    async getStudents() {
        return this.userService.getAllStudents().subscribe(async (res: User[]) => {
            res.sort(Utils.sortByFirstLastName);
            for (let i = 0; i < res.length; i++) {
                const tmpObj: any = {user: res[i], assignments: [], grade: 0};
                this.assignmentService.getAssignmentsByUser(res[i]._id).subscribe((assignments) => {
                    tmpObj.assignments = assignments;
                    this.calculateGrade(tmpObj);
                    if (i === res.length - 1) {
                        this.init();
                    }
                });
                this.assignmentsByStudent.push(tmpObj);
            }
        });
    }

    calculateGrade(tmpObj: any) {
        tmpObj.assignments.forEach(assignment => {
            tmpObj.grade += assignment.grade;
        });
    }

    init() {
        console.log(this.assignmentsByStudent);
        this.dataSource = new MatTableDataSource<Classroom>(this.assignmentsByStudent);
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.dataSource.sort = this.sort;
        this.hasResults = this.assignmentsByStudent?.length > 0;
    }
    sortChange($event: Sort) {
        // console.log($event);
    }
}
