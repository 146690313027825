import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private authService: AuthService, private route: Router) {
    }

    async canActivate() {
        const temp = await this.authService.checkStatus().toPromise();
        if (!(temp as any).hasValidToken) {
            this.authService.logout();
            return false;
        } else {
            this.authService.IsLoggedIn = true;
        }
        return true;
    }
}
