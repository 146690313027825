import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    @Input() user: any;
    @Input() title: any;
    @Output() logoutMethod = new EventEmitter();

    loggedIn = false;

    constructor() {
    }

    ngOnInit(): void {
        if (this.user?.token) {
            this.loggedIn = true;
        } else {
            this.loggedIn = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.user?.token) {
            this.loggedIn = true;
        } else {
            this.loggedIn = false;
        }
    }

    logout() {
        this.logoutMethod.emit();
    }
}
