export abstract class Utils {

    /**
     * Decodes a URL-safe token into a value that can be passed to the API
     * in the Authorization header
     * @param value the URL encoded token
     * @returns `string` the decoded token
     */
    static decodeToken(value: string): string {
        value = value.replace('%2F', '/');
        value = value.replace('%2B', '+');
        value = value.replace('%3D', '=');
        return value;
    }

    /**
     * Encodes a token into a URL-safe format
     * @param value the token to be encoded
     * @returns the encoded token
     */
    static encodeToken(value: string): string {
        value = value.replace(/\//g, '%2F');
        value = value.replace(/\+/g, '%2B');
        value = value.replace(/\=/g, '%3D');
        return value;
    }

    static trim(a): any {
        if (a == null) {
            return null;
        }
        if (typeof a === 'string') {
            return a.trim() || null;
        }
        return a;
    }

    static isEmpty(val) {
        if (this.isObject(val)) {
            return Object.entries(val).length === 0;
        } else {
            return (val == null || typeof val === 'string' && val.trim().length <= 0);
        }
    }

    static isObject(x: any): boolean {
        return x != null && typeof x === 'object';
    }

    static isDifferent(a, b): boolean {
        // only checks differences in b compared to a not vice versa
        if (typeof a === 'undefined' && typeof b === 'undefined') {
            return false;
        }

        if (!this.isEmpty(a) && this.isEmpty(b) || this.isEmpty(a) && !this.isEmpty(b)) {
            return true;
        }

        if (Array.isArray(a)) {
            if (a && b && (a.length !== b.length)) {
                return true;
            }
            // sort the array copies so that the next array check is correct
            a = a?.slice()?.sort();
            b = b?.slice()?.sort();
        }

        if (Array.isArray(a) || this.isObject(a)) {
            let diff = false;
            // tslint:disable-next-line:forin
            for (const index in a) { // only differences in b compared to a
                diff = diff || this.isDifferent(a[index], b[index]);
            }
            return diff;
        }

        return !(this.trim(a) === this.trim(b));
    }

    static getDeltaObject(original, modified) {
        const form = {};
        // tslint:disable-next-line:forin
        for (const key in modified) {
            if (key.indexOf('$$') === 0) {
                return;
            }
            if (this.isDifferent(modified[key], original[key])) {
                form[key] = Utils.trim(modified[key]);
            }
        }
        return form;
    }

    /** Used to append endings to day numbers */
    static dayName(val: number): string {
        const value = val.toString();

        if (value.length === 2 && value.substr(0, 1) === '1') {
            return `${val}th`;
        }

        switch (+value.substr(value.length - 1, 1)) {
            case 1: {
                return `${val}st`;
            }
            case 2: {
                return `${val}nd`;
            }
            case 3: {
                return `${val}rd`;
            }
            default: {
                return `${val}th`;
            }
        }
    }

    /**
     * Removes the escape backslashes generated by this properitary query formatting
     * @param val the `string` to remove the escape backslashes from.
     * @returns a `string` value without escape backslashes.
     */
    static removeBackslash(val: string): string {
        val = val.replace(/\\\\/g, 'DOUBLEBACKSLASHPLACEHOLDER');
        val = val.replace(/\\/g, '');
        val = val.replace(/\DOUBLEBACKSLASHPLACEHOLDER/g, '\\');
        return val;
    }

    static addEscape(val: string): string {
        return val.replace(/([.?*+\^$\[\]\\(){}|\-])/g, '\\$1');
    }

    static trackingLink(trackingNumber: string): string {
        if (trackingNumber?.substring(0, 2) === '1Z') {
            return `http://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1=${trackingNumber}`;
        } else {
            return `https://www.fedex.com/fedextrack/?action=track&locale=en_US&cntry_code=us&tracknumbers=${trackingNumber}`;
        }
    }

    static downloadFile(data: any, mimeType: string, fileName: string): void {
        const blob = new Blob([data], {type: mimeType});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    /**
     * Removes all object/array keys that contain empty, null, undefined values
     * in the object/array passed in
     * @param obj is an object or array
     * @returns the same object or array minus the empty values
     */
    static pruneEmpty(obj: any): any {
        for (const prop in obj) {
            if (Array.isArray(obj[prop]) || this.isObject(obj[prop])) {
                obj[prop] = this.pruneEmpty(obj[prop]);
            } else {
                if (this.isEmpty(obj[prop]) || obj[prop] === null || obj[prop] === undefined) {
                    delete obj[prop];
                }
            }
        }
        return obj;
    }

    /**
     * Takes an object array and property name, and returns it sorted alphabetically
     * ascending according to the property value
     * @param obj the object array to sort
     * @param prop the property to sort on
     * @returns the sorted object array
     */
    static sortAlphabetically(obj: any[], prop: string, group?: string, groupOrder?: string): any[] {
        return obj.sort((a, b) => {
            if (Utils.isEmpty(group)) {
                if (a[prop] < b[prop]) {
                    return -1;
                }
                if (a[prop] > b[prop]) {
                    return 1;
                }
                return 0;
            }
            if (!Utils.isEmpty(groupOrder) && groupOrder === 'desc') {
                if (!Utils.isDifferent(a[group], b[group])) {
                    if (a[prop] < b[prop]) {
                        return -1;
                    }
                    if (a[prop] > b[prop]) {
                        return 1;
                    }
                    return 0;
                }
                return a[group] < b[group] ? 1 : -1;
            } else {
                if (!Utils.isDifferent(a[group], b[group])) {
                    if (a[prop] < b[prop]) {
                        return -1;
                    }
                    if (a[prop] > b[prop]) {
                        return 1;
                    }
                    return 0;
                }
                return a[group] < b[group] ? -1 : 1;
            }
        });
    }

    /**
     * Takes an object array and property name, and returns it sorted alphabetically
     * ascending according to the property value
     * @param obj the object array to sort
     * @param prop the property to sort on
     * @returns the sorted object array
     */
    static sortDate(obj: any[], prop: string, order?: string): any[] {
        return obj.sort((a, b) => {
            if (Utils.isEmpty(order) || order === 'asc') {
                if (Utils.isEmpty(a[prop]) && !Utils.isEmpty(b[prop])) {
                    return -1;
                }
                if (!Utils.isEmpty(a[prop]) && Utils.isEmpty(b[prop])) {
                    return 1;
                }
                if (!Utils.isEmpty(a[prop]) && !Utils.isEmpty(b[prop])) {
                    if (a[prop] < b[prop]) {
                        return -1;
                    }
                    if (a[prop] > b[prop]) {
                        return 1;
                    }
                }
                return 0;
            } else {
                if (Utils.isEmpty(a[prop]) && !Utils.isEmpty(b[prop])) {
                    return 1;
                }
                if (!Utils.isEmpty(a[prop]) && Utils.isEmpty(b[prop])) {
                    return -1;
                }
                if (!Utils.isEmpty(a[prop]) && !Utils.isEmpty(b[prop])) {
                    if (a[prop] < b[prop]) {
                        return 1;
                    }
                    if (a[prop] > b[prop]) {
                        return -1;
                    }
                }
                return 0;
            }
        });
    }

    static sortByFirstLastName(a, b): number {
        const fullNameA = a.firstName?.toLowerCase() + ' ' + a.lastName?.toLowerCase();
        const fullNameB = b.firstName?.toLowerCase() + ' ' + b.lastName?.toLowerCase();
        if (fullNameA > fullNameB) {
            return 1;
        }
        if (fullNameA < fullNameB) {
            return -1;
        }
        return 0;
    }
}
