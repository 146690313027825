<div class='chat-container'>
    <div class='chat-room-name'>
        <div>{{ currentRoom }}</div>
    </div>
    <div class='messages imessage'>
        <p *ngFor='let user of messages' [ngClass]="{'from-me': userIsMe(user), 'from-them': !userIsMe(user)}">
            {{ user.name }}: {{ user.message }}
        </p>
    </div>
    <div class='new-message'>
        <form [formGroup]='messageForm'>
            <div #divInputMessage
                (focus)='onFocus(divInputMessage)'
                (keydown)='onKeydown(divInputMessage)'
                (mwlKeydownEnter)='submitMessage(divInputMessage)'
                [contentEditable]='contentEditable'
                class='input-text-message'
                placeholder='Type a message...'>
            </div>
            <div></div>
            <!--      <input-->
            <!--        class='emoji'-->
            <!--        formControlName="message"-->
            <!--        data-lpignore="true"-->
            <!--        type="search"-->
            <!--        (mwlKeydownEnter)="submitMessage()">-->
            <div #emojiPicker (click)='emojiMenu()' [innerHtml]='symbol' class='emoji-choice'></div>
            <!--      <button mat-flat-button color="primary" type="button" (click)="submitMessage()">Send</button>-->
        </form>
    </div>
    <div class='emoji-window'>

    </div>
</div>
