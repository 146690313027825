import { Component, Input, OnInit } from '@angular/core';
import { GridHeading } from '@troyaaronjones/angular_material_data_grid';
import { environment } from '../../../environments/environment';
import { AssignmentService } from '../../services/assignment.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-parent-view',
    templateUrl: './parent-view.component.html',
    styleUrls: ['./parent-view.component.scss']
})
export class ParentViewComponent implements OnInit {
    @Input() parentId;
    initialFilters = [];
    initialSort = {sort: 'desc', sortField: 'dueDate'} as any;
    url = `${environment.apiUrl}/api/assignments`;
    headings: GridHeading[] = [
        {
            fieldName: 'displayName',
            display: 'Name',
            type: 'string',
            width: '100px',
            textAlign: 'left',
            filterType: 'multi-select',
            other: {
                selectionMode: 'multiple',
                source: 'internal',
                optionsObject: [],
            },
        },
        {fieldName: 'dueDate', display: 'Due Date', type: 'date', width: '85px', sort: 'desc'},
        {fieldName: 'name', display: 'Assignment Name', type: 'string', width: '120px'},
        {
            fieldName: 'submitted',
            display: 'Submitted',
            type: 'string',
            width: '50px',
            filterType: 'multi-select',
            other: {
                selectionMode: 'single',
                source: 'internal',
                optionsObject: [
                    {text: 'Yes', value: 'true'},
                    {text: 'No', value: 'false'},
                ],
            },
        },
        {
            fieldName: 'accepted',
            display: 'Accepted',
            type: 'string',
            width: '50px',
            filterType: 'multi-select',
            other: {
                selectionMode: 'single',
                source: 'internal',
                optionsObject: [
                    {text: 'Yes', value: 'true'},
                    {text: 'No', value: 'false'},
                ],
            },
        },
    ];

    hasUserDetails = false;

    constructor(private assignmentService: AssignmentService,
                private userService: UserService) {
    }

    ngOnInit(): void {
        if (this.parentId) {
            this.userService.getStudentsByParent(this.parentId).subscribe(results => {
                let values = '';
                results.forEach(result => {
                    console.log(result);
                    this.headings[0].other.optionsObject.push({
                        text: result.firstName + ' ' + result.lastName,
                        value: result.firstName + ' ' + result.lastName
                    });
                    values += result.firstName + ' ' + result.lastName + ',';
                });
                // values = values.slice(0, values.length - 1);
                // this.initialFilters.push({
                //   field: 'displayName',
                //   operator: 'eq',
                //   value: values
                // });
                this.hasUserDetails = true;
                console.log(this.initialFilters);
            });
        }
    }

    responseReceived(response: any) {
        console.log('this is recieved');
        console.log(response); // If necessary manipulate the data or use data in the parent component
        response.gridData.forEach((item) => {
            item.dueDate = new Date(item.dueDate).toLocaleDateString();
            item.displayName = item.user.map(user => this.toCamelCase((user.firstName + ' ' + user.lastName).toLowerCase())).join(', ');
            item.amdgGridRowBackground = this.chooseColor(item);
        });
    }

    toCamelCase(str) {
        const words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        return words.join(' ');
    }

    private chooseColor(item: any): string {
        if (new Date(item.dueDate).getTime() < Date.now() && !item.submitted) {
            return '#FDC3B9FF'; // rgb(253 195 185 / 100%);
        }
        if (item.submitted && !item.accepted) {
            return '#FFF3D8FF'; // 'rgb(255 243 216 / 100%)';
        }
        if (item.submitted && item.accepted) {
            return '#62CECEFF'; // rgb(98 206 206 / 100%)';
        }
        if (!item.submitted && !item.accepted) {
            return 'lavender';
        }
    }

    requestBodyChanged($event: any) {

    }

    edit($event: any) {

    }
}
