<ng-container>
    <div class='blogs'>
        <amdg-grid
            (requestBodyEmit)='requestBodyChanged($event)'
            (responseEmit)='responseReceived($event)'
            (rowClickEmit)='edit($event)'
            (selectionEmit)='onSelectionChange($event)'
            [headings]='headings'
            [initialFilters]='initialFilters'
            [initialSort]='initialSort'
            [selection]='true'
            [serverSidePagination]='true'
            [url]='url'>
            <mat-card *ngIf='selectedRows?.length > 0' class='amdg-top-right-button'>
                <button (click)='delete()' mat-raised-button>Delete</button>
            </mat-card>
        </amdg-grid>
    </div>
</ng-container>
