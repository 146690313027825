<div draggable='true' ngClass='{{dragAreaClass}}' style='display: flex; justify-content: center'>
    <div class='row'>
        <div class='col-md-12 text-center'>
            Drag files here
            <a (click)='file.click()' href='javascript:void(0)'>
                or click to open
            </a>
            <input #file
                (change)='onFileChange($event)'
                [multiple]='false'
                style='display:none'
                type='file'/>
        </div>
    </div>
</div>
<div *ngIf='error' class='error'>
    Only one file at time allow
</div>


