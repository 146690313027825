import { User } from './user';

export class Booklog {
    // tslint:disable-next-line:variable-name
    _id: string;
    author: string;
    createdBy: User;
    createdDate: Date;
    modifiedBy: User;
    modifiedDate: Date;
    readDate: Date;
    title: string;
    user: User;

    constructor(o?: Booklog) {
        Object.assign(this, o);
    }
}
