import { formatDate } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { GridFilterItem, GridHeading, GridResponse } from '@troyaaronjones/angular_material_data_grid';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Assignment } from '../../_models/assignment';
import { AuthService } from '../../auth.service';
import { StorageService } from '../../core/storage.service';
import { Utils } from '../../core/utils';
import { AssignmentService } from '../../services/assignment.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { UserService } from '../../services/user.service';

class AssignmentParams {
    filterBy = 'all';
    selectedUser = '';
    selectedDate: Date = null;

    constructor(filterBy, selectedUser, selectedDate) {
        this.filterBy = filterBy;
        this.selectedUser = selectedUser;
        this.selectedDate = selectedDate;
    }
}

@Component({
    selector: 'app-assignment-list',
    templateUrl: './assignment-list.component.html',
    styleUrls: ['./assignment-list.component.scss'],
})
export class AssignmentListComponent {
    assignments: Assignment[];
    filterGroup: FormGroup;

    @ViewChild(MatSort) sort: MatSort;
    @Output() deleteAssignment = new EventEmitter<Assignment>();
    @Output() acceptAssignment = new EventEmitter<Assignment>();
    @Output() selectAssignment = new EventEmitter<Assignment>();
    @Output() unacceptAssignment = new EventEmitter<Assignment>();

    users: any;
    filterBy = null;
    deleting = false;
    selectedUser: any;
    selectedDate: any;
    isLoading: boolean;
    hasResults: boolean;
    isAdmin: boolean;
    displayedAssignments: Assignment[];
    dataSource: MatTableDataSource<Assignment>;
    entity: any = null;
    resetFilters: any = null;

    filterOptions = [
        {value: 'all', name: 'All'},
        {value: 'notsubmitted', name: 'Not Submitted'},
        {value: 'notaccepted', name: 'Not Accepted'},
        {value: 'submitted', name: 'Submitted'},
        {value: 'accepted', name: 'Accepted'},
        {value: 'submittednotaccepted', name: 'Submitted/Not Accepted'},
    ];
    displayedColumns: string[] = ['dueDate', 'name', 'submitted', 'accepted', 'delete', 'accept'];
    userTabIndex = -1;

    url = `${environment.apiUrl}/api/assignments`;

    headings: GridHeading[] = [
        {
            fieldName: 'teacherName',
            display: 'Teacher',
            type: 'string',
            width: '100px',
            textAlign: 'left',
            filterType: 'multi-select',
            other: {
                selectionMode: 'multiple',
                source: 'internal',
                optionsObject: [],
            },
        },
        {
            fieldName: 'displayName',
            display: 'Name',
            type: 'string',
            width: '100px',
            textAlign: 'left',
            filterType: 'multi-select',
            other: {
                selectionMode: 'multiple',
                source: 'internal',
                optionsObject: [],
            },
        },
        {fieldName: 'dueDate', display: 'Due Date', type: 'date', width: '85px', sort: 'desc'},
        {fieldName: 'name', display: 'Assignment Name', type: 'string', width: '120px'},
        {fieldName: 'grade', display: 'Grade', type: 'number', width: '50px'},
        {
            fieldName: 'submitted',
            display: 'Submitted',
            type: 'string',
            width: '50px',
            filterType: 'multi-select',
            other: {
                selectionMode: 'single',
                source: 'internal',
                optionsObject: [
                    {text: 'Yes', value: 'true'},
                    {text: 'No', value: 'false'},
                ],
            },
        },
        {
            fieldName: 'accepted',
            display: 'Accepted',
            type: 'string',
            width: '50px',
            filterType: 'multi-select',
            other: {
                selectionMode: 'single',
                source: 'internal',
                optionsObject: [
                    {text: 'Yes', value: 'true'},
                    {text: 'No', value: 'false'},
                ],
            },
        },
        {
            fieldName: 'bookletComplete',
            display: 'Booklet Complete',
            type: 'string',
            width: '50px',
            filterType: 'multi-select',
            other: {
                selectionMode: 'single',
                source: 'internal',
                optionsObject: [
                    {text: 'Yes', value: 'true'},
                    {text: 'No', value: 'false'},
                ],
            },
        },
    ];

    studentHeadings: GridHeading[] = [
        {fieldName: 'dueDate', display: 'Due Date', type: 'date', width: '85px', sort: 'desc'},
        {fieldName: 'name', display: 'Assignment Name', type: 'string', width: '120px'},
        {fieldName: 'grade', display: 'Grade', type: 'number', width: '50px', disableFiltering: true},
        {
            fieldName: 'submitted',
            display: 'Submitted',
            type: 'string',
            width: '50px',
            filterType: 'multi-select',
            other: {
                selectionMode: 'single',
                source: 'internal',
                optionsObject: [
                    {text: 'Yes', value: 'true'},
                    {text: 'No', value: 'false'},
                ],
            },
        },
        {
            fieldName: 'accepted',
            display: 'Accepted',
            type: 'string',
            width: '50px',
            filterType: 'multi-select',
            other: {
                selectionMode: 'single',
                source: 'internal',
                optionsObject: [
                    {text: 'Yes', value: 'true'},
                    {text: 'No', value: 'false'},
                ],
            },
        },
    ];

    initialFilters: GridFilterItem[] = [];
    initialStudentFilters: GridFilterItem[] = [];
    initialSort = {sort: 'desc', sortField: 'dueDate'} as any;
    hasUserDetails = false;
    isParent = false;
    userId = 0;
    selectedRows: any;
    private deletedAssignements: any[];
    private booketCompleted: any[];

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthService,
        private dialogService: ConfirmDialogService,
        private assignmentService: AssignmentService,
        private storageService: StorageService
    ) {
        this.isAdmin = this.authService.isAdmin;
        this.userId = (this.authService.userValue as any).id;
        this.userService.getAllTeachers().subscribe(res => {
            res.forEach(result => {
                this.headings[0].other.optionsObject.push({
                    text: result.firstName + ' ' + result.lastName,
                    value: result.firstName + ' ' + result.lastName
                });
            });
        });
        this.userService.getAllStudents().subscribe((res) => {
            res.sort(Utils.sortByFirstLastName);
            res.forEach(result => {
                this.headings[1].other.optionsObject.push({
                    text: result.firstName + ' ' + result.lastName,
                    value: result.firstName + ' ' + result.lastName
                });
            });
            this.userService.getUser((this.authService.userValue as any).id).subscribe(resp => {
                if (resp.roles.map(r => r.roleName).indexOf('Teacher') > -1) {
                    this.isAdmin = true;
                    const temp = JSON.parse(this.storageService.get('assignmentGridFilterAndSort'))?.filters;
                    if (temp) {
                        this.initialFilters = temp;
                        const teacherNameRec = temp.filter(f => f.field === 'teacherName');
                        if (teacherNameRec?.length > 0) {
                            teacherNameRec[0].value =
                                (this.authService.userValue as any).firstName + ' ' + (this.authService.userValue as any).lastName;
                        } else {
                            this.initialFilters = [{
                                field: 'teacherName',
                                operator: 'eq',
                                value: (this.authService.userValue as any).firstName + ' ' + (this.authService.userValue as any).lastName
                            }];
                        }
                    }
                    this.hasUserDetails = true;
                } else if (resp.roles.map(r => r.roleName).indexOf('Parent') > -1) {
                    if (!this.isAdmin) {
                        this.isParent = true;
                    }
                    const temp = JSON.parse(this.storageService.get('assignmentGridFilterAndSort'))?.filters;
                    if (temp) {
                        this.initialFilters = temp;
                    }
                    this.hasUserDetails = true;
                } else {
                    const temp = JSON.parse(this.storageService.get('assignmentGridFilterAndSort'))?.filters;
                    if (temp) {
                        this.initialFilters = temp;
                        const displayNameRec = temp.filter(f => f.field === 'displayName');
                        if (!this.isAdmin) {
                            if (displayNameRec?.length > 0) {
                                displayNameRec[0].value = (this.authService.userValue as any).firstName + ' ' +
                                    (this.authService.userValue as any).lastName;
                                this.initialStudentFilters = temp;
                            } else {
                                this.initialStudentFilters = [{
                                    field: 'displayName',
                                    operator: 'contains',
                                    value: (this.authService.userValue as any).firstName + ' ' +
                                        (this.authService.userValue as any).lastName
                                }];
                            }
                            this.hasUserDetails = true;
                        } else {
                            this.hasUserDetails = true;
                        }
                    } else {
                        if (!this.isAdmin) {
                            this.initialStudentFilters = [{
                                field: 'displayName',
                                operator: 'contains',
                                value: (this.authService.userValue as any).firstName + ' ' + (this.authService.userValue as any).lastName
                            }];
                            this.hasUserDetails = true;
                        } else {
                            this.hasUserDetails = true;
                        }
                    }
                    this.initialStudentFilters = [{
                        field: 'displayName',
                        operator: 'contains',
                        value: (this.authService.userValue as any).firstName + ' ' + (this.authService.userValue as any).lastName
                    }];
                }
            });
        });
    }

    resetPressed(): void {
        this.entity = {};
    }

    resetWithFilters(): void {
        this.resetFilters = {};
    }

    // this.userService.getAllStudents().subscribe((res) => {
    //   this.users = res.map((i) => new User(i));
    //   this.route.queryParams.subscribe((params) => {
    //     this.isLoading = true;
    //     console.log(params);
    //     if (params?.filterBy) {
    //       this.filterBy = params?.filterBy;
    //     } else {
    //       this.filterBy = 'all';
    //     }
    //     if (params?.selectedUser) {
    //       this.selectedUser = params?.selectedUser;
    //       if (params?.selectedDate) {
    //         this.selectedDate = new Date(+params.selectedDate);
    //       } else {
    //         this.selectedDate = null;
    //       }
    //       if (params?.selectedTabIndex) {
    //         this.userTabIndex = params.selectedTabIndex;
    //       } else {
    //         this.userTabIndex = 0;
    //       }
    //       this.assignmentService
    //         .getAssignmentsByQueryString(
    //           this.serialize(
    //             new AssignmentParams(
    //               params?.filterBy,
    //               params?.selectedUser ? params.selectedUser : this.selectedUser,
    //               params?.selectedDate
    //             )
    //           )
    //         )
    //         .subscribe((results) => {
    //           console.log(results);
    //           this.assignments = results.map((r) => new Assignment(r));
    //           // this.userTabIndex = this.findUserIndexById(this.selectedUser);
    //           this.isLoading = false;
    //           this.init();
    //         });
    //     } else {
    //       this.userTabIndex = this.findUserIndexById(this.selectedUser);
    //       this.isLoading = false;
    //       this.init();
    //     }
    //   });
    // });
    // this.filterGroup = this.fb.group({
    //   selectedUser: [null, Validators.required],
    // });

    toCamelCase(str) {
        const words = str.split(' ');

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(' ');
    }

    responseReceived(response: GridResponse): void {
        console.log('this is recieved');
        console.log(response); // If necessary manipulate the data or use data in the parent component
        response.gridData.forEach((item) => {
            item.dueDate = item.dueDate ? formatDate(new Date(item.dueDate), 'MM/dd/yyyy', 'en-US') : Date.now();
            item.displayName = item.user.map(user => this.toCamelCase((user.firstName + ' ' + user.lastName).toLowerCase())).join(', ');
            item.teacherName = item.teachers.map(user => this.toCamelCase((user.firstName + ' ' + user.lastName).toLowerCase())).join(', ');
            item.amdgGridRowBackground = this.chooseColor(item);
        });
    }

    createCustom(obj) {
        const newObj = {};
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                if (obj[p]) {
                    newObj[p] = obj[p];
                }
            }
        }
        return newObj;
    }

    edit(row) {
        if (!this.deleting) {
            // this.selectAssignment.emit(row);
            this.router.navigate([`edit/${row.item._id}`], {relativeTo: this.route});
        }
    }

    delete(element) {
        this.deleting = true;
        const options = {
            title: 'Delete assignment "' + element.name + '" ? ',
            message: 'By deleting you will permanently lose this assignment.',
            cancelText: 'CANCEL',
            confirmText: 'YES, DELETE',
        };

        this.dialogService.open(options);

        this.dialogService.confirmed().subscribe((confirmed) => {
            if (confirmed) {
                console.log('Delete;', element);
                this.deleteAssignment.emit(element);
                this.deleting = false;
            } else {
                this.deleting = false;
            }
        });
    }

    accept(element) {
        this.deleting = true;
        console.log('Accept;', element);
        this.acceptAssignment.emit(element);
    }

    requestBodyChanged($event: any) {
        this.storageService.save('assignmentGridFilterAndSort', JSON.stringify($event));
        console.log($event);
    }

    private chooseColor(item: any): string {
        if (new Date(item.dueDate).getTime() < Date.now() && !item.submitted) {
            return '#FDC3B9FF'; // rgb(253 195 185 / 100%);
        }
        if (item.submitted && !item.accepted) {
            return '#FFF3D8FF'; // 'rgb(255 243 216 / 100%)';
        }
        if (item.submitted && item.accepted) {
            return '#62CECEFF'; // rgb(98 206 206 / 100%)';
        }
        if (!item.submitted && !item.accepted) {
            return '#e6e6fa';
        }
    }

    onSelectionChange(selectedRows) {
        this.deleting = true;
        setTimeout(() => {
            this.deleting = false;
        }, 3000);
        this.selectedRows = selectedRows;
    }

    onDelete() {
        if (this.selectedRows) {
            this.deleting = true;
            const options = {
                title: 'Delete Assignments? ',
                message: 'By deleting you will permanently lose ' + this.selectedRows.length + ' assignment/s',
                cancelText: 'CANCEL',
                confirmText: 'YES, DELETE'
            };

            this.dialogService.open(options);

            this.dialogService.confirmed().subscribe(confirmed => {
                if (confirmed) {
                    console.log('Delete;', this.selectedRows);
                    this.deleteAssignments(this.selectedRows, () => {
                        this.deleting = false;
                        window.location.reload();
                    });
                } else {
                    this.deleting = false;
                }
            });
        }
    }

    deleteAssignments(selected, callback) {
        this.deletedAssignements = [];
        const deletes = new Array<Observable<any>>();
        this.selectedRows.forEach((a) => {
            deletes.push(this.assignmentService.delete(a));
        });
        const observable = forkJoin(deletes);
        observable.subscribe({
            next: value => this.deletedAssignements.push(value),
            complete: () => callback(),
        });
    }

    onBookletComplete() {
        if (this.selectedRows?.length > 0) {
            this.bookletComplete(this.selectedRows, () => {
                window.location.reload();
            });
        }
    }

    bookletComplete(selected, callback) {
        this.booketCompleted = [];
        const booklets = new Array<Observable<any>>();
        this.selectedRows.forEach((a) => {
            booklets.push(this.assignmentService.markCompletedBooklet(a));
        });
        const observable = forkJoin(booklets);
        observable.subscribe({
            next: value => this.booketCompleted.push(value),
            complete: () => callback(),
        });
    }
}
