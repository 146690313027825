<div class='container'>
    <div *ngIf='preview?.url' class='preview'>
        <div [hidden]='!preview?.image'>
            <img [src]='preview?.image' class='image'>
        </div>
        <div class='preview-text'>
            <div [hidden]='!preview?.url' class='url'>
                <span><a href='{{preview?.url}}' target='_blank'>{{ preview?.url }}</a></span>
            </div>
            <div [hidden]='!preview?.title' class='title'>
                <span>{{ preview?.title }}</span>
            </div>
            <div [hidden]='!preview?.description' class='description'>
                <span>{{ preview?.description }}</span>
            </div>
        </div>
    </div>
</div>
