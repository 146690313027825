<mat-card appearance='outlined'>
    <mat-card-content>
        <form (ngSubmit)='onSubmit()' [formGroup]='form'>
            <h2>Log In</h2>
            <mat-error *ngIf='loginInvalid'>
                The username and password were not recognised
            </mat-error>
            <mat-form-field class='full-width-input'>
                <input formControlName='username' matInput placeholder='Username' required>
                <mat-error>
                    Please provide a valid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field class='full-width-input'>
                <input [type]="hide ? 'password' : 'text'" formControlName='password' matInput placeholder='Password'
                    required>
                <mat-icon (click)='hide = !hide' matSuffix>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                <mat-error>
                    Please provide a valid password
                </mat-error>
            </mat-form-field>
            <asl-google-signin-button></asl-google-signin-button>
            <button color='primary' mat-raised-button>Login</button>
            <p></p>
            <p>Don't remember your password? <a routerLink='/forgot-password'>Reset it</a></p>
        </form>
    </mat-card-content>
</mat-card>
