import { EmojiGroup } from '../chat-emoji.model';

export let allEmoji: EmojiGroup[] = [
    {
        group: 'Smiley Face Emojis',
        emojis: [
            {
                name: 'Slightly smiling face',
                code: 'U+1F642'
            },
            {
                name: 'Smiling face',
                code: 'U+1F600'
            },
            {
                name: 'Smiling face with big eyes',
                code: 'U+1F603'
            },
            {
                name: 'Smiling face with smiling eyes',
                code: 'U+1F604'
            },
            {
                name: 'Beaming face with smiling eyes',
                code: 'U+1F601'
            },
            {
                name: 'Smiling face with tears',
                code: 'U+1F605'
            },
            {
                name: 'Grinning face',
                code: 'U+1F606'
            },
            {
                name: 'Rolling on the floor laughing',
                code: 'U+1F923'
            },
            {
                name: 'Lauging with tears',
                code: 'U+1F602'
            },
            {
                name: 'Upside down face',
                code: 'U+1F643'
            },
            {
                name: 'Winking face',
                code: 'U+1F609'
            },
            {
                name: 'Smiling face with smiling eyes',
                code: 'U+1F60A'
            },
            {
                name: 'Smiling face with halo',
                code: 'U+1F607'
            },
            {
                name: 'Smiling face with sunglasses',
                code: 'U+1F60E'
            },
            {
                name: 'Nerdy face',
                code: 'U+1F913'
            },
            {
                name: 'Face with monocle',
                code: 'U+1F9D0'
            },
            {
                name: 'Partying face',
                code: 'U+1F973'
            }
        ]
    },
    {
        group: 'Emotional Faces Emojis',
        emojis: [
            {
                name: 'Smiling face with hearts',
                code: 'U+1F970'
            },
            {
                name: 'Smiling face with heart eyes',
                code: 'U+1F60D'
            },
            {
                name: 'Star-struck',
                code: 'U+1F60D'
            },
            {
                name: 'Face blowing kiss',
                code: 'U+1F618'
            },
            {
                name: 'Kissing face',
                code: 'U+1F617'
            },
            {
                name: 'Smiling face',
                code: 'U+263A'
            },
            {
                name: 'Kissing face with closed eyes',
                code: 'U+1F61A'
            },
            {
                name: 'Kissng face with smiling eyes',
                code: 'U+1F619'
            },
            {
                name: 'Smiling face with tears',
                code: 'U+1F972'
            }
        ]
    },
    {
        group: 'Faces with Tongue Emojis',
        emojis: [
            {
                name: 'Yummy face',
                code: 'U+1F60B'
            },
            {
                name: 'Face with tongue',
                code: 'U+1F61B'
            },
            {
                name: 'WInking face with tongue',
                code: 'U+1F61C'
            },
            {
                name: 'Zany face',
                code: 'U+1F92A'
            },
            {
                name: 'Squinting face with tongue',
                code: 'U+1F61D'
            },
            {
                name: 'Money face with money tongue',
                code: 'U+1F911'
            }
        ]
    },
    {
        group: 'Faces with Hands Emojis',
        emojis: [
            {
                name: 'Hugs',
                code: 'U+1F917'
            },
            {
                name: 'Face with hand in mouth',
                code: 'U+1F92D'
            },
            {
                name: 'Shushing face',
                code: 'U+1F92B'
            },
            {
                name: 'Thinking face',
                code: 'U+1F914'
            }
        ]
    },
    {
        group: 'Neutral Faces Emojis',
        emojis: [
            {
                name: 'Neutral face',
                code: 'U+1F610'
            },
            {
                name: 'Zipped mouth',
                code: 'U+1F910'
            },
            {
                name: 'Face with raised eyebrow',
                code: 'U+1F928'
            },
            {
                name: 'Expressionless face',
                code: 'U+1F611'
            },
            {
                name: 'Face with no mouth',
                code: 'U+1F636'
            },
            {
                name: 'Smirking face',
                code: 'U+1F60F'
            },
            {
                name: 'Unamused face',
                code: 'U+1F612'
            },
            {
                name: 'Face with rolling eyes',
                code: 'U+1F644'
            },
            {
                name: 'Grimacing face',
                code: 'U+1F62C'
            },
            {
                name: 'Lying face',
                code: 'U+1F925'
            }
        ]
    },
    {
        group: 'Sleepy Faces Emojis',
        emojis: [
            {
                name: 'Sleepy face',
                code: 'U+1F62A'
            },
            {
                name: 'Sleeping face',
                code: 'U+1F634'
            },
            {
                name: 'Relieved face',
                code: 'U+1F60C'
            },
            {
                name: 'Pensive face',
                code: 'U+1F614'
            },
            {
                name: 'Drooling face',
                code: 'U+1F924'
            }
        ]
    },
    {
        group: 'Sick Faces Emojis',
        emojis: [
            {
                name: 'Face with mask',
                code: 'U+1F637'
            },
            {
                name: 'Face with thermometer',
                code: 'U+1F912'
            },
            {
                name: 'Face with bandage',
                code: 'U+1F915'
            },
            {
                name: 'Nauseous face',
                code: 'U+1F922'
            },
            {
                name: 'Vomiting face',
                code: 'U+1F92E'
            },
            {
                name: 'Sneezing face',
                code: 'U+1F927'
            },
            {
                name: 'Hot face',
                code: 'U+1F975'
            },
            {
                name: 'Cold face',
                code: 'U+1F976'
            },
            {
                name: 'Woozy face',
                code: 'U+1F974'
            },
            {
                name: 'Face with crossed-out eyes',
                code: 'U+1F635'
            },
            {
                name: 'Face with exploding head',
                code: 'U+1F92F'
            }
        ]
    },
    {
        group: 'Concerned Faces Emojis',
        emojis: [
            {
                name: 'Confused face',
                code: 'U+1F615'
            },
            {
                name: 'Worried face',
                code: 'U+1F61F'
            },
            {
                name: 'Slightly frowning face',
                code: 'U+1F641'
            },
            {
                name: 'Frowning face',
                code: 'U+2639'
            },
            {
                name: 'Face with open mouth',
                code: 'U+1F62E'
            },
            {
                name: 'Hushed face',
                code: 'U+1F62F'
            },
            {
                name: 'Astonished face',
                code: 'U+1F632'
            },
            {
                name: 'Flushed face',
                code: 'U+1F633'
            },
            {
                name: 'Begging face',
                code: 'U+1F97A'
            },
            {
                name: 'Frowning face with open mouth',
                code: 'U+1F626'
            },
            {
                name: 'Angushed face',
                code: 'U+1F627'
            },
            {
                name: 'Fearful face',
                code: 'U+1F628'
            },
            {
                name: 'Anxious face with sweat',
                code: 'U+1F630'
            },
            {
                name: 'Sad but relieved face',
                code: 'U+1F625'
            },
            {
                name: 'Crying face',
                code: 'U+1F622'
            },
            {
                name: 'Loudly crying face',
                code: 'U+1F62D'
            },
            {
                name: 'Screaming face',
                code: 'U+1F631'
            },
            {
                name: 'Confounded face',
                code: 'U+1F616'
            },
            {
                name: 'Persevering face',
                code: 'U+1F623'
            },
            {
                name: 'Disappointed face',
                code: 'U+1F61E'
            },
            {
                name: 'Downcast face with sweat',
                code: 'U+1F613'
            },
            {
                name: 'Weary face',
                code: 'U+1F629'
            },
            {
                name: 'Tired face',
                code: 'U+1F62B'
            },
            {
                name: 'Yawning face',
                code: 'U+1F971'
            }
        ]
    },
    {
        group: 'Negative Faces Emojis',
        emojis: [
            {
                name: 'Face with steam',
                code: 'U+1F624'
            },
            {
                name: 'Pouting face',
                code: 'U+1F621'
            },
            {
                name: 'Angry face',
                code: 'U+1F620'
            },
            {
                name: 'Face with symbols on mouth',
                code: 'U+1F92C'
            },
            {
                name: 'Smiling face with horns',
                code: 'U+1F608'
            },
            {
                name: 'Angry face with horns',
                code: 'U+1F47F'
            },
            {
                name: 'Skull',
                code: 'U+1F480'
            },
            {
                name: 'Skull and cross-bone',
                code: 'U+2620'
            }
        ]
    },
    {
        group: 'Costume Faces Emojis',
        emojis: [
            {
                name: 'Pile of poo',
                code: 'U+1F4A9'
            },
            {
                name: 'Clown',
                code: 'U+1F921'
            },
            {
                name: 'Ogre',
                code: 'U+1F479'
            },
            {
                name: 'Goblin',
                code: 'U+1F47A'
            },
            {
                name: 'Ghost',
                code: 'U+1F47B'
            },
            {
                name: 'Alien',
                code: 'U+1F47D'
            },
            {
                name: 'Alien monster',
                code: 'U+1F47E'
            },
            {
                name: 'Robot',
                code: 'U+1F916'
            }
        ]
    },
    {
        group: 'Faces that require more than one unicode character',
        emojis: [
            {
                name: 'Face in clouds',
                code: 'U+1F636 U+200D U+1F32B U+FE0F'
            },
            {
                name: 'Face exhaling',
                code: 'U+1F62E U+200D U+1F4A8'
            },
            {
                name: 'Face with spiral eyes',
                code: 'U+1F635 U+200D U+1F4AB'
            },
            {
                name: 'Heart on fire',
                code: 'U+2764 U+FE0F U+200D U+1F525'
            },
            {
                name: 'Mending heart',
                code: 'U+2764 U+FE0F U+200D U+1FA79'
            },
            {
                name: 'Eye in speech bubble',
                code: 'U+1F441 U+FE0F U+200D U+1F5E8 U+FE0F'
            },
            {
                name: 'Man with beard',
                code: 'U+1F9D4 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman with beard',
                code: 'U+1F9D4 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man with red hair',
                code: 'U+1F468 U+200D U+1F9B0'
            },
            {
                name: 'Man with curly hair',
                code: 'U+1F468 U+200D U+1F9B1'
            },
            {
                name: 'Man with white hair',
                code: 'U+1F468 U+200D U+1F9B3'
            },
            {
                name: 'Man with bald head',
                code: 'U+1F468 U+200D U+1F9B2'
            },
            {
                name: 'Woman with red hair',
                code: 'U+1F469 U+200D U+1F9B0'
            },
            {
                name: 'Person with red hair',
                code: 'U+1F9D1 U+200D U+1F9B0'
            },
            {
                name: 'Woman with curly hair',
                code: 'U+1F469 U+200D U+1F9B1'
            },
            {
                name: 'Person with curly hair',
                code: 'U+1F9D1 U+200D U+1F9B1'
            },
            {
                name: 'Woman with white hair',
                code: 'U+1F469 U+200D U+1F9B3'
            },
            {
                name: 'Person with white hair',
                code: 'U+1F9D1 U+200D U+1F9B3'
            },
            {
                name: 'Woman with bald head',
                code: 'U+1F469 U+200D U+1F9B2'
            },
            {
                name: 'Person with bald head',
                code: 'U+1F9D1 U+200D U+1F9B2'
            },
            {
                name: 'Woman with blond hair',
                code: 'U+1F471 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man with blond hair',
                code: 'U+1F471 U+200D U+2642 U+FE0F'
            }
        ]
    },
    {
        group: 'Cat Faces Emojis',
        emojis: [
            {
                name: 'Grinnig cat',
                code: 'U+1F63A'
            },
            {
                name: 'Grinning cat with smiling eyes',
                code: 'U+1F638'
            },
            {
                name: 'Grinning cat with tears',
                code: 'U+1F639'
            },
            {
                name: 'Smiling cat with heart eyes',
                code: 'U+1F63B'
            },
            {
                name: 'Cat with wry smile',
                code: 'U+1F63C'
            },
            {
                name: 'Kissing cat',
                code: 'U+1F63D'
            },
            {
                name: 'Weary cat',
                code: 'U+1F640'
            },
            {
                name: 'Crying cat',
                code: 'U+1F63F'
            },
            {
                name: 'Pouting cat',
                code: 'U+1F63E'
            }
        ]
    },
    {
        group: 'Monkey Faces Emojis',
        emojis: [
            {
                name: 'See no evil monkey',
                code: 'U+1F648'
            },
            {
                name: 'Hear no evil monkey',
                code: 'U+1F649'
            },
            {
                name: 'Speak no evil monkey',
                code: 'U+1F64A'
            }
        ]
    },
    {
        group: 'Emotion Emojis',
        emojis: [
            {
                name: 'Kiss',
                code: 'U+1F48B'
            },
            {
                name: 'Love letter',
                code: 'U+1F48C'
            },
            {
                name: 'Heart with arrow',
                code: 'U+1F498'
            },
            {
                name: 'Heart with ribbon',
                code: 'U+1F49D'
            },
            {
                name: 'Sparking heart',
                code: 'U+1F496'
            },
            {
                name: 'Growing heart',
                code: 'U+1F497'
            },
            {
                name: 'Beating heart',
                code: 'U+1F493'
            },
            {
                name: 'Revolving heart',
                code: 'U+1F49E'
            },
            {
                name: 'Two hearts',
                code: 'U+1F495'
            },
            {
                name: 'Heart decoration',
                code: 'U+1F49F'
            },
            {
                name: 'Heart exclamation',
                code: 'U+2763'
            },
            {
                name: 'Broken heart',
                code: 'U+1F494'
            },
            {
                name: 'Heart on fire',
                code: 'U+2764'
            },
            {
                name: 'Mending heart',
                code: 'U+2764'
            },
            {
                name: 'Red heart',
                code: 'U+2764'
            },
            {
                name: 'Orange heart',
                code: 'U+1F9E1'
            },
            {
                name: 'Yellow heart',
                code: 'U+1F49B'
            },
            {
                name: 'Green heart',
                code: 'U+1F49A'
            },
            {
                name: 'Blue heart',
                code: 'U+1F499'
            },
            {
                name: 'Purple heart',
                code: 'U+1F49C'
            },
            {
                name: 'Brown heart',
                code: 'U+1F90E'
            },
            {
                name: 'Black heart',
                code: 'U+1F5A4'
            },
            {
                name: 'White heart',
                code: 'U+1F90D'
            },
            {
                name: 'Hundred(correct)',
                code: 'U+1F4AF'
            },
            {
                name: 'Anger',
                code: 'U+1F4A2'
            },
            {
                name: 'Collision',
                code: 'U+1F4A5'
            },
            {
                name: 'Dizzy',
                code: 'U+1F4AB'
            },
            {
                name: 'Sweat droplets',
                code: 'U+1F4A6'
            },
            {
                name: 'Dashing away',
                code: 'U+1F4A8'
            },
            {
                name: 'Hole',
                code: 'U+1F573'
            },
            {
                name: 'Bomb',
                code: 'U+1F4A3'
            },
            {
                name: 'Message baloon',
                code: 'U+1F4AC'
            },
            {
                name: 'Eye in speech bubble',
                code: 'U+1F441'
            },
            {
                name: 'Left speech bubble',
                code: 'U+1F5E8'
            },
            {
                name: 'Anger bubble',
                code: 'U+1F5EF'
            },
            {
                name: 'Thought baloon',
                code: 'U+1F4AD'
            }
        ]
    },
    {
        group: 'Hands and other Body Parts Emojis',
        emojis: [
            {
                name: 'zzz',
                code: 'U+1F4A4'
            },
            {
                name: 'Waving hand',
                code: 'U+1F44B'
            },
            {
                name: 'Raised back of hand',
                code: 'U+1F91A'
            },
            {
                name: 'Hands with splayed finger',
                code: 'U+1F590'
            },
            {
                name: 'Raised hand',
                code: 'U+270B'
            },
            {
                name: 'Vulcan salute',
                code: 'U+1F596'
            },
            {
                name: 'Ok',
                code: 'U+1F44C'
            },
            {
                name: 'Pinched fingers',
                code: 'U+1F90C'
            },
            {
                name: 'Pinched hand',
                code: 'U+1F90F'
            },
            {
                name: 'Victory hand',
                code: 'U+270C'
            },
            {
                name: 'Crossed fingers',
                code: 'U+1F91E'
            },
            {
                name: 'Love you',
                code: 'U+1F91F'
            },
            {
                name: 'Horn sign',
                code: 'U+1F918'
            },
            {
                name: 'Call me hand',
                code: 'U+1F919'
            },
            {
                name: 'Index finger pointing left',
                code: 'U+1F448'
            },
            {
                name: 'Index finger pointing right',
                code: 'U+1F449'
            },
            {
                name: 'Index finger pointing up',
                code: 'U+1F446'
            },
            {
                name: 'Index finger pointing down',
                code: 'U+1F447'
            },
            {
                name: 'Middle finger',
                code: 'U+1F595'
            },
            {
                name: 'Forehand Index finger pointing up',
                code: 'U+261D'
            },
            {
                name: 'Index finger pointing at viewer',
                code: 'U+1FAF5'
            },
            {
                name: 'Thumbs up',
                code: 'U+1F44D'
            },
            {
                name: 'Thumbs down',
                code: 'U+1F44E'
            },
            {
                name: 'Raised fist',
                code: 'U+270A'
            },
            {
                name: 'Fist',
                code: 'U+1F44A'
            },
            {
                name: 'Left facing fist',
                code: 'U+1F91B'
            },
            {
                name: 'Right facing fist',
                code: 'U+1F91C'
            },
            {
                name: 'Clapping hands',
                code: 'U+1F44F'
            },
            {
                name: 'Raised hands',
                code: 'U+1F64C'
            },
            {
                name: 'Open hands',
                code: 'U+1F450'
            },
            {
                name: 'Palms together hands',
                code: 'U+1F932'
            },
            {
                name: 'Handshake',
                code: 'U+1F91D'
            },
            {
                name: 'Praying hands',
                code: 'U+1F64F'
            },
            {
                name: 'Writing hands',
                code: 'U+270D'
            },
            {
                name: 'Nail polish',
                code: 'U+1F485'
            },
            {
                name: 'Selfie hand',
                code: 'U+1F933'
            },
            {
                name: 'Flexed biceps',
                code: 'U+1F4AA'
            },
            {
                name: 'Mechanical arm',
                code: 'U+1F9BE'
            },
            {
                name: 'Leg',
                code: 'U+1F9B5'
            },
            {
                name: 'Mechanical leg',
                code: 'U+1F9BF'
            },
            {
                name: 'Foot',
                code: 'U+1F9B6'
            },
            {
                name: 'Ear',
                code: 'U+1F442'
            },
            {
                name: 'Ear with earing aid',
                code: 'U+1F9BB'
            },
            {
                name: 'Nose',
                code: 'U+1F443'
            },
            {
                name: 'Brain',
                code: 'U+1F9E0'
            },
            {
                name: 'Footprint',
                code: 'U+1F463'
            },
            {
                name: 'Mechanical heart',
                code: 'U+1FAC0'
            },
            {
                name: 'Lungs',
                code: 'U+1FAC1'
            },
            {
                name: 'Tooth',
                code: 'U+1F9B7'
            },
            {
                name: 'Bone',
                code: 'U+1F9B7'
            },
            {
                name: 'Eyes',
                code: 'U+1F440'
            },
            {
                name: 'Eye',
                code: 'U+1F441'
            },
            {
                name: 'Nose',
                code: 'U+1F445'
            },
            {
                name: 'Mouth',
                code: 'U+1F444'
            },
            {
                name: 'Person',
                code: 'U+1F9D1'
            },
            {
                name: 'Baby',
                code: 'U+1F476'
            },
            {
                name: 'Child',
                code: 'U+1F9D2'
            },
            {
                name: 'Boy',
                code: 'U+1F466'
            },
            {
                name: 'Girl',
                code: 'U+1F467'
            },
            {
                name: 'Person with blonde hair',
                code: 'U+1F471'
            },
            {
                name: 'Man',
                code: 'U+1F468'
            },
            {
                name: 'Bearded person',
                code: 'U+1F9D4'
            },
            {
                name: 'Bearded man',
                code: 'U+1F9D4'
            },
            {
                name: 'Bearded woman',
                code: 'U+1F9D4'
            }
        ]
    },
    {
        group: 'Person Emojis',
        emojis: [
            {
                name: 'Man with red hair',
                code: 'U+1F468'
            },
            {
                name: 'Man with curly hair',
                code: 'U+1F9B1'
            },
            {
                name: 'Man with white hair',
                code: 'U+200D'
            },
            {
                name: 'Bald man',
                code: 'U+1F468'
            },
            {
                name: 'Woman',
                code: 'U+1F469'
            },
            {
                name: 'Woman with red hair',
                code: 'U+1F469'
            },
            {
                name: 'Woman with curly hair',
                code: 'U+1F469'
            },
            {
                name: 'Woman with white hair',
                code: 'U+1F469'
            },
            {
                name: 'Bald woman',
                code: 'U+1F469'
            },
            {
                name: 'Woman with blonde hair',
                code: 'U+1F471'
            },
            {
                name: 'Man with blonde hair',
                code: 'U+1F471'
            },
            {
                name: 'Old person',
                code: 'U+1F9D3'
            },
            {
                name: 'Old man',
                code: 'U+1F474'
            },
            {
                name: 'Old woman',
                code: 'U+1F475'
            },
            {
                name: 'Person frowning',
                code: 'U+1F64D'
            },
            {
                name: 'Man frowning',
                code: 'U+1F64D U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman frowning',
                code: 'U+1F64D U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man pouting',
                code: 'U+1F64E U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman pouting',
                code: 'U+1F64E U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man gesturing no',
                code: 'U+1F645 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman gesturing no',
                code: 'U+1F645 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man gesturing ok',
                code: 'U+1F646 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman gesturing ok',
                code: 'U+1F646 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man tipping hand',
                code: 'U+1F481 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman tipping hand',
                code: 'U+1F481 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man raising hand',
                code: 'U+1F64B U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman raising hand',
                code: 'U+1F64B U+200D U+2640 U+FE0F'
            },
            {
                name: 'Deaf man',
                code: 'U+1F9CF U+200D U+2642 U+FE0F'
            },
            {
                name: 'Deaf woman',
                code: 'U+1F9CF U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man bowing',
                code: 'U+1F647 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman bowing',
                code: 'U+1F647 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man facepalming',
                code: 'U+1F926 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman facepalming',
                code: 'U+1F926 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man shrugging',
                code: 'U+1F937 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman shrugging',
                code: 'U+1F937 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Health worker',
                code: 'U+1F9D1 U+200D U+2695 U+FE0F'
            },
            {
                name: 'Man health worker',
                code: 'U+1F468 U+200D U+2695 U+FE0F'
            },
            {
                name: 'Woman health worker',
                code: 'U+1F469 U+200D U+2695 U+FE0F'
            },
            {
                name: 'Student',
                code: 'U+1F9D1 U+200D U+1F393'
            },
            {
                name: 'Man student',
                code: 'U+1F468 U+200D U+1F393'
            },
            {
                name: 'Woman student',
                code: 'U+1F469 U+200D U+1F393'
            },
            {
                name: 'Teacher',
                code: 'U+1F9D1 U+200D U+1F3EB'
            },
            {
                name: 'Man teacher',
                code: 'U+1F468 U+200D U+1F3EB'
            },
            {
                name: 'Woman teacher',
                code: 'U+1F469 U+200D U+1F3EB'
            },
            {
                name: 'Judge',
                code: 'U+1F468 U+200D U+2696 U+FE0F'
            },
            {
                name: 'Woman judge',
                code: 'U+1F469 U+200D U+2696 U+FE0F'
            },
            {
                name: 'Farmer',
                code: 'U+1F9D1 U+200D U+1F33E'
            },
            {
                name: 'Man farmer',
                code: 'U+1F468 U+200D U+1F33E'
            },
            {
                name: 'Woman farmer',
                code: 'U+1F469 U+200D U+1F33E'
            },
            {
                name: 'Cook',
                code: 'U+1F9D1 U+200D U+1F373'
            },
            {
                name: 'Man cook',
                code: 'U+1F468 U+200D U+1F373'
            },
            {
                name: 'Woman cook',
                code: 'U+1F469 U+200D U+1F373'
            },
            {
                name: 'Mechanic',
                code: 'U+1F9D1 U+200D U+1F527'
            },
            {
                name: 'Man mechanic',
                code: 'U+1F468 U+200D U+1F527'
            },
            {
                name: 'Woman mechanic',
                code: 'U+1F469 U+200D U+1F527'
            },
            {
                name: 'Factory worker',
                code: 'U+1F9D1 U+200D U+1F3ED'
            },
            {
                name: 'Man factory worker',
                code: 'U+1F468 U+200D U+1F3ED'
            },
            {
                name: 'Woman factory worker',
                code: 'U+1F469 U+200D U+1F3ED'
            },
            {
                name: 'Office worker',
                code: 'U+1F9D1 U+200D U+1F4BC'
            },
            {
                name: 'Man office worker',
                code: 'U+1F468 U+200D U+1F4BC'
            },
            {
                name: 'Woman factory worker',
                code: 'U+1F469 U+200D U+1F4BC'
            },
            {
                name: 'Scientist',
                code: 'U+1F9D1 U+200D U+1F52C'
            },
            {
                name: 'Man scientist',
                code: 'U+1F468 U+200D U+1F52C'
            },
            {
                name: 'Woman scientist',
                code: 'U+1F469 U+200D U+1F52C'
            },
            {
                name: 'Technologist',
                code: 'U+1F9D1 U+200D U+1F4BB'
            },
            {
                name: 'Man technologist',
                code: 'U+1F468 U+200D U+1F4BB'
            },
            {
                name: 'Woman technologist',
                code: 'U+1F469 U+200D U+1F4BB'
            },
            {
                name: 'Singer',
                code: 'U+1F9D1 U+200D U+1F3A4'
            },
            {
                name: 'Man singer',
                code: 'U+1F468 U+200D U+1F3A4'
            },
            {
                name: 'Woman singer',
                code: 'U+1F469 U+200D U+1F3A4'
            },
            {
                name: 'Artist',
                code: 'U+1F9D1 U+200D U+1F3A8'
            },
            {
                name: 'Man artist',
                code: 'U+1F468 U+200D U+1F3A8'
            },
            {
                name: 'Woman artist',
                code: 'U+1F469 U+200D U+1F3A8'
            },
            {
                name: 'Pilot',
                code: 'U+1F9D1 U+200D U+2708 U+FE0F'
            },
            {
                name: 'Man pilot',
                code: 'U+1F468 U+200D U+2708 U+FE0F'
            },
            {
                name: 'Woman pilot',
                code: 'U+1F469 U+200D U+2708 U+FE0F'
            },
            {
                name: 'Astronaut',
                code: 'U+1F9D1 U+200D U+1F680'
            },
            {
                name: 'Man astronaut',
                code: 'U+1F468 U+200D U+1F680'
            },
            {
                name: 'Womanh astronaut',
                code: 'U+1F469 U+200D U+1F680'
            },
            {
                name: 'Firefighter',
                code: 'U+1F9D1 U+200D U+1F692'
            },
            {
                name: 'Man firefighter',
                code: 'U+1F468 U+200D U+1F692'
            },
            {
                name: 'Woman firefighter',
                code: 'U+1F469 U+200D U+1F692'
            },
            {
                name: 'Man police',
                code: 'U+1F46E U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman police',
                code: 'U+1F46E U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man detective',
                code: 'U+1F575 U+FE0F U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman detective',
                code: 'U+1F575 U+FE0F U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man guard',
                code: 'U+1F482 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman guard',
                code: 'U+1F482 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man construction worker',
                code: 'U+1F477 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman construction worker',
                code: 'U+1F477 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man wearing turban',
                code: 'U+1F473 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman wearing turban',
                code: 'U+1F473 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man in tuxedo',
                code: 'U+1F935 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman in tuxedo',
                code: 'U+1F935 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man with veil',
                code: 'U+1F470 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman with veil',
                code: 'U+1F470 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person feeding baby',
                code: 'U+1F9D1 U+200D U+1F37C'
            },
            {
                name: 'Woman feeding baby',
                code: 'U+1F469 U+200D U+1F37C'
            },
            {
                name: 'Man feeding baby',
                code: 'U+1F468 U+200D U+1F37C'
            },
            {
                name: 'Mx claus',
                code: 'U+1F9D1 U+200D U+1F384'
            },
            {
                name: 'Man superhero',
                code: 'U+1F9B8 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman superhero',
                code: 'U+1F9B8 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man supervillain',
                code: 'U+1F9B9 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman supervillain',
                code: 'U+1F9B9 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man mage',
                code: 'U+1F9D9 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman mage',
                code: 'U+1F9D9 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man fairy',
                code: 'U+1F9DA U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman fairy',
                code: 'U+1F9DA U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man vampire',
                code: 'U+1F9DB U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman vampire',
                code: 'U+1F9DB U+200D U+2640 U+FE0F'
            },
            {
                name: 'Merman',
                code: 'U+1F9DC U+200D U+2642 U+FE0F'
            },
            {
                name: 'Mermaid',
                code: 'U+1F9DC U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man elf',
                code: 'U+1F9DD U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman elf',
                code: 'U+1F9DD U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man genie',
                code: 'U+1F9DE U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman genie',
                code: 'U+1F9DE U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man zombie',
                code: 'U+1F9DF U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman zombie',
                code: 'U+1F9DF U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man getting massage',
                code: 'U+1F486 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman getting massage',
                code: 'U+1F486 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man getting haircut',
                code: 'U+1F487 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman getting haircut',
                code: 'U+1F487 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man walking',
                code: 'U+1F6B6 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman walking',
                code: 'U+1F6B6 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man standing',
                code: 'U+1F9CD U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman standing',
                code: 'U+1F9CD U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man kneeling',
                code: 'U+1F9CE U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman kneeling',
                code: 'U+1F9CE U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person with white cane',
                code: 'U+1F9D1 U+200D U+1F9AF'
            },
            {
                name: 'Man with white cane',
                code: 'U+1F468 U+200D U+1F9AF'
            },
            {
                name: 'Woman with white cane',
                code: 'U+1F469 U+200D U+1F9AF'
            },
            {
                name: 'Person with motorized wheelchair',
                code: 'U+1F9D1 U+200D U+1F9BC'
            },
            {
                name: 'Man in motorized wheelchair',
                code: 'U+1F468 U+200D U+1F9BC'
            },
            {
                name: 'Woman in motorized wheelchair',
                code: 'U+1F469 U+200D U+1F9BC'
            },
            {
                name: 'Person in manual wheelchair',
                code: 'U+1F9D1 U+200D U+1F9BD'
            },
            {
                name: 'Man in manual wheelchair',
                code: 'U+1F468 U+200D U+1F9BD'
            },
            {
                name: 'Woman in manual wheelchair',
                code: 'U+1F469 U+200D U+1F9BD'
            },
            {
                name: 'Man running',
                code: 'U+1F3C3 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman running',
                code: 'U+1F3C3 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Men with bunny ears',
                code: 'U+1F46F U+200D U+2642 U+FE0F'
            },
            {
                name: 'Women with bunny ears',
                code: 'U+1F46F U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man in steamy room',
                code: 'U+1F9D6 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman in steamy room',
                code: 'U+1F9D6 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man climbing',
                code: 'U+1F9D7 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman climbing',
                code: 'U+1F9D7 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Man golfing',
                code: 'U+1F3CC U+FE0F U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman golfing',
                code: 'U+1F3CC U+FE0F U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person climbing',
                code: 'U+1F9D7'
            },
            {
                name: 'Man climbing',
                code: 'U+200D'
            },
            {
                name: 'Woman climbing',
                code: 'U+2640'
            },
            {
                name: 'Person fencing',
                code: 'U+1F93A'
            },
            {
                name: 'Horse racing',
                code: 'U+1F3C7'
            },
            {
                name: 'Skier',
                code: 'U+26F7'
            },
            {
                name: 'Snowboarder',
                code: 'U+1F3C2'
            },
            {
                name: 'Person playing golf',
                code: 'U+1F3CC'
            },
            {
                name: 'Man playing golf',
                code: 'U+FE0F'
            },
            {
                name: 'Woman playing golf',
                code: 'U+200D'
            },
            {
                name: 'Person surfing',
                code: 'U+1F3C4'
            },
            {
                name: 'Man sufing',
                code: 'U+1F3C4 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman surfing',
                code: 'U+1F3C4 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person rowing boat',
                code: 'U+1F6A3'
            },
            {
                name: 'Man rowing boat',
                code: 'U+1F6A3 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman rowing boat',
                code: 'U+1F6A3 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person swimming',
                code: 'U+1F3CA'
            },
            {
                name: 'Man swimming',
                code: 'U+1F3CA U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman swimming',
                code: 'U+1F3CA U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person bouncing ball',
                code: 'U+26F9'
            },
            {
                name: 'Man bouncing ball',
                code: 'U+26F9 U+FE0F U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman bouncing ball',
                code: 'U+26F9 U+FE0F U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person lifting weight',
                code: 'U+1F3CB'
            },
            {
                name: 'Man lifting weights',
                code: 'U+1F3CB U+FE0F U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman lifting weights',
                code: 'U+1F3CB U+FE0F U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person cycling',
                code: 'U+1F6B4'
            },
            {
                name: 'Man cycling',
                code: 'U+1F6B4 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman cycling',
                code: 'U+1F6B4 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person mountain biking',
                code: 'U+1F6B5'
            },
            {
                name: 'Man mountain biking',
                code: 'U+1F6B5 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman mountain biking',
                code: 'U+1F6B5 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person cartwheeling',
                code: 'U+1F938'
            },
            {
                name: 'Man cartwheeling',
                code: 'U+1F938 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman cartwheeling',
                code: 'U+1F938 U+200D U+2640 U+FE0F'
            },
            {
                name: 'People wrestling',
                code: 'U+1F93C'
            },
            {
                name: 'Men wrestling',
                code: 'U+1F93C U+200D U+2642 U+FE0F'
            },
            {
                name: 'Women wrestling',
                code: 'U+1F93C U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person playing water polo',
                code: 'U+1F93D'
            },
            {
                name: 'Man playing water polo',
                code: 'U+1F93D U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman playing water polo',
                code: 'U+1F93D U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person playing handball',
                code: 'U+1F93E'
            },
            {
                name: 'Man playing handball',
                code: 'U+1F93E U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman playing handblall',
                code: 'U+1F93E U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person juggling',
                code: 'U+1F939'
            },
            {
                name: 'Man juggling',
                code: 'U+1F939 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman juggling',
                code: 'U+1F939 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person lotus position',
                code: 'U+1F9D8'
            },
            {
                name: 'Man in lotus position',
                code: 'U+1F9D8 U+200D U+2642 U+FE0F'
            },
            {
                name: 'Woman in lotus position',
                code: 'U+1F9D8 U+200D U+2640 U+FE0F'
            },
            {
                name: 'Person bathing',
                code: 'U+1F6C0'
            },
            {
                name: 'Person in bed',
                code: 'U+1F6CC'
            },
            {
                name: 'Family',
                code: 'U+1F46A'
            }
        ]
    },
    {
        group: 'Family Emojis',
        emojis: [
            {
                name: 'Family of man, woman and boy',
                code: 'U+1F468 U+200D U+1F469 U+200D U+1F466'
            },
            {
                name: 'Family of man, woman and girl',
                code: 'U+1F468 U+200D U+1F469 U+200D U+1F467'
            },
            {
                name: 'Family of man, woman, boy, and girl',
                code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466'
            },
            {
                name: 'Family of man, woman, boy, and boy',
                code: 'U+1F468 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466'
            },
            {
                name: 'Family of man, woman, girl, and girl',
                code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467'
            },
            {
                name: 'Family of man, man, and boy',
                code: 'U+1F468 U+200D U+1F468 U+200D U+1F466'
            },
            {
                name: 'Family of man, man, and girl',
                code: 'U+1F468 U+200D U+1F468 U+200D U+1F467'
            },
            {
                name: 'Family of man, man, girl, and boy',
                code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F466'
            },
            {
                name: 'Family of man, man, boy, and boy',
                code: 'U+1F468 U+200D U+1F468 U+200D U+1F466 U+200D U+1F466'
            },
            {
                name: 'Family of man, man, girl, and girl',
                code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F467'
            },
            {
                name: 'Family of woman, woman, and boy',
                code: 'U+1F469 U+200D U+1F469 U+200D U+1F466'
            },
            {
                name: 'Family of woman, woman, and girl',
                code: 'U+1F469 U+200D U+1F469 U+200D U+1F467'
            },
            {
                name: 'Family of woman, woman, girl, and boy',
                code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466'
            },
            {
                name: 'Family of woman, woman, boy, and boy',
                code: 'U+1F469 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466'
            },
            {
                name: 'Family of woman, woman, girl, and girl',
                code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467'
            },
            {
                name: 'Family of man and boy',
                code: 'U+1F468 U+200D U+1F466'
            },
            {
                name: 'Family of man, boy, and boy',
                code: 'U+1F468 U+200D U+1F466 U+200D U+1F466'
            },
            {
                name: 'Family of man and girl',
                code: 'U+1F468 U+200D U+1F467'
            },
            {
                name: 'Family of man, girl, and boy',
                code: 'U+1F468 U+200D U+1F467 U+200D U+1F466'
            },
            {
                name: 'Family of man, girl, and girl',
                code: 'U+1F468 U+200D U+1F467 U+200D U+1F467'
            },
            {
                name: 'Family of woman, and boy',
                code: 'U+1F469 U+200D U+1F466'
            },
            {
                name: 'Family of woman, boy, and boy',
                code: 'None'
            },
            {
                name: 'Family of woman, and girl',
                code: 'U+1F469 U+200D U+1F467'
            },
            {
                name: 'Family of woman, girl, and boy',
                code: 'U+1F469 U+200D U+1F467 U+200D U+1F466'
            },
            {
                name: 'Family of woman, girl, and girl',
                code: 'U+1F469 U+200D U+1F467 U+200D U+1F467'
            },
            {
                name: 'People holding hands',
                code: 'U+1F9D1 U+200D U+1F91D U+200D U+1F9D1'
            },
            {
                name: 'Women holding hands',
                code: 'U+1F46D'
            },
            {
                name: 'Woman and man holding hands',
                code: 'U+1F46B'
            },
            {
                name: 'Men holding hands',
                code: 'U+1F46C'
            },
            {
                name: 'Kiss',
                code: 'U+1F48F'
            },
            {
                name: 'Woman and man kissing',
                code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468'
            },
            {
                name: 'Man and man kissing',
                code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468'
            },
            {
                name: 'Woman and woman kissing',
                code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F469'
            },
            {
                name: 'Woman and man couple',
                code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F468'
            },
            {
                name: 'Man and man couple',
                code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F468'
            },
            {
                name: 'Woman and woman couple',
                code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F469'
            },
            {
                name: 'Couple with heart',
                code: 'U+1F491'
            },
            {
                name: 'Person speaking',
                code: 'U+1F5E3'
            },
            {
                name: 'Bust in silhouhette',
                code: 'U+1F464'
            },
            {
                name: 'Busts in silhouette',
                code: 'U+1F465'
            },
            {
                name: 'People hugging',
                code: 'U+1FAC2'
            },
            {
                name: 'Monkey face',
                code: 'U+1F435'
            }
        ]
    },
    {
        group: 'Animals and Nature Emojis',
        emojis: [
            {
                name: 'Monkey',
                code: 'U+1F412'
            },
            {
                name: 'Gorilla',
                code: 'U+1F98D'
            },
            {
                name: 'Orangutan',
                code: 'U+1F9A7'
            },
            {
                name: 'Dog face',
                code: 'U+1F436'
            },
            {
                name: 'Dog',
                code: 'U+1F415'
            },
            {
                name: 'Guide dog',
                code: 'U+1F9AE'
            },
            {
                name: 'Service dog',
                code: 'U+1F415 U+200D U+1F9BA'
            },
            {
                name: 'Poodle',
                code: 'U+1F429'
            },
            {
                name: 'Wolf',
                code: 'U+1F43A'
            },
            {
                name: 'Fox',
                code: 'U+1F98A'
            },
            {
                name: 'Racoon',
                code: 'U+1F99D'
            },
            {
                name: 'Cat face',
                code: 'U+1F431'
            },
            {
                name: 'Cat',
                code: 'U+1F408'
            },
            {
                name: 'Black Cat',
                code: 'U+1F408 U+200D U+2B1B'
            },
            {
                name: 'Lion',
                code: 'U+1F981'
            },
            {
                name: 'Tiger face',
                code: 'U+1F42F'
            },
            {
                name: 'Tiger',
                code: 'U+1F405'
            },
            {
                name: 'Leopard',
                code: 'U+1F406'
            },
            {
                name: 'Horse face',
                code: 'U+1F434'
            },
            {
                name: 'Horse',
                code: 'U+1F40E'
            },
            {
                name: 'Unicorn',
                code: 'U+1F984'
            },
            {
                name: 'Zebra',
                code: 'U+1F993'
            },
            {
                name: 'Deer',
                code: 'U+1F98C'
            },
            {
                name: 'Bison',
                code: 'U+1F9AC'
            },
            {
                name: 'Cow face',
                code: 'U+1F42E'
            },
            {
                name: 'Cow',
                code: 'U+1F404'
            },
            {
                name: 'Ox',
                code: 'U+1F402'
            },
            {
                name: 'Water buffalo',
                code: 'U+1F437'
            },
            {
                name: 'Pig face',
                code: 'U+1F437'
            },
            {
                name: 'Pig',
                code: 'U+1F416'
            },
            {
                name: 'Boar',
                code: 'U+1F417'
            },
            {
                name: 'Pig nose',
                code: 'U+1F43D'
            },
            {
                name: 'Ram',
                code: 'U+1F40F'
            },
            {
                name: 'Ewe',
                code: 'U+1F411'
            },
            {
                name: 'Goat',
                code: 'U+1F410'
            },
            {
                name: 'Camel',
                code: 'U+1F42A'
            },
            {
                name: 'Two hump camel',
                code: 'U+1F42B'
            },
            {
                name: 'Llama',
                code: 'U+1F999'
            },
            {
                name: 'Giraffe',
                code: 'U+1F992'
            },
            {
                name: 'Elephant',
                code: 'U+1F418'
            },
            {
                name: 'Mammoth',
                code: 'U+1F9A3'
            },
            {
                name: 'Rhinoceros',
                code: 'U+1F98F'
            },
            {
                name: 'Hippopotamus',
                code: 'U+1F99B'
            },
            {
                name: 'Mouse face',
                code: 'U+1F42D'
            },
            {
                name: 'Mouse',
                code: 'U+1F401'
            },
            {
                name: 'Rat',
                code: 'U+1F400'
            },
            {
                name: 'Hamster',
                code: 'U+1F439'
            },
            {
                name: 'Rabbit face',
                code: 'U+1F430'
            },
            {
                name: 'Rabbit',
                code: 'U+1F407'
            },
            {
                name: 'Chipmunk',
                code: 'U+1F43F'
            },
            {
                name: 'Beaver',
                code: 'U+1F9AB'
            },
            {
                name: 'Hedgehog',
                code: 'U+1F994'
            },
            {
                name: 'Bat',
                code: 'U+1F987'
            },
            {
                name: 'Bear',
                code: 'U+1F43B'
            },
            {
                name: 'Polar bear',
                code: 'U+1F43B U+200D U+2744 U+FE0F'
            },
            {
                name: 'Koala',
                code: 'U+1F428'
            },
            {
                name: 'Panda',
                code: 'U+1F43C'
            },
            {
                name: 'Sloth',
                code: 'U+1F9A5'
            },
            {
                name: 'Otter',
                code: 'U+1F9A6'
            },
            {
                name: 'Skunk',
                code: 'U+1F9A8'
            },
            {
                name: 'Kangaroo',
                code: 'U+1F998'
            },
            {
                name: 'Badger',
                code: 'U+1F9A1'
            },
            {
                name: 'Paw prints',
                code: 'U+1F43E'
            },
            {
                name: 'Turkey',
                code: 'U+1F983'
            },
            {
                name: 'Chicken',
                code: 'U+1F414'
            },
            {
                name: 'Rooster',
                code: 'U+1F413'
            },
            {
                name: 'Hatching',
                code: 'U+1F423'
            },
            {
                name: 'Baby chick',
                code: 'U+1F424'
            },
            {
                name: 'Front-facing chick',
                code: 'U+1F425'
            },
            {
                name: 'Bird',
                code: 'U+1F426'
            },
            {
                name: 'Black bird',
                code: 'U+1F426 U+200D U+2B1B'
            },
            {
                name: 'Penguin',
                code: 'U+1F427'
            },
            {
                name: 'Dove',
                code: 'U+1F54A'
            },
            {
                name: 'Eagle',
                code: 'U+1F985'
            },
            {
                name: 'Duck',
                code: 'U+1F986'
            },
            {
                name: 'Swan',
                code: 'U+1F9A2'
            },
            {
                name: 'Owl',
                code: 'U+1F989'
            },
            {
                name: 'Dodo',
                code: 'U+1F9A4'
            },
            {
                name: 'Feather',
                code: 'U+1FAB6'
            },
            {
                name: 'Flamingo',
                code: 'U+1F9A9'
            },
            {
                name: 'Peacock',
                code: 'U+1F99C'
            },
            {
                name: 'Frog',
                code: 'U+1F438'
            },
            {
                name: 'Crocodile',
                code: 'U+1F40A'
            },
            {
                name: 'Turtle',
                code: 'U+1F422'
            },
            {
                name: 'Lizard',
                code: 'U+1F98E'
            },
            {
                name: 'Snake',
                code: 'U+1F40D'
            },
            {
                name: 'Dragon face',
                code: 'U+1F432'
            },
            {
                name: 'Dragon',
                code: 'U+1F409'
            },
            {
                name: 'Sauropod',
                code: 'U+1F995'
            },
            {
                name: 'Tyrannosaurus',
                code: 'U+1F996'
            },
            {
                name: 'Spouting whale',
                code: 'U+1F433'
            },
            {
                name: 'Whale',
                code: 'U+1F40B'
            },
            {
                name: 'Dolphin',
                code: 'U+1F42C'
            },
            {
                name: 'Seal',
                code: 'U+1F9AD'
            },
            {
                name: 'Fish',
                code: 'U+1F41F'
            },
            {
                name: 'Tropical fish',
                code: 'U+1F420'
            },
            {
                name: 'Blowfish',
                code: 'U+1F421'
            },
            {
                name: 'Shark',
                code: 'U+1F988'
            },
            {
                name: 'Octopus',
                code: 'U+1F419'
            },
            {
                name: 'Spiral shell',
                code: 'U+1F41A'
            },
            {
                name: 'Snail',
                code: 'U+1F40C'
            },
            {
                name: 'Butterfly',
                code: 'U+1F98B'
            },
            {
                name: 'Bug',
                code: 'U+1F41B'
            },
            {
                name: 'Ant',
                code: 'U+1F41C'
            },
            {
                name: 'Honeybee',
                code: 'U+1F41D'
            },
            {
                name: 'Beetle',
                code: 'U+1FAB2'
            },
            {
                name: 'Lady Beetle',
                code: 'U+1F41E'
            },
            {
                name: 'Cricket',
                code: 'U+1F997'
            },
            {
                name: 'Cockroach',
                code: 'U+1FAB3'
            },
            {
                name: 'Spider',
                code: 'U+1F577'
            },
            {
                name: 'Spider web',
                code: 'U+1F578'
            },
            {
                name: 'Scorpion',
                code: 'U+1F982'
            },
            {
                name: 'Mosquito',
                code: 'U+1F99F'
            },
            {
                name: 'Fly',
                code: 'U+1FAB0'
            },
            {
                name: 'Worm',
                code: 'U+1FAB1'
            },
            {
                name: 'Microbe',
                code: 'U+1F9A0'
            },
            {
                name: 'Bouquet',
                code: 'U+1F490'
            },
            {
                name: 'Cherry blossom',
                code: 'U+1F338'
            },
            {
                name: 'White flower',
                code: 'U+1F4AE'
            },
            {
                name: 'Rosette',
                code: 'U+1F3F5'
            },
            {
                name: 'Rose',
                code: 'U+1F339'
            },
            {
                name: 'Wilted flower',
                code: 'U+1F940'
            },
            {
                name: 'Hibiscus',
                code: 'U+1F33A'
            },
            {
                name: 'Sunflower',
                code: 'U+1F33B'
            },
            {
                name: 'Blossom',
                code: 'U+1F33C'
            },
            {
                name: 'Tulip',
                code: 'U+1F337'
            },
            {
                name: 'Seedling',
                code: 'U+1F331'
            },
            {
                name: 'Potted plant',
                code: 'U+1FAB4'
            },
            {
                name: 'Evergreen tree',
                code: 'U+1F332'
            },
            {
                name: 'Deciduous plant',
                code: 'U+1F333'
            },
            {
                name: 'Palm tree',
                code: 'U+1F334'
            },
            {
                name: 'Cactus',
                code: 'U+1F335'
            },
            {
                name: 'Sheaf of rice',
                code: 'U+1F33E'
            },
            {
                name: 'Herb',
                code: 'U+1F33F'
            },
            {
                name: 'Shamrock',
                code: 'U+2618'
            },
            {
                name: 'Four leaf clover',
                code: 'U+1F340'
            },
            {
                name: 'Maple leaf',
                code: 'U+1F341'
            },
            {
                name: 'Fallen leaf',
                code: 'U+1F342'
            },
            {
                name: 'Leaf fluttering in wind',
                code: 'U+1F343'
            },
            {
                name: 'Empty nest',
                code: 'U+1FAB9'
            },
            {
                name: 'Nest with eggs',
                code: 'U+1FABA'
            },
            {
                name: 'Grapes',
                code: 'U+1F347'
            }
        ]
    },
    {
        group: 'Food and Drinks Emojis',
        emojis: [
            {
                name: 'Melon',
                code: 'U+1F348'
            },
            {
                name: 'Water melon',
                code: 'U+1F349'
            },
            {
                name: 'Tangerine',
                code: 'U+1F34A'
            },
            {
                name: 'Lime',
                code: 'U+1F34B'
            },
            {
                name: 'Banana',
                code: 'U+1F34C'
            },
            {
                name: 'Pineapple',
                code: 'U+1F34D'
            },
            {
                name: 'Mango',
                code: 'U+1F96D'
            },
            {
                name: 'Red apple',
                code: 'U+1F34E'
            },
            {
                name: 'Green apple',
                code: 'U+1F34F'
            },
            {
                name: 'Pear',
                code: 'U+1F350'
            },
            {
                name: 'Peach',
                code: 'U+1F351'
            },
            {
                name: 'Cherries',
                code: 'U+1F352'
            },
            {
                name: 'Strawberries',
                code: 'U+1F353'
            },
            {
                name: 'Blueberries',
                code: 'U+1FAD0'
            },
            {
                name: 'Kiwi fruit',
                code: 'U+1F95D'
            },
            {
                name: 'Tomato',
                code: 'U+1F345'
            },
            {
                name: 'Olive',
                code: 'U+1FAD2'
            },
            {
                name: 'Coconut',
                code: 'U+1F965'
            },
            {
                name: 'Avocado',
                code: 'U+1F951'
            },
            {
                name: 'Eggplant',
                code: 'U+1F346'
            },
            {
                name: 'Potato',
                code: 'U+1F954'
            },
            {
                name: 'Carrot',
                code: 'U+1F955'
            },
            {
                name: 'Corn',
                code: 'U+1F33D'
            },
            {
                name: 'Pepper',
                code: 'U+1F336'
            },
            {
                name: 'Bell pepper',
                code: 'U+1FAD1'
            },
            {
                name: 'Cucumber',
                code: 'U+1F952'
            },
            {
                name: 'Leafy green',
                code: 'U+1F96C'
            },
            {
                name: 'Broccoli',
                code: 'U+1F966'
            },
            {
                name: 'Garlic',
                code: 'U+1F9C4'
            },
            {
                name: 'Onion',
                code: 'U+1F9C5'
            },
            {
                name: 'Mushroom',
                code: 'U+1F344'
            },
            {
                name: 'Peanuts',
                code: 'U+1F95C'
            },
            {
                name: 'Beans',
                code: 'U+1FAD8'
            },
            {
                name: 'Chestnut',
                code: 'U+1F330'
            },
            {
                name: 'Bread',
                code: 'U+1F35E'
            },
            {
                name: 'Croissant',
                code: 'U+1F950'
            },
            {
                name: 'Baguette bread',
                code: 'U+1F956'
            },
            {
                name: 'Flat bread',
                code: 'U+1FAD3'
            },
            {
                name: 'Pretzel',
                code: 'U+1F968'
            },
            {
                name: 'Bagel',
                code: 'U+1F96F'
            },
            {
                name: 'Pancake',
                code: 'U+1F95E'
            },
            {
                name: 'Waffle',
                code: 'U+1F9C7'
            },
            {
                name: 'Cheese wedge',
                code: 'U+1F9C0'
            },
            {
                name: 'Meat with bone',
                code: 'U+1F356'
            },
            {
                name: 'Poultry leg',
                code: 'U+1F357'
            },
            {
                name: 'Cut of meat',
                code: 'U+1F969'
            },
            {
                name: 'Bacon',
                code: 'U+1F953'
            },
            {
                name: 'Hamburger',
                code: 'U+1F354'
            },
            {
                name: 'French fries',
                code: 'U+1F35F'
            },
            {
                name: 'Pizza',
                code: 'U+1F355'
            },
            {
                name: 'Hot dog',
                code: 'U+1F32D'
            },
            {
                name: 'Sandwich',
                code: 'U+1F96A'
            },
            {
                name: 'Taco',
                code: 'U+1F32E'
            },
            {
                name: 'Burrito',
                code: 'U+1F32F'
            },
            {
                name: 'Tamale',
                code: 'U+1FAD4'
            },
            {
                name: 'Stuffed flatbread',
                code: 'U+1F959'
            },
            {
                name: 'Falafel',
                code: 'U+1F9C6'
            },
            {
                name: 'Egg',
                code: 'U+1F95A'
            },
            {
                name: 'Cooking',
                code: 'U+1F373'
            },
            {
                name: 'Shallow pan of food',
                code: 'U+1F958'
            },
            {
                name: 'Pot of food',
                code: 'U+1F372'
            },
            {
                name: 'Fondue',
                code: 'U+1FAD5'
            },
            {
                name: 'Bowl with food',
                code: 'U+1F963'
            },
            {
                name: 'Green salad',
                code: 'U+1F957'
            },
            {
                name: 'Popcorn',
                code: 'U+1F37F'
            },
            {
                name: 'Butter',
                code: 'U+1F9C8'
            },
            {
                name: 'Salt',
                code: 'U+1F9C2'
            },
            {
                name: 'Canned food',
                code: 'U+1F96B'
            },
            {
                name: 'Bento box',
                code: 'U+1F371'
            },
            {
                name: 'RIce cracker',
                code: 'U+1F358'
            },
            {
                name: 'Rice ball',
                code: 'U+1F359'
            },
            {
                name: 'Cooked rice',
                code: 'U+1F35A'
            },
            {
                name: 'Curry rice',
                code: 'U+1F35B'
            },
            {
                name: 'Steaming bowl',
                code: 'U+1F35C'
            },
            {
                name: 'Spaghetti',
                code: 'U+1F35D'
            },
            {
                name: 'Roasted sweet potato',
                code: 'U+1F360'
            },
            {
                name: 'Oden',
                code: 'U+1F362'
            },
            {
                name: 'Sushi',
                code: 'U+1F363'
            },
            {
                name: 'Fried shrimp',
                code: 'U+1F364'
            },
            {
                name: 'Fish cake with swiri',
                code: 'U+1F365'
            },
            {
                name: 'Moon cake',
                code: 'U+1F96E'
            },
            {
                name: 'Dango',
                code: 'U+1F361'
            },
            {
                name: 'Dumpling',
                code: 'U+1F95F'
            },
            {
                name: 'Fortune cookie',
                code: 'U+1F960'
            },
            {
                name: 'Take out box',
                code: 'U+1F961'
            },
            {
                name: 'Crab',
                code: 'U+1F980'
            },
            {
                name: 'Lobster',
                code: 'U+1F99E'
            },
            {
                name: 'Shrimp',
                code: 'U+1F990'
            },
            {
                name: 'Squid',
                code: 'U+1F991'
            },
            {
                name: 'Oyster',
                code: 'U+1F9AA'
            },
            {
                name: 'Ice cream',
                code: 'U+1F368'
            },
            {
                name: 'Shaved ice cream',
                code: 'U+1F367'
            },
            {
                name: 'Soft ice cream',
                code: 'U+1F366'
            },
            {
                name: 'Doughnut',
                code: 'U+1F369'
            },
            {
                name: 'Cookie',
                code: 'U+1F36A'
            },
            {
                name: 'Birthday cake',
                code: 'U+1F382'
            },
            {
                name: 'Short cake',
                code: 'U+1F370'
            },
            {
                name: 'Cup cake',
                code: 'U+1F9C1'
            },
            {
                name: 'Pie',
                code: 'U+1F967'
            },
            {
                name: 'Chocolate',
                code: 'U+1F36B'
            },
            {
                name: 'Candy',
                code: 'U+1F36C'
            },
            {
                name: 'Lollipop',
                code: 'U+1F36D'
            },
            {
                name: 'Custard',
                code: 'U+1F36E'
            },
            {
                name: 'Honey pot',
                code: 'U+1F36F'
            },
            {
                name: 'Baby bottle',
                code: 'U+1F37C'
            },
            {
                name: 'Glass of milk',
                code: 'U+1F95B'
            },
            {
                name: 'Hot beverage',
                code: 'U+2615'
            },
            {
                name: 'Teapot',
                code: 'U+1FAD6'
            },
            {
                name: 'Teacup without handle',
                code: 'U+1F375'
            },
            {
                name: 'Sake',
                code: 'U+1F376'
            },
            {
                name: 'Bottle with poppin cork',
                code: 'U+1F37E'
            },
            {
                name: 'Wine glass',
                code: 'U+1F377'
            },
            {
                name: 'Cocktail glass',
                code: 'U+1F378'
            },
            {
                name: 'Tropical drink',
                code: 'U+1F379'
            },
            {
                name: 'Beer mug',
                code: 'U+1F37A'
            },
            {
                name: 'Clinking beer mug',
                code: 'U+1F37B'
            },
            {
                name: 'Clinking glasses',
                code: 'U+1F942'
            },
            {
                name: 'Tumbler glass',
                code: 'U+1F943'
            },
            {
                name: 'Cup with strawberry',
                code: 'U+1F964'
            },
            {
                name: 'Bubble tea',
                code: 'U+1F9CB'
            },
            {
                name: 'Beverage box',
                code: 'U+1F9C3'
            },
            {
                name: 'Mate drink',
                code: 'U+1F9C9'
            },
            {
                name: 'Ice',
                code: 'U+1F9CA'
            },
            {
                name: 'Chopsticks',
                code: 'U+1F962'
            },
            {
                name: 'Fork and knife with plate',
                code: 'U+1F37D'
            },
            {
                name: 'Fork and knife',
                code: 'U+1F374'
            },
            {
                name: 'Spoon',
                code: 'U+1F944'
            },
            {
                name: 'Kitchen knife',
                code: 'U+1F52A'
            },
            {
                name: 'Jar',
                code: 'U+1FAD9'
            },
            {
                name: 'Amphora',
                code: 'U+1F3FA'
            },
            {
                name: 'Globe showing Africa and Europe',
                code: 'U+1F30D'
            }
        ]
    },
    {
        group: 'Travel and Places Emojis',
        emojis: [
            {
                name: 'Globe showing Americas',
                code: 'U+1F30E'
            },
            {
                name: 'Globe showing Asia and Australia',
                code: 'U+1F30F'
            },
            {
                name: 'Globe with meridians',
                code: 'U+1F310'
            },
            {
                name: 'World map',
                code: 'U+1F5FA'
            },
            {
                name: 'Compass',
                code: 'U+1F9ED'
            },
            {
                name: 'Mountain',
                code: 'U+26F0'
            },
            {
                name: 'Snowcap mountain',
                code: 'U+26F0'
            },
            {
                name: 'Volcanic mountain',
                code: 'U+1F30B'
            },
            {
                name: 'Fuji mountain',
                code: 'U+1F5FB'
            },
            {
                name: 'Camping',
                code: 'U+1F3D5'
            },
            {
                name: 'Beach with umbrella',
                code: 'U+1F3D6'
            },
            {
                name: 'Desert',
                code: 'U+1F3DC'
            },
            {
                name: 'Desertified island',
                code: 'U+1F3DD'
            },
            {
                name: 'National park',
                code: 'U+1F3DE'
            },
            {
                name: 'Stadium',
                code: 'U+1F3DF'
            },
            {
                name: 'Classical building',
                code: 'U+1F3DB'
            },
            {
                name: 'Building construction',
                code: 'U+1F3D7'
            },
            {
                name: 'Brick',
                code: 'U+1F9F1'
            },
            {
                name: 'Rock',
                code: 'U+1FAA8'
            },
            {
                name: 'Wood',
                code: 'U+1FAB5'
            },
            {
                name: 'Hut',
                code: 'U+1F6D6'
            },
            {
                name: 'Houses',
                code: 'U+1F3D8'
            },
            {
                name: 'Derelict house',
                code: 'U+1F3DA'
            },
            {
                name: 'House',
                code: 'U+1F3E0'
            },
            {
                name: 'House with garden',
                code: 'U+1F3E1'
            },
            {
                name: 'Office building',
                code: 'U+1F3E2'
            },
            {
                name: 'Japanese office',
                code: 'U+1F3E3'
            },
            {
                name: 'Post office',
                code: 'U+1F3E4'
            },
            {
                name: 'Hospital',
                code: 'U+1F3E5'
            },
            {
                name: 'Bank',
                code: 'U+1F3E6'
            },
            {
                name: 'Hotel',
                code: 'U+1F3E8'
            },
            {
                name: 'Love hotel',
                code: 'U+1F3E9'
            },
            {
                name: 'Convenience store',
                code: 'U+1F3EA'
            },
            {
                name: 'School',
                code: 'U+1F3EB'
            },
            {
                name: 'Department',
                code: 'U+1F3EC'
            },
            {
                name: 'Factory',
                code: 'U+1F3ED'
            },
            {
                name: 'Japanese castle',
                code: 'U+1F3EF'
            },
            {
                name: 'Castle',
                code: 'U+1F3F0'
            },
            {
                name: 'Wedding house',
                code: 'U+1F492'
            },
            {
                name: 'Tokyo tower',
                code: 'U+1F5FC'
            },
            {
                name: 'Statue of liberty',
                code: 'U+1F5FD'
            },
            {
                name: 'Church',
                code: 'U+26EA'
            },
            {
                name: 'Mosque',
                code: 'U+1F54C'
            },
            {
                name: 'Hindu temple',
                code: 'U+1F6D5'
            },
            {
                name: 'Synagogue',
                code: 'U+1F54D'
            },
            {
                name: 'Shinto shrine',
                code: 'U+26E9'
            },
            {
                name: 'Kaaba',
                code: 'U+1F54B'
            },
            {
                name: 'Fountain',
                code: 'U+26F2'
            },
            {
                name: 'Tent',
                code: 'U+26FA'
            },
            {
                name: 'Foggy',
                code: 'U+1F301'
            },
            {
                name: 'Night with starrs',
                code: 'U+1F303'
            },
            {
                name: 'Citscape',
                code: 'U+1F3D9'
            },
            {
                name: 'Sunrise',
                code: 'U+1F305'
            },
            {
                name: 'Sunrise over mountains',
                code: 'U+1F304'
            },
            {
                name: 'Cityscape at dusk',
                code: 'U+1F306'
            },
            {
                name: 'Sunset',
                code: 'U+1F307'
            },
            {
                name: 'Bridge at night',
                code: 'U+1F309'
            },
            {
                name: 'Hot springs',
                code: 'U+2668'
            },
            {
                name: 'Carousel horse',
                code: 'U+1F3A0'
            },
            {
                name: 'Ferris wheel',
                code: 'U+1F3A1'
            },
            {
                name: 'Roller coaster',
                code: 'U+1F3A2'
            },
            {
                name: 'Barber poll',
                code: 'U+1F488'
            },
            {
                name: 'Circus tent',
                code: 'U+1F3AA'
            }
        ]
    },
    {
        group: 'Transport Emojis',
        emojis: [
            {
                name: 'Locomotive',
                code: 'U+1F682'
            },
            {
                name: 'Railway car',
                code: 'U+1F683'
            },
            {
                name: 'High speed train',
                code: 'U+1F684'
            },
            {
                name: 'Bullet train',
                code: 'U+1F685'
            },
            {
                name: 'Train',
                code: 'U+1F686'
            },
            {
                name: 'Metro',
                code: 'U+1F687'
            },
            {
                name: 'Light rail',
                code: 'U+1F688'
            },
            {
                name: 'Station',
                code: 'U+1F689'
            },
            {
                name: 'Tram',
                code: 'U+1F68A'
            },
            {
                name: 'Monorail',
                code: 'U+1F69D'
            },
            {
                name: 'Mountain railway',
                code: 'U+1F69E'
            },
            {
                name: 'Tram car',
                code: 'U+1F68B'
            },
            {
                name: 'us',
                code: 'U+1F68C'
            },
            {
                name: 'Oncoming bus',
                code: 'U+1F68D'
            },
            {
                name: 'Trolley bus',
                code: 'U+1F68E'
            },
            {
                name: 'Minibus',
                code: 'U+1F690'
            },
            {
                name: 'Ambulance',
                code: 'U+1F691'
            },
            {
                name: 'Fire engine',
                code: 'U+1F692'
            },
            {
                name: 'Police car',
                code: 'U+1F693'
            },
            {
                name: 'Oncoming police car',
                code: 'U+1F694'
            },
            {
                name: 'Taxi',
                code: 'U+1F695'
            },
            {
                name: 'Oncoming taxi',
                code: 'U+1F696'
            },
            {
                name: 'Automobile',
                code: 'U+1F697'
            },
            {
                name: 'Oncoming automobile',
                code: 'U+1F698'
            },
            {
                name: 'Sport utility vehicle',
                code: 'U+1F699'
            },
            {
                name: 'Pickup truck',
                code: 'U+1F6FB'
            },
            {
                name: 'Delivery truck',
                code: 'U+1F69A'
            },
            {
                name: 'Articulated lorry',
                code: 'U+1F69B'
            },
            {
                name: 'Tractor',
                code: 'U+1F69C'
            },
            {
                name: 'Racing car',
                code: 'U+1F3CE'
            },
            {
                name: 'Motorcycle',
                code: 'U+1F3CD'
            },
            {
                name: 'Scooter',
                code: 'U+1F6F5'
            },
            {
                name: 'Manual wheelchair',
                code: 'U+1F9BD'
            },
            {
                name: 'Motorized wheelchair',
                code: 'U+1F9BC'
            }
        ]
    },
    {
        group: 'Time Emojis',
        emojis: [
            {
                name: 'Hourglass done',
                code: 'U+231B'
            },
            {
                name: 'Hourglass starting',
                code: 'U+23F3'
            },
            {
                name: 'Watch',
                code: 'U+231A'
            },
            {
                name: 'Alarm',
                code: 'U+23F0'
            },
            {
                name: 'Stopwatch',
                code: 'U+23F1'
            },
            {
                name: 'Timer clock',
                code: 'U+23F2'
            },
            {
                name: 'Mantelpiece clock',
                code: 'U+1F570'
            },
            {
                name: 'Twelve O\'clock',
                code: 'U+1F55B'
            },
            {
                name: 'Twelve-thirty',
                code: 'U+1F567'
            },
            {
                name: 'One O\'clock',
                code: 'U+1F550'
            },
            {
                name: 'One-thirty',
                code: 'U+1F55C'
            },
            {
                name: 'Two O\'clock',
                code: 'U+1F551'
            },
            {
                name: 'Two-thirty',
                code: 'U+1F55D'
            },
            {
                name: 'Three O\'clock',
                code: 'U+1F552'
            },
            {
                name: 'Three-thirty',
                code: 'U+1F55E'
            },
            {
                name: 'Four O\'clock',
                code: 'U+1F553'
            },
            {
                name: 'Four-thirty',
                code: 'U+1F55F'
            },
            {
                name: 'Five O\'clock',
                code: 'U+1F554'
            },
            {
                name: 'Five-thirty',
                code: 'U+1F560'
            },
            {
                name: 'Six O\'clock',
                code: 'U+1F555'
            },
            {
                name: 'Six-thirty',
                code: 'U+1F561'
            },
            {
                name: 'Seven O\'clock',
                code: 'U+1F556'
            },
            {
                name: 'Seven-thirty',
                code: 'U+1F562'
            },
            {
                name: 'Eight O\'clock',
                code: 'U+1F557'
            },
            {
                name: 'Eight-thirty',
                code: 'U+1F563'
            },
            {
                name: 'Nine O\'clock',
                code: 'U+1F558'
            },
            {
                name: 'Nine-thirty',
                code: 'U+1F564'
            },
            {
                name: 'Ten O\'clock',
                code: 'U+1F559'
            },
            {
                name: 'Ten-thirty',
                code: 'U+1F565'
            },
            {
                name: 'Eleven O\'clock',
                code: 'U+1F55A'
            },
            {
                name: 'Eleven-thirty',
                code: 'U+1F566'
            }
        ]
    },
    {
        group: 'Sky and Weather Emojis',
        emojis: [
            {
                name: 'New moon',
                code: 'U+1F311'
            },
            {
                name: 'Waxing crescent moon',
                code: 'U+1F312'
            },
            {
                name: 'First quarter moon',
                code: 'U+1F313'
            },
            {
                name: 'Waxing gibbous moon',
                code: 'U+1F314'
            },
            {
                name: 'Full moon',
                code: 'U+1F315'
            },
            {
                name: 'Waning gibbous moon',
                code: 'U+1F316'
            },
            {
                name: 'Last quarter moon',
                code: 'U+1F317'
            },
            {
                name: 'Waning crescent moon',
                code: 'U+1F318'
            },
            {
                name: 'Crescent moon',
                code: 'U+1F319'
            },
            {
                name: 'New moon face',
                code: 'U+1F31A'
            },
            {
                name: 'First quarter moon face',
                code: 'U+1F31B'
            },
            {
                name: 'Last quartermoon face',
                code: 'U+1F31C'
            },
            {
                name: 'Thermometer',
                code: 'U+1F321'
            },
            {
                name: 'Sun',
                code: 'U+2600'
            },
            {
                name: 'Full moon face',
                code: 'U+1F31D'
            },
            {
                name: 'Sun with face',
                code: 'U+1F31E'
            },
            {
                name: 'Ringed planet',
                code: 'U+1FA90'
            },
            {
                name: 'Star',
                code: 'U+2B50'
            },
            {
                name: 'Glowing star',
                code: 'U+1F31F'
            },
            {
                name: 'Shooting star',
                code: 'U+1F320'
            },
            {
                name: 'Milky way',
                code: 'U+1F30C'
            },
            {
                name: 'Cloud',
                code: 'U+2601'
            },
            {
                name: 'Sun behind cloud',
                code: 'U+26C5'
            },
            {
                name: 'Cloud with lighting and rain',
                code: 'U+26C8'
            },
            {
                name: 'Sun behind small cloud',
                code: 'U+1F324'
            },
            {
                name: 'Sun behind large cloud',
                code: 'U+1F325'
            },
            {
                name: 'Sun behind rain cloud',
                code: 'U+1F326'
            },
            {
                name: 'Cloud with rain',
                code: 'U+1F327'
            },
            {
                name: 'Cloud with snow',
                code: 'U+1F328'
            },
            {
                name: 'Cloud with lighting',
                code: 'U+1005'
            },
            {
                name: 'Tornado',
                code: 'U+1F32A'
            },
            {
                name: 'Fog',
                code: 'U+1F32B'
            },
            {
                name: 'Wind face',
                code: 'U+1F32C'
            },
            {
                name: 'Cyclone',
                code: 'U+1F300'
            },
            {
                name: 'Rainbow',
                code: 'U+1F308'
            },
            {
                name: 'Closed umbrella',
                code: 'U+1F302'
            },
            {
                name: 'Umbrella',
                code: 'U+2602'
            },
            {
                name: 'Umbrella with raindrops',
                code: 'U+2614'
            },
            {
                name: 'Umbrella on ground',
                code: 'U+26F1'
            },
            {
                name: 'High voltage',
                code: 'U+26A1'
            },
            {
                name: 'Snowflake',
                code: 'U+2744'
            },
            {
                name: 'Snowman',
                code: 'U+2603'
            },
            {
                name: 'Snowman without snow',
                code: 'U+26C4'
            },
            {
                name: 'Comet',
                code: 'U+2604'
            },
            {
                name: 'Fire',
                code: 'U+1F525'
            },
            {
                name: 'Droplet',
                code: 'U+1F4A7'
            },
            {
                name: 'Water wave',
                code: 'U+1F30A'
            }
        ]
    },
    {
        group: 'Activity Emojis',
        emojis: [
            {
                name: 'Jack-o-lantern',
                code: 'U+1F383'
            },
            {
                name: 'Christmas tree',
                code: 'U+1F384'
            },
            {
                name: 'Fireworks',
                code: 'U+1F386'
            },
            {
                name: 'Sparkler',
                code: 'U+1F387'
            },
            {
                name: 'Firecracker',
                code: 'U+1F9E8'
            },
            {
                name: 'Sparkles',
                code: 'U+2728'
            },
            {
                name: 'Baloon',
                code: 'U+1F388'
            },
            {
                name: 'Party popper',
                code: 'U+1F389'
            },
            {
                name: 'Confetti ball',
                code: 'U+1F38A'
            },
            {
                name: 'Tanabata tree',
                code: 'U+1F38B'
            },
            {
                name: 'Pine decoration',
                code: 'U+1F38D'
            },
            {
                name: 'Japanese dolls',
                code: 'U+1F38E'
            },
            {
                name: 'Carp streamer',
                code: 'U+1F38F'
            },
            {
                name: 'Moon viewing ceremony',
                code: 'U+1F391'
            },
            {
                name: 'Red envelope',
                code: 'U+1F9E7'
            },
            {
                name: 'Ribbon',
                code: 'U+1F380'
            },
            {
                name: 'Wrapped gift',
                code: 'U+1F381'
            },
            {
                name: 'Reminder ribbon',
                code: 'U+1F397'
            },
            {
                name: 'Admission ticket',
                code: 'U+1F39F'
            },
            {
                name: 'Ticket',
                code: 'U+1F3AB'
            }
        ]
    },
    {
        group: 'Award Medals Emojis',
        emojis: [
            {
                name: 'Military medal',
                code: 'U+1F396'
            },
            {
                name: 'Trophy',
                code: 'U+1F3C6'
            },
            {
                name: 'Sports medal',
                code: 'U+1F3C5'
            },
            {
                name: 'Gold medal - first position',
                code: 'U+1F947'
            },
            {
                name: 'Silver medal - second position',
                code: 'U+1F948'
            },
            {
                name: 'Bronze medal - third position',
                code: 'U+1F949'
            }
        ]
    },
    {
        group: 'Sport Emojis',
        emojis: [
            {
                name: 'Soccer ball',
                code: 'U+26BD'
            },
            {
                name: 'Baseball',
                code: 'U+26BE'
            },
            {
                name: 'Softball',
                code: 'U+1F94E'
            },
            {
                name: 'Basketball',
                code: 'U+1F3C0'
            },
            {
                name: 'Volleyball',
                code: 'U+1F3D0'
            },
            {
                name: 'American football',
                code: 'U+1F3C8'
            },
            {
                name: 'Rugby',
                code: 'U+1F3C9'
            },
            {
                name: 'Tennis',
                code: 'U+1F3BE'
            },
            {
                name: 'Flying disk',
                code: 'U+1F94F'
            },
            {
                name: 'Bowling',
                code: 'U+1F3B3'
            },
            {
                name: 'Cricket',
                code: 'U+1F3CF'
            },
            {
                name: 'Field hockey',
                code: 'U+1F3D1'
            },
            {
                name: 'Ice hockey',
                code: 'U+1F3D2'
            },
            {
                name: 'Lacrosse',
                code: 'U+1F94D'
            },
            {
                name: 'Ping pong',
                code: 'U+1F3D3'
            },
            {
                name: 'Badminton',
                code: 'U+1F3F8'
            },
            {
                name: 'Boxing glove',
                code: 'U+1F94A'
            },
            {
                name: 'Martial arts uniform',
                code: 'U+1F94B'
            },
            {
                name: 'Goal net',
                code: 'U+1F945'
            },
            {
                name: 'Flag in a hole',
                code: 'U+26F3'
            },
            {
                name: 'Ice skate',
                code: 'U+26F8'
            },
            {
                name: 'Fishing pole',
                code: 'U+1F3A3'
            },
            {
                name: 'Diving mask',
                code: 'U+1F93F'
            },
            {
                name: 'Running shirt',
                code: 'U+1F3BD'
            },
            {
                name: 'Skis',
                code: 'U+1F3BF'
            },
            {
                name: 'Sled',
                code: 'U+1F6F7'
            },
            {
                name: 'Curling stone',
                code: 'U+1F94C'
            }
        ]
    },
    {
        group: 'Games Emojis',
        emojis: [
            {
                name: 'Bullseye',
                code: 'U+1F3AF'
            },
            {
                name: 'Yo-yo',
                code: 'U+1FA80'
            },
            {
                name: 'Kite',
                code: 'U+1FA81'
            },
            {
                name: '8 ball',
                code: 'U+1F3B1'
            },
            {
                name: 'Crystal ball',
                code: 'U+1F52E'
            },
            {
                name: 'Magic wand',
                code: 'U+1FA84'
            },
            {
                name: 'Nazar amulet',
                code: 'U+1F9FF'
            },
            {
                name: 'Hamsa',
                code: 'U+1FAAC'
            },
            {
                name: 'Video game pad',
                code: 'U+1F3AE'
            },
            {
                name: 'Joystick',
                code: 'U+1F579'
            },
            {
                name: 'Slot machine',
                code: 'U+1F3B0'
            },
            {
                name: 'Game die',
                code: 'U+1F3B2'
            },
            {
                name: 'Puzzle piece',
                code: 'U+1F9E9'
            },
            {
                name: 'Teddy bear',
                code: 'U+1F9F8'
            },
            {
                name: 'Piñata',
                code: 'U+1FA85'
            },
            {
                name: 'Nesting doll',
                code: 'U+1FA86'
            },
            {
                name: 'Spade suit',
                code: 'U+2660'
            },
            {
                name: 'Heart suit',
                code: 'U+2665'
            },
            {
                name: 'Club suit',
                code: 'U+2663'
            },
            {
                name: 'Chess pawn',
                code: 'U+265F'
            },
            {
                name: 'Joker',
                code: 'U+1F0CF'
            },
            {
                name: 'Mahjong red dragon',
                code: 'U+1F004'
            },
            {
                name: 'Flower playing cards',
                code: 'U+1F3B4'
            }
        ]
    },
    {
        group: 'Arts and Crafts Emojis',
        emojis: [
            {
                name: 'Performing arts',
                code: 'U+1F3AD'
            },
            {
                name: 'Framed picture',
                code: 'U+1F5BC'
            },
            {
                name: 'Artist palette',
                code: 'U+1F3A8'
            },
            {
                name: 'Thread',
                code: 'U+1F9F5'
            },
            {
                name: 'Sewing needle with thread',
                code: 'U+1FAA1'
            },
            {
                name: 'Yarn',
                code: 'U+1F9F6'
            },
            {
                name: 'Knot',
                code: 'U+1FAA2'
            }
        ]
    },
    {
        group: 'Clothing Objects Emojis',
        emojis: [
            {
                name: 'Glasses',
                code: 'U+1F453'
            },
            {
                name: 'Sunglasses',
                code: 'U+1F576'
            },
            {
                name: 'Goggles',
                code: 'U+1F97D'
            },
            {
                name: 'Lab coat',
                code: 'U+1F97C'
            },
            {
                name: 'Safety vest',
                code: 'U+1F9BA'
            },
            {
                name: 'Necktie',
                code: 'U+1F454'
            },
            {
                name: 'T-shirt',
                code: 'U+1F455'
            },
            {
                name: 'Jeans',
                code: 'U+1F456'
            },
            {
                name: 'Scarf',
                code: 'U+1F9E3'
            },
            {
                name: 'Gloves',
                code: 'U+1F9E4'
            },
            {
                name: 'Coat',
                code: 'U+1F9E5'
            },
            {
                name: 'Socks',
                code: 'U+1F9E6'
            },
            {
                name: 'Dress',
                code: 'U+1F457'
            },
            {
                name: 'Kimono',
                code: 'U+1F458'
            },
            {
                name: 'Sari',
                code: 'U+1F97B'
            },
            {
                name: 'One piece suit',
                code: 'U+1FA71'
            },
            {
                name: 'Briefs',
                code: 'U+1FA72'
            },
            {
                name: 'Shorts',
                code: 'U+1FA73'
            },
            {
                name: 'Bikini',
                code: 'U+1F459'
            },
            {
                name: 'Woman\'s shirt',
                code: 'U+1F45A'
            },
            {
                name: 'Purse',
                code: 'U+1F45B'
            },
            {
                name: 'Handbag',
                code: 'U+1F45C'
            },
            {
                name: 'Clutch bag',
                code: 'U+1F45D'
            },
            {
                name: 'Shopping bags',
                code: 'U+1F6CD'
            },
            {
                name: 'Backpack',
                code: 'U+1F392'
            },
            {
                name: 'Thong sandals',
                code: 'U+1FA74'
            },
            {
                name: 'Man\'s shoe',
                code: 'U+1F45E'
            },
            {
                name: 'Running shoe',
                code: 'U+1F45F'
            },
            {
                name: 'Hiking boot',
                code: 'U+1F97E'
            },
            {
                name: 'Flat shoe',
                code: 'U+1F97F'
            },
            {
                name: 'High-heeled shoe',
                code: 'U+1F460'
            },
            {
                name: 'Woman\'s sandal',
                code: 'U+1F461'
            },
            {
                name: 'Ballet shoes',
                code: 'U+1FA70'
            },
            {
                name: 'Woman\'s boot',
                code: 'U+1F462'
            },
            {
                name: 'Crown',
                code: 'U+1F451'
            },
            {
                name: 'Woman\'s hat',
                code: 'U+1F452'
            },
            {
                name: 'Top hat',
                code: 'U+1F3A9'
            },
            {
                name: 'Graduation cap',
                code: 'U+1F393'
            },
            {
                name: 'Billed cap',
                code: 'U+1F9E2'
            },
            {
                name: 'Military helmet',
                code: 'U+1FA96'
            },
            {
                name: 'Rescue worker\'s helmet',
                code: 'U+26D1'
            },
            {
                name: 'Prayer beads',
                code: 'U+1F4FF'
            },
            {
                name: 'Lipstick',
                code: 'U+1F484'
            },
            {
                name: 'Ring',
                code: 'U+1F48D'
            },
            {
                name: 'Gemstone',
                code: 'U+1F48E'
            }
        ]
    },
    {
        group: 'Sound Emojis',
        emojis: [
            {
                name: 'Muted speaker',
                code: 'U+1F507'
            },
            {
                name: 'Low volume speaker',
                code: 'U+1F508'
            },
            {
                name: 'Mid volume speaker',
                code: 'U+1F509'
            },
            {
                name: 'High volume speaker',
                code: 'U+1F50A'
            },
            {
                name: 'Loudspeaker',
                code: 'U+1F4E2'
            },
            {
                name: 'Megaphone',
                code: 'U+1F4E3'
            },
            {
                name: 'Postal horn',
                code: 'U+1F4EF'
            },
            {
                name: 'Bell',
                code: 'U+1F514'
            },
            {
                name: 'Bell with slash',
                code: 'U+1F515'
            },
            {
                name: 'Musical score',
                code: 'U+1F3BC'
            },
            {
                name: 'Musical note',
                code: 'U+1F3B5'
            },
            {
                name: 'Musical notes',
                code: 'U+1F3B6'
            },
            {
                name: 'Studio microphone',
                code: 'U+1F399'
            },
            {
                name: 'Level slider',
                code: 'U+1F39A'
            },
            {
                name: 'Control knobs',
                code: 'U+1F39B'
            },
            {
                name: 'Microphone',
                code: 'U+1F3A4'
            },
            {
                name: 'Headphone',
                code: 'U+1F3A7'
            },
            {
                name: 'Radio',
                code: 'U+1F4FB'
            }
        ]
    },
    {
        group: 'Musical Instrument Emojis',
        emojis: [
            {
                name: 'Saxophone',
                code: 'U+1F3B7'
            },
            {
                name: 'Accordion',
                code: 'U+1FA97'
            },
            {
                name: 'Guitar',
                code: 'U+1F3B8'
            },
            {
                name: 'Musical keyboard',
                code: 'U+1F3B9'
            },
            {
                name: 'Trumpet',
                code: 'U+1F3BA'
            },
            {
                name: 'Violin',
                code: 'U+1F3BB'
            },
            {
                name: 'Banjo',
                code: 'U+1FA95'
            },
            {
                name: 'Drum',
                code: 'U+1F941'
            },
            {
                name: 'Long drum',
                code: 'U+1FA98'
            }
        ]
    },
    {
        group: 'Phone Emojis',
        emojis: [
            {
                name: 'Mobile phone',
                code: 'U+1F4F1'
            },
            {
                name: 'Mobile phone with arrow',
                code: 'U+1F4F2'
            },
            {
                name: 'Telephone',
                code: 'U+260E'
            },
            {
                name: 'Telephone receiver',
                code: 'U+1F4DE'
            },
            {
                name: 'Pager',
                code: 'U+1F4DF'
            },
            {
                name: 'Fax machine',
                code: 'U+1F4E0'
            }
        ]
    },
    {
        group: 'Computer Emojis',
        emojis: [
            {
                name: 'Full battery',
                code: 'U+1F50B'
            },
            {
                name: 'Low battery',
                code: 'U+1FAAB'
            },
            {
                name: 'Electric plug',
                code: 'U+1F50C'
            },
            {
                name: 'Laptop',
                code: 'U+1F4BB'
            },
            {
                name: 'Desktop computer',
                code: 'U+1F5A5'
            },
            {
                name: 'Printer',
                code: 'U+1F5A8'
            },
            {
                name: 'Keyboard',
                code: 'U+2328'
            },
            {
                name: 'Mouse',
                code: 'U+1F5B1'
            },
            {
                name: 'Trackball',
                code: 'U+1F5B2'
            },
            {
                name: 'Computer disk',
                code: 'U+1F4BD'
            },
            {
                name: 'Floppy disk',
                code: 'U+1F4BE'
            },
            {
                name: 'Optical disk',
                code: 'U+1F4BF'
            },
            {
                name: 'DVD',
                code: 'U+1F4C0'
            },
            {
                name: 'Abacus',
                code: 'U+1F9EE'
            }
        ]
    },
    {
        group: 'Light and Video Emojis',
        emojis: [
            {
                name: 'Movie camera',
                code: 'U+1F3A5'
            },
            {
                name: 'Film frames',
                code: 'U+1F39E'
            },
            {
                name: 'Film Projector',
                code: 'U+1F4FD'
            },
            {
                name: 'Clapper board',
                code: 'U+1F3AC'
            },
            {
                name: 'Television',
                code: 'U+1F4FA'
            },
            {
                name: 'Camera',
                code: 'U+1F4F7'
            },
            {
                name: 'Camera with flash',
                code: 'U+1F4F8'
            },
            {
                name: 'Video camera',
                code: 'U+1F4F9'
            },
            {
                name: 'Video cassette',
                code: 'U+1F4FC'
            },
            {
                name: 'Magnifying glass tilted left',
                code: 'U+1F50D'
            },
            {
                name: 'Magnifying glass tilted right',
                code: 'U+1F50E'
            },
            {
                name: 'Candle',
                code: 'U+1F56F'
            },
            {
                name: 'Light bulb',
                code: 'U+1F4A1'
            },
            {
                name: 'Flashlight',
                code: 'U+1F526'
            },
            {
                name: 'Red pepper lantern',
                code: 'U+1F3EE'
            },
            {
                name: 'Diya lamp',
                code: 'U+1FA94'
            }
        ]
    },
    {
        group: 'Book and Paper Emojis',
        emojis: [
            {
                name: 'Notebook with decorative cover',
                code: 'U+1F4D4'
            },
            {
                name: 'Closed notebook',
                code: 'U+1F4D5'
            },
            {
                name: 'Opened notebook',
                code: 'U+1F4D6'
            },
            {
                name: 'Green book',
                code: 'U+1F4D7'
            },
            {
                name: 'Blue book',
                code: 'U+1F4D8'
            },
            {
                name: 'Orange book',
                code: 'U+1F4D9'
            },
            {
                name: 'Orange books',
                code: 'U+1F4DA'
            },
            {
                name: 'Notebook',
                code: 'U+1F4D3'
            },
            {
                name: 'Ledger',
                code: 'U+1F4D2'
            },
            {
                name: 'Page with curl',
                code: 'U+1F4C3'
            },
            {
                name: 'Scroll',
                code: 'U+1F4DC'
            },
            {
                name: 'Page facing up',
                code: 'U+1F4C4'
            },
            {
                name: 'Newspaper',
                code: 'U+1F4F0'
            },
            {
                name: 'Rolled-up newspaper',
                code: 'U+1F5DE'
            },
            {
                name: 'Bookmark tabs',
                code: 'U+1F4D1'
            },
            {
                name: 'Bookmark',
                code: 'U+1F516'
            },
            {
                name: 'Label',
                code: 'U+1F3F7'
            },
            {
                name: 'Money bag',
                code: 'U+1F4B0'
            },
            {
                name: 'Coin',
                code: 'U+1FA99'
            },
            {
                name: 'Yen banknote',
                code: 'U+1F4B4'
            },
            {
                name: 'Dollar banknote',
                code: 'U+1F4B5'
            },
            {
                name: 'Euro banknote',
                code: 'U+1F4B6'
            },
            {
                name: 'Pound banknote',
                code: 'U+1F4B7'
            },
            {
                name: 'Money with wings',
                code: 'U+1F4B8'
            },
            {
                name: 'Credit card',
                code: 'U+1F4B3'
            },
            {
                name: 'Receipt',
                code: 'U+1F9FE'
            },
            {
                name: 'Chart increase woth Yen',
                code: 'U+1F4B9'
            }
        ]
    },
    {
        group: 'Mail Emojis',
        emojis: [
            {
                name: 'Envelope',
                code: 'U+2709'
            },
            {
                name: 'e-mail',
                code: 'U+1F4E7'
            },
            {
                name: 'Envelope with arrow',
                code: 'U+1F4E9'
            },
            {
                name: 'Outbox tray',
                code: 'U+1F4E4'
            },
            {
                name: 'Inbox tray',
                code: 'U+1F4E5'
            },
            {
                name: 'Package',
                code: 'U+1F4E6'
            },
            {
                name: 'Closed mailbox with raised flag',
                code: 'U+1F4EB'
            },
            {
                name: 'Closed mailbox with lowered flag',
                code: 'U+1F4EA'
            },
            {
                name: 'Open mailbox with raised flag',
                code: 'U+1F4EC'
            },
            {
                name: 'Open mailbox with lowered flag',
                code: 'U+1F4ED'
            },
            {
                name: 'Postbox',
                code: 'U+1F4EE'
            },
            {
                name: 'Ballot box with ballot',
                code: 'U+1F5F3'
            }
        ]
    },
    {
        group: 'Writing Emojis',
        emojis: [
            {
                name: 'Pencil',
                code: 'U+270F'
            },
            {
                name: 'Black nib',
                code: 'U+2712'
            },
            {
                name: 'Fountain pen',
                code: 'U+1F58B'
            },
            {
                name: 'Pen',
                code: 'U+1F58A'
            },
            {
                name: 'Paintbrush',
                code: 'U+1F58C'
            },
            {
                name: 'Crayon',
                code: 'U+1F58D'
            },
            {
                name: 'Memo',
                code: 'U+1F4DD'
            }
        ]
    },
    {
        group: 'Office Emojis',
        emojis: [
            {
                name: 'Briefcase',
                code: 'U+1F4BC'
            },
            {
                name: 'File folder',
                code: 'U+1F4C1'
            },
            {
                name: 'Open the folder',
                code: 'U+1F4C2'
            },
            {
                name: 'Card index dividers',
                code: 'U+1F5C2'
            },
            {
                name: 'Calender',
                code: 'U+1F4C5'
            },
            {
                name: 'Tear off calender',
                code: 'U+1F4C6'
            },
            {
                name: 'Card index',
                code: 'U+1F4C7'
            },
            {
                name: 'Increasing chart',
                code: 'U+1F4C8'
            },
            {
                name: 'Decreasing chart',
                code: 'U+1F4C9'
            },
            {
                name: 'Bar chart',
                code: 'U+1F4CA'
            },
            {
                name: 'Clipboard',
                code: 'U+1F4CB'
            },
            {
                name: 'Pushpin',
                code: 'U+1F4CC'
            },
            {
                name: 'Round pushpin',
                code: 'U+1F4CD'
            },
            {
                name: 'Paperclip',
                code: 'U+1F4CE'
            },
            {
                name: 'Linked paperclips',
                code: 'U+1F587'
            },
            {
                name: 'Straight ruler',
                code: 'U+1F4CF'
            },
            {
                name: 'Triangular ruler',
                code: 'U+1F4D0'
            },
            {
                name: 'Scissors',
                code: 'U+2702'
            },
            {
                name: 'Card file box',
                code: 'U+1F5C3'
            },
            {
                name: 'File cabinet',
                code: 'U+1F5C4'
            },
            {
                name: 'Waste basket',
                code: 'U+1F5D1'
            }
        ]
    },
    {
        group: 'Lock Emojis',
        emojis: [
            {
                name: 'Locked',
                code: 'U+1F512'
            },
            {
                name: 'Unlocked',
                code: 'U+1F513'
            },
            {
                name: 'Locked with pen',
                code: 'U+1F50F'
            },
            {
                name: 'Locked with key',
                code: 'U+1F510'
            },
            {
                name: 'Key',
                code: 'U+1F511'
            },
            {
                name: 'Old key',
                code: 'U+1F5DD'
            }
        ]
    },
    {
        group: 'Tools Emojis',
        emojis: [
            {
                name: 'Hammer',
                code: 'U+1F528'
            },
            {
                name: 'Axe',
                code: 'U+1FA93'
            },
            {
                name: 'Pick',
                code: 'U+26CF'
            },
            {
                name: 'Hammer and pick',
                code: 'U+2692'
            },
            {
                name: 'Hammer and wrench',
                code: 'U+1F6E0'
            },
            {
                name: 'Sword',
                code: 'U+1F5E1'
            },
            {
                name: 'Crossed swords',
                code: 'U+2694'
            },
            {
                name: 'Water gun',
                code: 'U+1F52B'
            },
            {
                name: 'Boomerang',
                code: 'U+1FA83'
            },
            {
                name: 'Bow and arrow',
                code: 'U+1F3F9'
            },
            {
                name: 'Shield',
                code: 'U+1F6E1'
            },
            {
                name: 'Carpentry saw',
                code: 'U+1FA9A'
            },
            {
                name: 'Wrench',
                code: 'U+1F527'
            },
            {
                name: 'Screwdriver',
                code: 'U+1FA9B'
            },
            {
                name: 'Bolt and nut',
                code: 'U+1F529'
            },
            {
                name: 'Wheel',
                code: 'U+2699'
            },
            {
                name: 'Clamp',
                code: 'U+1F5DC'
            },
            {
                name: 'Balance scale',
                code: 'U+2696'
            },
            {
                name: 'White cane',
                code: 'U+1F9AF'
            },
            {
                name: 'Link',
                code: 'U+1F517'
            },
            {
                name: 'Chains',
                code: 'U+26D3'
            },
            {
                name: 'Hook',
                code: 'U+1FA9D'
            },
            {
                name: 'Toolbox',
                code: 'U+1F9F0'
            },
            {
                name: 'Magnet',
                code: 'U+1F9F2'
            },
            {
                name: 'Ladder',
                code: 'U+1FA9C'
            }
        ]
    },
    {
        group: 'Science Emojis',
        emojis: [
            {
                name: 'Alembic',
                code: 'U+2697'
            },
            {
                name: 'Test tube',
                code: 'U+1F9EA'
            },
            {
                name: 'Petri dish',
                code: 'U+1F9EB'
            },
            {
                name: 'DNA',
                code: 'U+1F9EC'
            },
            {
                name: 'Microscope',
                code: 'U+1F52C'
            },
            {
                name: 'Telescope',
                code: 'U+1F52D'
            },
            {
                name: 'Satelite antenna',
                code: 'U+1F4E1'
            }
        ]
    },
    {
        group: 'Medical Emojis',
        emojis: [
            {
                name: 'Syringe',
                code: 'U+1F489'
            },
            {
                name: 'A droplet of blood',
                code: 'U+1FA78'
            },
            {
                name: 'Pill',
                code: 'U+1F48A'
            },
            {
                name: 'Adhesive bandage',
                code: '🩹'
            },
            {
                name: 'Crutch',
                code: 'U+1FA7C'
            },
            {
                name: 'Stethoscope',
                code: 'U+1FA7A'
            },
            {
                name: 'X-ray',
                code: 'U+1FA7B'
            }
        ]
    },
    {
        group: 'Household Emojis',
        emojis: [
            {
                name: 'Door',
                code: 'U+1F6AA'
            },
            {
                name: 'Elevator',
                code: 'U+1F6D7'
            },
            {
                name: 'Mirror',
                code: 'U+1FA9E'
            },
            {
                name: 'Window',
                code: 'U+1FA9F'
            },
            {
                name: 'Bed',
                code: 'U+1F6CF'
            },
            {
                name: 'Couch and lamp',
                code: 'U+1F6CB'
            },
            {
                name: 'Chair',
                code: 'U+1FA91'
            },
            {
                name: 'Toilet',
                code: 'U+1F6BD'
            },
            {
                name: 'Plunger',
                code: 'U+1FAA0'
            },
            {
                name: 'Shower',
                code: 'U+1F6BF'
            },
            {
                name: 'Bathtub',
                code: 'U+1F6C1'
            },
            {
                name: 'Mouse trap',
                code: 'U+1FAA4'
            },
            {
                name: 'Razor',
                code: 'U+1FA92'
            },
            {
                name: 'Lotion bottle',
                code: 'U+1F9F4'
            },
            {
                name: 'Safety pin',
                code: 'U+1F9F7'
            },
            {
                name: 'Broom',
                code: 'U+1F9F9'
            },
            {
                name: 'Basket',
                code: 'U+1F9FA'
            },
            {
                name: 'Roll of paper',
                code: 'U+1F9FB'
            },
            {
                name: 'Bucket',
                code: 'U+1FAA3'
            },
            {
                name: 'Soap',
                code: 'U+1F9FC'
            },
            {
                name: 'Bubbles',
                code: 'U+1FAE7'
            },
            {
                name: 'Toothbrush',
                code: 'U+1FAA5'
            },
            {
                name: 'Sponge',
                code: 'U+1F9FD'
            },
            {
                name: 'Fire extinguisher',
                code: 'U+1F9EF'
            },
            {
                name: 'Shopping cart',
                code: 'U+1F6D2'
            }
        ]
    },
    {
        group: 'Other Objects Emojis',
        emojis: [
            {
                name: 'Cigarette',
                code: 'U+1F6AC'
            },
            {
                name: 'Casket',
                code: 'U+26B0'
            },
            {
                name: 'Headstone',
                code: 'U+1FAA6'
            },
            {
                name: 'Funeral urn',
                code: 'U+26B1'
            },
            {
                name: 'Moai',
                code: 'U+1F5FF'
            },
            {
                name: 'Placard',
                code: 'U+1FAA7'
            },
            {
                name: 'ID Card',
                code: 'U+1FAAA'
            }
        ]
    },
    {
        group: 'Symbols',
        emojis: [
            {
                name: 'ATM Sign',
                code: 'U+1F3E7'
            },
            {
                name: 'Litter in bin',
                code: 'U+1F6AE'
            },
            {
                name: 'Portable water',
                code: 'U+1F6B0'
            },
            {
                name: 'Wheelchair symbol',
                code: 'U+267F'
            },
            {
                name: 'Men\'s room symbol',
                code: 'U+1F6B9'
            },
            {
                name: 'Women\'s room symbol',
                code: 'U+1F6BA'
            },
            {
                name: 'Restroom symbol',
                code: 'U+1F6BB'
            },
            {
                name: 'Baby symbol',
                code: 'U+1F6BC'
            },
            {
                name: 'Water closet',
                code: 'U+1F6BE'
            },
            {
                name: 'Passport control',
                code: 'U+1F6C2'
            },
            {
                name: 'Customs',
                code: 'U+1F6C3'
            },
            {
                name: 'Baggage claim',
                code: 'U+1F6C4'
            },
            {
                name: 'Left laugage',
                code: 'U+1F6C5'
            }
        ]
    },
    {
        group: 'Warning Emojis',
        emojis: [
            {
                name: 'Warning',
                code: 'U+26A0'
            },
            {
                name: 'Children crossing',
                code: 'U+1F6B8'
            },
            {
                name: 'No entry',
                code: 'U+26D4'
            },
            {
                name: 'Prohibited',
                code: 'U+1F6AB'
            },
            {
                name: 'No bicycles',
                code: 'U+1F6B3'
            },
            {
                name: 'No smoking',
                code: 'U+1F6AD'
            },
            {
                name: 'No littering',
                code: 'U+1F6AF'
            },
            {
                name: 'Non-portable water',
                code: 'U+1F6B1'
            },
            {
                name: 'No pedestrians',
                code: 'U+1F6B7'
            },
            {
                name: 'No mobile phones',
                code: 'U+1F4F5'
            },
            {
                name: 'No one under 18',
                code: 'U+1F51E'
            },
            {
                name: 'Radioactive',
                code: 'U+2622'
            },
            {
                name: 'Biohazard',
                code: 'U+2623'
            }
        ]
    },
    {
        group: 'Arrow Emojis',
        emojis: [
            {
                name: 'Up Arrow',
                code: 'U+2B06'
            },
            {
                name: 'Up-right arrow',
                code: 'U+2197'
            },
            {
                name: 'Right arrow',
                code: 'U+27A1'
            },
            {
                name: 'Down-right arrow',
                code: 'U+2198'
            },
            {
                name: 'Down arrow',
                code: 'U+2B07'
            },
            {
                name: 'Down-left arrow',
                code: 'U+2199'
            },
            {
                name: 'Left arrow',
                code: 'U+2B05'
            },
            {
                name: 'Up-left arrow',
                code: 'U+2196'
            },
            {
                name: 'Up-down arrow',
                code: 'U+2195'
            },
            {
                name: 'Left arrow',
                code: 'U+2194'
            },
            {
                name: 'Right arrow curving left',
                code: 'U+21A9'
            },
            {
                name: 'Left arrow curving right',
                code: 'U+21AA'
            },
            {
                name: 'Right arrow curving up',
                code: 'U+2934'
            },
            {
                name: 'Right arrow curving down',
                code: 'U+2935'
            },
            {
                name: 'Clockwise vertical arrow',
                code: 'U+1F503'
            },
            {
                name: 'Counterclockwise arrows button',
                code: 'U+1F504'
            },
            {
                name: 'Back arrow',
                code: 'U+1F519'
            },
            {
                name: 'End arrow',
                code: 'U+1F51A'
            },
            {
                name: 'On arrow',
                code: 'U+1F51B'
            },
            {
                name: 'Soon arrow',
                code: 'U+1F51C'
            },
            {
                name: 'Top arrow',
                code: 'U+1F51D'
            }
        ]
    },
    {
        group: 'Religion',
        emojis: [
            {
                name: 'Place of worship',
                code: 'U+1F6D0'
            },
            {
                name: 'Atom symbol',
                code: 'U+269B'
            },
            {
                name: 'OM',
                code: 'U+1F549'
            },
            {
                name: 'Star of David',
                code: 'U+2721'
            },
            {
                name: 'Wheel of Dharma',
                code: 'U+2638'
            },
            {
                name: 'Yin yang',
                code: 'U+262F'
            },
            {
                name: 'Latin cross',
                code: 'U+271D'
            },
            {
                name: 'Orthodox cross',
                code: 'U+2626'
            },
            {
                name: 'Star and cresent moon',
                code: 'U+262A'
            },
            {
                name: 'Peace',
                code: 'U+262E'
            },
            {
                name: 'Menorah',
                code: 'U+1F54E'
            },
            {
                name: 'Six-pointed star',
                code: 'U+1F52F'
            }
        ]
    },
    {
        group: 'Zodiac',
        emojis: [
            {
                name: 'Aries',
                code: 'U+2648'
            },
            {
                name: 'Taurus',
                code: 'U+2649'
            },
            {
                name: 'Gemini',
                code: 'U+264A'
            },
            {
                name: 'Cancer',
                code: 'U+264B'
            },
            {
                name: 'Leo',
                code: 'U+264C'
            },
            {
                name: 'Virgo',
                code: 'U+264D'
            },
            {
                name: 'Libra',
                code: 'U+264E'
            },
            {
                name: 'Scorpio',
                code: 'U+264F'
            },
            {
                name: 'Sagittarius',
                code: 'U+2650'
            },
            {
                name: 'Capricon',
                code: 'U+2651'
            },
            {
                name: 'Acquarius',
                code: 'U+2652'
            },
            {
                name: 'Pisces',
                code: 'U+2653'
            },
            {
                name: 'Ophiucus',
                code: 'U+26CE'
            }
        ]
    },
    {
        group: 'AV Symbols',
        emojis: [
            {
                name: 'Shuffle tracks',
                code: 'U+1F500'
            },
            {
                name: 'Repeat all',
                code: 'U+1F501'
            },
            {
                name: 'Repeat one',
                code: 'U+1F502'
            },
            {
                name: 'Play',
                code: 'U+25B6'
            },
            {
                name: 'Pause',
                code: 'U+23F8'
            },
            {
                name: 'Fast-forward',
                code: 'U+23E9'
            },
            {
                name: 'Next track',
                code: 'U+23ED'
            },
            {
                name: 'Play or pause',
                code: 'U+23EF'
            },
            {
                name: 'Reverse',
                code: 'U+25C0'
            },
            {
                name: 'Fast-reverse',
                code: 'U+23EA'
            },
            {
                name: 'Previous track',
                code: 'U+23EE'
            },
            {
                name: 'Upwards',
                code: 'U+1F53C'
            },
            {
                name: 'Fast-up',
                code: 'U+23EB'
            },
            {
                name: 'Downwards',
                code: 'U+1F53D'
            },
            {
                name: 'Fast down',
                code: 'U+23EC'
            },
            {
                name: 'Stop',
                code: 'U+23F9'
            },
            {
                name: 'Record',
                code: 'U+23FA'
            },
            {
                name: 'Eject',
                code: 'U+23CF'
            },
            {
                name: 'Cinema',
                code: 'U+1F3A6'
            },
            {
                name: 'Dim',
                code: 'U+1F505'
            },
            {
                name: 'Bright',
                code: 'U+1F506'
            },
            {
                name: 'Network antenna bars',
                code: 'U+1F4F6'
            },
            {
                name: 'Vibration mode',
                code: 'U+1F4F3'
            },
            {
                name: 'Mobile phone off',
                code: 'U+1F4F4'
            }
        ]
    },
    {
        group: 'Gender',
        emojis: [
            {
                name: 'Female',
                code: 'U+2640'
            },
            {
                name: 'Male',
                code: 'U+2642'
            },
            {
                name: 'Transgender',
                code: 'U+26A7'
            }
        ]
    },
    {
        group: 'Math Symbols',
        emojis: [
            {
                name: 'Times',
                code: 'U+2716'
            },
            {
                name: 'Plus',
                code: 'U+2795'
            },
            {
                name: 'Minus',
                code: 'U+2796'
            },
            {
                name: 'Divide',
                code: 'U+2797'
            },
            {
                name: 'Equals',
                code: 'U+1F7F0'
            },
            {
                name: 'Infinity',
                code: 'U+267E'
            }
        ]
    },
    {
        group: 'Punctuation Symbols',
        emojis: [
            {
                name: 'Double exclamation',
                code: 'U+203C'
            },
            {
                name: 'Exclamation and question mark',
                code: 'U+2049'
            },
            {
                name: 'Red question mark',
                code: 'U+2753'
            },
            {
                name: 'White question mark',
                code: 'U+2754'
            },
            {
                name: 'Red exclamation mark',
                code: 'U+2757'
            },
            {
                name: 'White exclamation mark',
                code: 'U+2755'
            },
            {
                name: 'Wavy dash',
                code: 'U+3030'
            }
        ]
    },
    {
        group: 'Currency',
        emojis: [
            {
                name: 'Currency exchange',
                code: 'U+1F4B1'
            },
            {
                name: 'Heavy green dollar sign',
                code: 'U+1F4B2'
            }
        ]
    },
    {
        group: 'Other Symbols',
        emojis: [
            {
                name: 'Medical symbol',
                code: 'U+2695'
            },
            {
                name: 'Recycling symbol',
                code: 'U+267B'
            },
            {
                name: 'Fleur-de-lis',
                code: 'U+269C'
            },
            {
                name: 'Trident',
                code: 'U+1F531'
            },
            {
                name: 'Name badge',
                code: 'U+1F4DB'
            },
            {
                name: 'Japanese symbol for beginner',
                code: 'U+1F530'
            },
            {
                name: 'Hollow red circle',
                code: 'U+2B55'
            },
            {
                name: 'Green box with checkmark',
                code: 'U+2705'
            },
            {
                name: 'Blue box with checkmark',
                code: 'U+2611'
            },
            {
                name: 'Checkmark',
                code: 'U+2714'
            },
            {
                name: 'Crossmark',
                code: 'U+274C'
            },
            {
                name: 'Green crossmark',
                code: 'U+274E'
            },
            {
                name: 'Curly loop',
                code: 'U+27B0'
            },
            {
                name: 'Double curly loop',
                code: 'U+27BF'
            },
            {
                name: 'Part alternation mark',
                code: 'U+303D'
            },
            {
                name: 'Eight-spoked asterisk',
                code: 'U+2733'
            },
            {
                name: 'Eight-pointed star',
                code: 'U+2734'
            },
            {
                name: 'Sparkle',
                code: 'U+2747'
            },
            {
                name: 'Copyright symbol',
                code: 'U+00A9'
            },
            {
                name: 'Registered',
                code: 'U+00AE'
            },
            {
                name: 'Trademark',
                code: 'U+2122'
            }
        ]
    },
    {
        group: 'Keycap',
        emojis: [
            {
                name: '# Keycap',
                code: 'U+20E3'
            },
            {
                name: '* Keycap',
                code: 'U+20E3'
            },
            {
                name: '0 Keycap',
                code: 'U+20E3'
            },
            {
                name: '1 Keycap',
                code: 'U+20E3'
            },
            {
                name: '2 Keycap',
                code: 'U+20E3'
            },
            {
                name: '3 Keycap',
                code: 'U+20E3'
            },
            {
                name: '4 Keycap',
                code: 'U+20E3'
            },
            {
                name: '5 Keycap',
                code: 'U+20E3'
            },
            {
                name: '6 Keycap',
                code: 'U+20E3'
            },
            {
                name: '7 Keycap',
                code: 'U+20E3'
            },
            {
                name: '8 Keycap',
                code: 'U+20E3'
            },
            {
                name: '9 Keycap',
                code: 'U+20E3'
            },
            {
                name: '10 Keycap',
                code: 'U+1F51F'
            }
        ]
    },
    {
        group: 'Alphanumeric Symbols',
        emojis: [
            {
                name: 'Input Latin uppercase',
                code: 'U+1F520'
            },
            {
                name: 'Input Latin lowercase',
                code: 'U+1F521'
            },
            {
                name: 'Input numbers',
                code: 'U+1F522'
            },
            {
                name: 'Input symbols',
                code: 'U+1F523'
            },
            {
                name: 'Input Latin letters',
                code: 'U+1F524'
            },
            {
                name: 'A blood type',
                code: 'U+1F170'
            },
            {
                name: 'AB blood type',
                code: 'U+1F18E'
            },
            {
                name: 'B blood type',
                code: 'U+1F171'
            },
            {
                name: 'O blood type',
                code: 'U+1F17E'
            },
            {
                name: 'CL button',
                code: 'U+1F191'
            },
            {
                name: 'Cool button',
                code: 'U+1F192'
            },
            {
                name: 'Free button',
                code: 'U+1F193'
            },
            {
                name: 'Info button',
                code: 'U+2139'
            },
            {
                name: 'ID button',
                code: 'U+1F194'
            },
            {
                name: 'Circled M',
                code: 'U+24C2'
            },
            {
                name: 'New button',
                code: 'U+1F195'
            },
            {
                name: 'NG button',
                code: 'U+1F196'
            },
            {
                name: 'OK button',
                code: 'U+1F197'
            },
            {
                name: 'P button',
                code: 'U+1F17F'
            },
            {
                name: 'SOS button',
                code: 'U+1F198'
            },
            {
                name: 'UP! button',
                code: 'U+1F199'
            },
            {
                name: 'VS Button',
                code: 'U+1F19A'
            }
        ]
    },
    {
        group: 'Japanese Buttons',
        emojis: [
            {
                name: 'Japanese "here" button',
                code: 'U+1F201'
            },
            {
                name: 'Japanese "service charge" button',
                code: 'U+1F202'
            },
            {
                name: 'Japanese "monthly amount" button',
                code: 'U+1F237'
            },
            {
                name: 'Japanese "not free of charge" button',
                code: 'U+1F236'
            },
            {
                name: 'Japanese "reserved" button',
                code: 'U+1F22F'
            },
            {
                name: 'Japanese "bargain" button',
                code: 'U+1F250'
            },
            {
                name: 'Japanese "discount" button',
                code: 'U+1F239'
            },
            {
                name: 'Japanese "free of charge" button',
                code: 'U+1F21A'
            },
            {
                name: 'Japanese "prohibited" button',
                code: 'U+1F232'
            },
            {
                name: 'Japanese "acceptable" button',
                code: 'U+1F251'
            },
            {
                name: 'Japanese "application" button',
                code: 'U+1F238'
            },
            {
                name: 'Japanese "passing grade" button',
                code: 'U+1F234'
            },
            {
                name: 'Japanese "vacancy" button',
                code: 'U+1F233'
            },
            {
                name: 'Japanese "congratulations" button',
                code: 'U+3297'
            },
            {
                name: 'Japanese "secret" button',
                code: 'U+3299'
            },
            {
                name: 'Japanese "open for business" button',
                code: 'U+1F23A'
            },
            {
                name: 'Japanese "no vacancy" button',
                code: 'U+1F235'
            }
        ]
    },
    {
        group: 'Geometric Emojis',
        emojis: [
            {
                name: 'Red circle',
                code: 'U+1F534'
            },
            {
                name: 'Orange circle',
                code: 'U+1F7E0'
            },
            {
                name: 'Yellow circle',
                code: 'U+1F7E1'
            },
            {
                name: 'Green circle',
                code: 'U+1F7E2'
            },
            {
                name: 'Blue circle',
                code: 'U+1F535'
            },
            {
                name: 'Purple circle',
                code: 'U+1F7E3'
            },
            {
                name: 'Brown circle',
                code: 'U+1F7E4'
            },
            {
                name: 'Black circle',
                code: 'U+26AB'
            },
            {
                name: 'White circle',
                code: 'U+26AA'
            },
            {
                name: 'Red square',
                code: 'U+1F7E5'
            },
            {
                name: 'Orange square',
                code: 'U+1F7E5'
            },
            {
                name: 'Yellow square',
                code: 'U+1F7E8'
            },
            {
                name: 'Green square',
                code: 'U+1F7E9'
            },
            {
                name: 'Blue square',
                code: 'U+1F7E6'
            },
            {
                name: 'Purple square',
                code: 'U+1F7EA'
            },
            {
                name: 'Brown square',
                code: 'U+1F7EB'
            },
            {
                name: 'Black square',
                code: 'U+2B1B'
            },
            {
                name: 'White square',
                code: 'U+2B1C'
            },
            {
                name: 'Large orange diamond',
                code: 'U+1F536'
            },
            {
                name: 'Large blue diamond',
                code: 'U+1F537'
            },
            {
                name: 'Small orange diamond',
                code: 'U+1F538'
            },
            {
                name: 'Small blue diamond',
                code: 'U+1F539'
            },
            {
                name: 'Red triangle pointed up',
                code: 'U+1F53A'
            },
            {
                name: 'Red triangle pointed down',
                code: 'U+1F53B'
            },
            {
                name: 'Diamond with a dot',
                code: 'U+1F4A0'
            },
            {
                name: 'Radio button',
                code: 'U+1F518'
            },
            {
                name: 'White square button',
                code: 'U+1F533'
            },
            {
                name: 'Black square button',
                code: 'U+1F532'
            }
        ]
    },
    {
        group: 'Flags',
        emojis: [
            {
                name: 'Chequered flag',
                code: 'U+1F3C1'
            },
            {
                name: 'Triangular flag',
                code: 'U+1F6A9'
            },
            {
                name: 'Crossed flag',
                code: 'U+1F38C'
            },
            {
                name: 'Black flag',
                code: 'U+1F3F4'
            },
            {
                name: 'White flag',
                code: 'U+1F3F3'
            },
            {
                name: 'Rainbow flag',
                code: 'U+1F3F3 U+FE0F U+200D U+1F308'
            },
            {
                name: 'Transgender Flag',
                code: 'U+1F3F3 U+FE0F U+200D U+26A7 U+FE0F'
            },
            {
                name: 'Pirate flag',
                code: 'U+1F3F4 U+200D U+2620 U+FE0F'
            }
        ]
    },
    {
        group: 'Country Flags',
        emojis: [
            {
                name: 'Ascension Island flag',
                code: 'U+1F1E6 U+1F1E8'
            },
            {
                name: 'Andorra flag',
                code: 'U+1F1E6 U+1F1E9'
            },
            {
                name: 'UAE flag',
                code: 'U+1F1E6 U+1F1EA'
            },
            {
                name: 'Afghanistan flag',
                code: 'U+1F1E6 U+1F1EB'
            },
            {
                name: 'Antigua & Barbuda flag',
                code: 'U+1F1E6 U+1F1EC'
            },
            {
                name: 'Anguilla flag',
                code: 'U+1F1E6 U+1F1EE'
            },
            {
                name: 'Albania',
                code: 'U+1F1E6 U+1F1F1'
            },
            {
                name: 'Algeria flag',
                code: 'U+1F1E9 U+1F1FF'
            },
            {
                name: 'Armenia flag',
                code: 'U+1F1E6 U+1F1F2'
            },
            {
                name: 'Angola flag',
                code: 'U+1F1E6 U+1F1F4'
            },
            {
                name: 'Antarctica flag',
                code: 'U+1F1E6 U+1F1F6'
            },
            {
                name: 'Argentina flag',
                code: 'U+1F1E6 U+1F1F7'
            },
            {
                name: 'American Samoa flag',
                code: 'U+1F1E6 U+1F1F8'
            },
            {
                name: 'Austria flag',
                code: 'U+1F1E6 U+1F1F9'
            },
            {
                name: 'Australia flag',
                code: 'U+1F1E6 U+1F1FA'
            },
            {
                name: 'Aruba flag',
                code: 'U+1F1E6 U+1F1FC'
            },
            {
                name: 'Åland Islands flag',
                code: 'U+1F1E6 U+1F1FD'
            },
            {
                name: 'Azerbaijan flag',
                code: 'U+1F1E6 U+1F1FF'
            },
            {
                name: 'Bosnia & Herzegovina flag',
                code: 'U+1F1E7 U+1F1E6'
            },
            {
                name: 'Bangladesh flag',
                code: 'U+1F1E7 U+1F1E9'
            },
            {
                name: 'Belgium flag',
                code: 'U+1F1E7 U+1F1EA'
            },
            {
                name: 'Burkina Faso flag',
                code: 'U+1F1E7 U+1F1EB'
            },
            {
                name: 'Bulgaria flag',
                code: 'U+1F1E7 U+1F1EC'
            },
            {
                name: 'Bahrain flag',
                code: 'U+1F1E7 U+1F1ED'
            },
            {
                name: 'Burundi flag',
                code: 'U+1F1E7 U+1F1EE'
            },
            {
                name: 'Benin Republic flag',
                code: 'U+1F1E7 U+1F1EF'
            },
            {
                name: 'St. Barthélemy flag',
                code: 'U+1F1E7 U+1F1F1'
            },
            {
                name: 'Bermuda flag',
                code: 'U+1F1E7 U+1F1F2'
            },
            {
                name: 'Brunei flag',
                code: 'U+1F1E7 U+1F1F3'
            },
            {
                name: 'Bolivia flag',
                code: 'U+1F1E7 U+1F1F4'
            },
            {
                name: 'Caribbean Netherlands flag',
                code: 'U+1F1E7 U+1F1F6'
            },
            {
                name: 'Brazil flag',
                code: 'U+1F1E7 U+1F1F7'
            },
            {
                name: 'Bahamas flag',
                code: 'U+1F1E7 U+1F1F8'
            },
            {
                name: 'Bhutan flag',
                code: 'U+1F1E7 U+1F1F9'
            },
            {
                name: 'Bouvet Island flag',
                code: 'U+1F1E7 U+1F1FB'
            },
            {
                name: 'Botswana flag',
                code: 'U+1F1E7 U+1F1FC'
            },
            {
                name: 'Belarus flag',
                code: 'U+1F1E7 U+1F1FE'
            },
            {
                name: 'Belize flag',
                code: 'U+1F1E7 U+1F1FF'
            },
            {
                name: 'Canada flag',
                code: 'U+1F1E8 U+1F1E6'
            },
            {
                name: 'Cocos (Keeling) Islands flag',
                code: 'U+1F1E8 U+1F1E8'
            },
            {
                name: 'DR Congo flag',
                code: 'U+1F1E8 U+1F1E9'
            },
            {
                name: 'Central African Republic flag',
                code: 'U+1F1E8 U+1F1EB'
            },
            {
                name: 'Congo Brazzaville flag',
                code: 'U+1F1E8 U+1F1EC'
            },
            {
                name: 'Switzerland flag',
                code: 'U+1F1E8 U+1F1ED'
            },
            {
                name: 'Côte d’Ivoire flag',
                code: 'U+1F1E8 U+1F1EE'
            },
            {
                name: 'Cook Islands flag',
                code: 'U+1F1E8 U+1F1F0'
            },
            {
                name: 'Chile flag',
                code: 'U+1F1E8 U+1F1F1'
            },
            {
                name: 'Cameroon flag',
                code: 'U+1F1E8 U+1F1F2'
            },
            {
                name: 'China flag',
                code: 'U+1F1E8 U+1F1F3'
            },
            {
                name: 'Columbia flag',
                code: 'U+1F1E8 U+1F1F4'
            },
            {
                name: 'Clipperton Island flag',
                code: 'U+1F1E8 U+1F1F5'
            },
            {
                name: 'Costa Rica flag',
                code: 'U+1F1E8 U+1F1F7'
            },
            {
                name: 'Cuba flag',
                code: 'U+1F1E8 U+1F1FA'
            },
            {
                name: 'Cape Verde flag',
                code: 'U+1F1E8 U+1F1FB'
            },
            {
                name: 'Curaçao flag',
                code: 'U+1F1E8 U+1F1FC'
            },
            {
                name: 'Christmas Island flag',
                code: 'U+1F1E8 U+1F1FD'
            },
            {
                name: 'Cyprus flag',
                code: 'U+1F1E8 U+1F1FE'
            },
            {
                name: 'Czech Republic flag',
                code: 'U+1F1E8 U+1F1FF'
            },
            {
                name: 'Germany flag',
                code: 'U+1F1E9 U+1F1EA'
            },
            {
                name: 'Diego Garcia flag',
                code: 'U+1F1E9 U+1F1EC'
            },
            {
                name: 'Djibouti flag',
                code: 'U+1F1E9 U+1F1EF'
            },
            {
                name: 'Denmark flag',
                code: 'U+1F1E9 U+1F1F0'
            },
            {
                name: 'Dominica flag',
                code: 'U+1F1E9 U+1F1F2'
            },
            {
                name: 'Dominican Republic flag',
                code: 'U+1F1E9 U+1F1F4'
            },
            {
                name: 'Ceuta & Melilla flag',
                code: 'U+1F1EA U+1F1E6'
            },
            {
                name: 'Ecuador flag',
                code: 'U+1F1EA U+1F1E8'
            },
            {
                name: 'Estonia flag',
                code: 'U+1F1EA U+1F1EA'
            },
            {
                name: 'Egypt flag',
                code: 'U+1F1EA U+1F1EC'
            },
            {
                name: 'Western Sahara flag',
                code: 'U+1F1EA U+1F1ED'
            },
            {
                name: 'Eritre flag',
                code: 'U+1F1EA U+1F1F7'
            },
            {
                name: 'Spain flag',
                code: 'U+1F1EA U+1F1F8'
            },
            {
                name: 'Ethiopia flag',
                code: 'U+1F1EA U+1F1F9'
            },
            {
                name: 'European Union flag',
                code: 'U+1F1EA U+1F1FA'
            },
            {
                name: 'Finalnd flag',
                code: 'U+1F1EB U+1F1EE'
            },
            {
                name: 'Fiji Island flag',
                code: 'U+1F1EB U+1F1EF'
            },
            {
                name: 'Falkland Islands flag',
                code: 'U+1F1EB U+1F1F0'
            },
            {
                name: 'Micronesia flag',
                code: 'U+1F1EB U+1F1F2'
            },
            {
                name: 'Faroe Islands flag',
                code: 'U+1F1EB U+1F1F4'
            },
            {
                name: 'France flag',
                code: 'U+1F1EB U+1F1F7'
            },
            {
                name: 'Gabon flag',
                code: 'U+1F1EC U+1F1E6'
            },
            {
                name: 'United Kingdom flag',
                code: 'U+1F1EC U+1F1E7'
            },
            {
                name: 'Grenada flag',
                code: 'U+1F1EC U+1F1E9'
            },
            {
                name: 'Georgia flag',
                code: 'U+1F1EC U+1F1EA'
            },
            {
                name: 'French Guiana flag',
                code: 'U+1F1EC U+1F1EB'
            },
            {
                name: 'Guernsey flag',
                code: 'U+1F1EC U+1F1EC'
            },
            {
                name: 'Ghana flag',
                code: 'U+1F1EC U+1F1ED'
            },
            {
                name: 'Gibraltar flag',
                code: 'U+1F1EC U+1F1EE'
            },
            {
                name: 'Greenland flag',
                code: 'U+1F1EC U+1F1F1'
            },
            {
                name: 'Gambia flag',
                code: 'U+1F1EC U+1F1F2'
            },
            {
                name: 'Guinea flag',
                code: 'U+1F1EC U+1F1F3'
            },
            {
                name: 'Guadeloupe flag',
                code: 'U+1F1EC U+1F1F5'
            },
            {
                name: 'Equatorial Guinea flag',
                code: 'U+1F1EC U+1F1F6'
            },
            {
                name: 'Greece flag',
                code: 'U+1F1EC U+1F1F7'
            },
            {
                name: 'South Georgia & South Sandwich Islands flag',
                code: 'U+1F1EC U+1F1F8'
            },
            {
                name: 'Guatemala flag',
                code: 'U+1F1EC U+1F1F9'
            },
            {
                name: 'Guam flag',
                code: 'U+1F1EC U+1F1FA'
            },
            {
                name: 'Guinea Bissau flag',
                code: 'U+1F1EC U+1F1FC'
            },
            {
                name: 'Guyana flag',
                code: 'U+1F1EC U+1F1FE'
            },
            {
                name: 'Hong Kong SAR China flag',
                code: 'U+1F1ED U+1F1F0'
            },
            {
                name: 'Heard & McDonald Islands flag',
                code: 'U+1F1ED U+1F1F2'
            },
            {
                name: 'Honduras flag',
                code: 'U+1F1ED U+1F1F3'
            },
            {
                name: 'Croatia flag',
                code: 'U+1F1ED U+1F1F7'
            },
            {
                name: 'Haiti flag',
                code: 'U+1F1ED U+1F1F9'
            },
            {
                name: 'Hungary flag',
                code: 'U+1F1ED U+1F1FA'
            },
            {
                name: 'Canary Islands flag',
                code: 'U+1F1EE U+1F1E8'
            },
            {
                name: 'Indonesia flag',
                code: 'U+1F1EE U+1F1E9'
            },
            {
                name: 'Ireland flag',
                code: 'U+1F1EE U+1F1EA'
            },
            {
                name: 'Israel flag',
                code: 'U+1F1EE U+1F1F1'
            },
            {
                name: 'Isle of Man flag',
                code: 'U+1F1EE U+1F1F2'
            },
            {
                name: 'India flag',
                code: 'U+1F1EE U+1F1F3'
            },
            {
                name: 'British Indian Ocean Territory flag',
                code: 'U+1F1EE U+1F1F4'
            },
            {
                name: 'Iraq flag',
                code: 'U+1F1EE U+1F1F6'
            },
            {
                name: 'Iran flag',
                code: 'U+1F1EE U+1F1F7'
            },
            {
                name: 'Iceland flag',
                code: 'U+1F1EE U+1F1F8'
            },
            {
                name: 'Italy flag',
                code: 'U+1F1EE U+1F1F9'
            },
            {
                name: 'Jersey flag',
                code: 'U+1F1EF U+1F1EA'
            },
            {
                name: 'Jamaica flag',
                code: 'U+1F1EF U+1F1F2'
            },
            {
                name: 'Jordan flag',
                code: 'U+1F1EF U+1F1F4'
            },
            {
                name: 'Japan flag',
                code: 'U+1F1EF U+1F1F5'
            },
            {
                name: 'Kenya flag',
                code: 'U+1F1F0 U+1F1EA'
            },
            {
                name: 'Kyrgyzstan flag',
                code: 'U+1F1F0 U+1F1EC'
            },
            {
                name: 'Cambodia flag',
                code: 'U+1F1F0 U+1F1ED'
            },
            {
                name: 'Kiribati flag',
                code: 'U+1F1F0 U+1F1EE'
            },
            {
                name: 'Comoros flag',
                code: 'U+1F1F0 U+1F1F2'
            },
            {
                name: 'St. Kitts & Nevis flag',
                code: 'U+1F1F0 U+1F1F3'
            },
            {
                name: 'North Korea flag',
                code: 'U+1F1F0 U+1F1F5'
            },
            {
                name: 'South Korea flag',
                code: 'U+1F1F0 U+1F1F7'
            },
            {
                name: 'Kuwait flag',
                code: 'U+1F1F0 U+1F1FC'
            },
            {
                name: 'Cayman Islands flag',
                code: 'U+1F1F0 U+1F1FE'
            },
            {
                name: 'Kazakhstan flag',
                code: 'U+1F1F0 U+1F1FF'
            },
            {
                name: 'Laos flag',
                code: 'U+1F1F1 U+1F1E6'
            },
            {
                name: 'Lebanon flag',
                code: 'U+1F1F1 U+1F1E7'
            },
            {
                name: 'St. Lucia flag',
                code: 'U+1F1F1 U+1F1E8'
            },
            {
                name: 'Liechtenstein flag',
                code: 'U+1F1F1 U+1F1EE'
            },
            {
                name: 'Sri Lanka flag',
                code: 'U+1F1F1 U+1F1F0'
            },
            {
                name: 'Liberia flag',
                code: 'U+1F1F1 U+1F1F7'
            },
            {
                name: 'Lesotho flag',
                code: 'U+1F1F1 U+1F1F8'
            },
            {
                name: 'Lithuania flag',
                code: 'U+1F1F1 U+1F1F9'
            },
            {
                name: 'Luxembourg flag',
                code: 'U+1F1F1 U+1F1FA'
            },
            {
                name: 'Latvia flag',
                code: 'U+1F1F1 U+1F1FB'
            },
            {
                name: 'Libya flag',
                code: 'U+1F1F1 U+1F1FE'
            },
            {
                name: 'Morocco flag',
                code: 'U+1F1F2 U+1F1E6'
            },
            {
                name: 'Monaco flag',
                code: 'U+1F1F2 U+1F1E8'
            },
            {
                name: 'Moldova flag',
                code: 'U+1F1F2 U+1F1E9'
            },
            {
                name: 'Montenegro flag',
                code: 'U+1F1F2 U+1F1EA'
            },
            {
                name: 'St. Martin flag',
                code: 'U+1F1F2 U+1F1EB'
            },
            {
                name: 'Madagascar flag',
                code: 'U+1F1F2 U+1F1EC'
            },
            {
                name: 'Marshall Islands flag',
                code: 'U+1F1F2 U+1F1ED'
            },
            {
                name: 'North Macedonia flag',
                code: 'U+1F1F2 U+1F1F0'
            },
            {
                name: 'Mali flag',
                code: 'U+1F1F2 U+1F1F1'
            },
            {
                name: 'Myanmar flag',
                code: 'U+1F1F2 U+1F1F2'
            },
            {
                name: 'Mongolia flag',
                code: 'U+1F1F2 U+1F1F3'
            },
            {
                name: 'Macao SAR China flag',
                code: 'U+1F1F2 U+1F1F4'
            },
            {
                name: 'Northern Mariana Islands flag',
                code: 'U+1F1F2 U+1F1F5'
            },
            {
                name: 'Martinique flag',
                code: 'U+1F1F2 U+1F1F6'
            },
            {
                name: 'Mauritania flag',
                code: 'U+1F1F2 U+1F1F7'
            },
            {
                name: 'Montserrat flag',
                code: 'U+1F1F2 U+1F1F8'
            },
            {
                name: 'Malta flag',
                code: 'U+1F1F2 U+1F1F9'
            },
            {
                name: 'Mauritius flag',
                code: 'U+1F1F2 U+1F1FA'
            },
            {
                name: 'Maldives flag',
                code: 'U+1F1F2 U+1F1FB'
            },
            {
                name: 'Malawi flag',
                code: 'U+1F1F2 U+1F1FC'
            },
            {
                name: 'Mexico flag',
                code: 'U+1F1F2 U+1F1FD'
            },
            {
                name: 'Malaysia flag',
                code: 'U+1F1F2 U+1F1FE'
            },
            {
                name: 'Mozambique flag',
                code: 'U+1F1F2 U+1F1FF'
            },
            {
                name: 'Namibia flag',
                code: 'U+1F1F3 U+1F1E6'
            },
            {
                name: 'New Caledonia flag',
                code: 'U+1F1F3 U+1F1E8'
            },
            {
                name: 'Niger Republic flag',
                code: 'U+1F1F3 U+1F1EA'
            },
            {
                name: 'Nigeria flag',
                code: 'U+1F1F3 U+1F1EC'
            },
            {
                name: 'Norfolk Island flag',
                code: 'U+1F1F3 U+1F1EB'
            },
            {
                name: 'Nicaragua flag',
                code: 'U+1F1F3 U+1F1EE'
            },
            {
                name: 'Netherlands flag',
                code: 'U+1F1F3 U+1F1F1'
            },
            {
                name: 'Norway flag',
                code: 'U+1F1F3 U+1F1F4'
            },
            {
                name: 'Nepal flag',
                code: 'U+1F1F3 U+1F1F5'
            },
            {
                name: 'Nauru flag',
                code: 'U+1F1F3 U+1F1F7'
            },
            {
                name: 'Niue flag',
                code: 'U+1F1F3 U+1F1FA'
            },
            {
                name: 'New Zealand flag',
                code: 'U+1F1F3 U+1F1FF'
            },
            {
                name: 'Oman flag',
                code: 'U+1F1F4 U+1F1F2'
            },
            {
                name: 'Panama flag',
                code: 'U+1F1F5 U+1F1E6'
            },
            {
                name: 'Peru flag',
                code: 'U+1F1F5 U+1F1EA'
            },
            {
                name: 'French Polynesia flag',
                code: 'U+1F1F5 U+1F1EB'
            },
            {
                name: 'Papua New Guinea flag',
                code: 'U+1F1F5 U+1F1EC'
            },
            {
                name: 'Philippines flag',
                code: 'U+1F1F5 U+1F1ED'
            },
            {
                name: 'Pakistan flag',
                code: 'U+1F1F5 U+1F1F0'
            },
            {
                name: 'Poland flag',
                code: 'U+1F1F5 U+1F1F1'
            },
            {
                name: 'St. Pierre & Miquelon flag',
                code: 'U+1F1F5 U+1F1F2'
            },
            {
                name: 'itcairn Islands flag',
                code: 'U+1F1F5 U+1F1F3'
            },
            {
                name: 'Puerto Rico flag',
                code: 'U+1F1F5 U+1F1F7'
            },
            {
                name: 'Palestinian Territories flag',
                code: 'U+1F1F5 U+1F1F8'
            },
            {
                name: 'Portugal flag',
                code: 'U+1F1F5 U+1F1F9'
            },
            {
                name: 'Palau flag',
                code: 'U+1F1F5 U+1F1FC'
            },
            {
                name: 'Paraguay flag',
                code: 'U+1F1F5 U+1F1FE'
            },
            {
                name: 'Qatar flag',
                code: 'U+1F1F6 U+1F1E6'
            },
            {
                name: 'Réunion flag',
                code: 'U+1F1F7 U+1F1EA'
            },
            {
                name: 'Romania flag',
                code: 'U+1F1F7 U+1F1F4'
            },
            {
                name: 'Serbia flag',
                code: 'U+1F1F7 U+1F1F8'
            },
            {
                name: 'Russia flag',
                code: 'U+1F1F7 U+1F1FA'
            },
            {
                name: 'Rwanda flag',
                code: 'U+1F1F7 U+1F1FC'
            },
            {
                name: 'Saudi Arabia flag',
                code: 'U+1F1F8 U+1F1E6'
            },
            {
                name: 'Solomon Islands flag',
                code: 'U+1F1F8 U+1F1E7'
            },
            {
                name: 'Seychelles flag',
                code: 'U+1F1F8 U+1F1E8'
            },
            {
                name: 'Sudan flag',
                code: 'U+1F1F8 U+1F1E9'
            },
            {
                name: 'Sweden flag',
                code: 'U+1F1F8 U+1F1EA'
            },
            {
                name: 'Singapore flag',
                code: 'U+1F1F8 U+1F1EC'
            },
            {
                name: 'St. Helena flag',
                code: 'U+1F1F8 U+1F1ED'
            },
            {
                name: 'Slovenia flag',
                code: 'U+1F1F8 U+1F1EE'
            },
            {
                name: 'Svalbard & Jan Mayen flag',
                code: 'U+1F1F8 U+1F1EF'
            },
            {
                name: 'Slovakia flag',
                code: 'U+1F1F8 U+1F1F0'
            },
            {
                name: 'Sierra Leone flag',
                code: 'U+1F1F8 U+1F1F1'
            },
            {
                name: 'San Marino flag',
                code: 'U+1F1F8 U+1F1F2'
            },
            {
                name: 'Senegal flag',
                code: 'U+1F1F8 U+1F1F3'
            },
            {
                name: 'Somalia flag',
                code: 'U+1F1F8 U+1F1F4'
            },
            {
                name: 'Suriname flag',
                code: 'U+1F1F8 U+1F1F7'
            },
            {
                name: 'South Sudan flag',
                code: 'U+1F1F8 U+1F1F8'
            },
            {
                name: 'São Tomé & Príncipe flag',
                code: 'U+1F1F8 U+1F1F9'
            },
            {
                name: 'El Salvador flag',
                code: 'U+1F1F8 U+1F1FB'
            },
            {
                name: 'Saint Maarten flag',
                code: 'U+1F1F8 U+1F1FD'
            },
            {
                name: 'Syria flag',
                code: 'U+1F1F8 U+1F1FE'
            },
            {
                name: 'Eswatini flag',
                code: 'U+1F1F8 U+1F1FF'
            },
            {
                name: 'Tristan da Cunha flag',
                code: 'U+1F1F9 U+1F1E6'
            },
            {
                name: 'Turks & Caicos Islands flag',
                code: 'U+1F1F9 U+1F1E8'
            },
            {
                name: 'Chad flag',
                code: 'U+1F1F9 U+1F1E9'
            },
            {
                name: 'French Southern Territories flag',
                code: 'U+1F1F9 U+1F1EB'
            },
            {
                name: 'Togo flag',
                code: 'U+1F1F9 U+1F1EC'
            },
            {
                name: 'Thailand flag',
                code: 'U+1F1F9 U+1F1ED'
            },
            {
                name: 'Tajikistan flag',
                code: 'U+1F1F9 U+1F1EF'
            },
            {
                name: 'Tokelau flag',
                code: 'U+1F1F9 U+1F1F0'
            },
            {
                name: 'Timor-Leste flag',
                code: 'U+1F1F9 U+1F1F1'
            },
            {
                name: 'Turkmenistan flag',
                code: 'U+1F1F9 U+1F1F2'
            },
            {
                name: 'Tunisia flag',
                code: 'U+1F1F9 U+1F1F3'
            },
            {
                name: 'Tonga flag',
                code: 'U+1F1F9 U+1F1F4'
            },
            {
                name: 'Turkey flag',
                code: 'U+1F1F9 U+1F1F7'
            },
            {
                name: 'Trinidad & Tobago flag',
                code: 'U+1F1F9 U+1F1F9'
            },
            {
                name: 'Tuvalu flag',
                code: 'U+1F1F9 U+1F1FB'
            },
            {
                name: 'Taiwan flag',
                code: 'U+1F1F9 U+1F1FC'
            },
            {
                name: 'Tanzania flag',
                code: 'U+1F1F9 U+1F1FF'
            },
            {
                name: 'Ukraine flag',
                code: 'U+1F1FA U+1F1E6'
            },
            {
                name: 'Uganda flag',
                code: 'U+1F1FA U+1F1EC'
            },
            {
                name: 'U.S. Outlying Islands flag',
                code: 'U+1F1FA U+1F1F2'
            },
            {
                name: 'United Nations flag',
                code: 'U+1F1FA U+1F1F3'
            },
            {
                name: 'United States flag',
                code: 'U+1F1FA U+1F1F8'
            },
            {
                name: 'Uruguay flag',
                code: 'U+1F1FA U+1F1FE'
            },
            {
                name: 'Uzbekistan flag',
                code: 'U+1F1FA U+1F1FF'
            },
            {
                name: 'Vatican City flag',
                code: 'U+1F1FB U+1F1E6'
            },
            {
                name: 'St. Vincent & Grenadines flag',
                code: 'U+1F1FB U+1F1E8'
            },
            {
                name: 'Venezuela flag',
                code: 'U+1F1FB U+1F1EA'
            },
            {
                name: 'British Virgin Islands flag',
                code: 'U+1F1FB U+1F1EC'
            },
            {
                name: 'U.S. Virgin Islands flag',
                code: 'U+1F1FB U+1F1EE'
            },
            {
                name: 'Vietnam flag',
                code: 'U+1F1FB U+1F1F3'
            },
            {
                name: 'Vanuatu flag',
                code: 'U+1F1FB U+1F1FA'
            },
            {
                name: 'Wallis & Futuna flag',
                code: 'U+1F1FC U+1F1EB'
            },
            {
                name: 'Samoa flag',
                code: 'U+1F1FC U+1F1F8'
            },
            {
                name: 'Kosovo flag',
                code: 'U+1F1FD U+1F1F0'
            },
            {
                name: 'Yemen flag',
                code: 'U+1F1FE U+1F1EA'
            },
            {
                name: 'Mayotte flag',
                code: 'U+1F1FE U+1F1F9'
            },
            {
                name: 'South Africa flag',
                code: 'U+1F1FF U+1F1E6'
            },
            {
                name: 'Zambia flag',
                code: 'U+1F1FF U+1F1F2'
            },
            {
                name: 'Zimbabwe flag',
                code: 'U+1F1FF U+1F1FC'
            },
            {
                name: 'England flag',
                code: 'U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F'
            },
            {
                name: 'Scotland flag',
                code: 'U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F'
            },
            {
                name: 'Wales flag',
                code: 'U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F'
            }
        ]
    }
];

