import { Component, OnInit } from '@angular/core';
import { Classroom } from '../../../_models/classroom';
import { ClassroomService } from '../../../services/classroom.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-classroom-page',
    templateUrl: './classroom-page.component.html',
    styleUrls: ['./classroom-page.component.scss']
})
export class ClassroomPageComponent implements OnInit {
    selectedClassroom: Classroom;
    classrooms = new Array<Classroom>();

    constructor(private userService: UserService,
                private classroomService: ClassroomService) {
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.classroomService.getClassrooms().subscribe(rooms => {
            this.selectedClassroom = null;
            this.classrooms = rooms;
            this.addUsers();
        });
    }

    addUsers() {
        for (const a of this.classrooms) {
            for (const b of a.teachers) {
                if (b) {
                    if (a.displayUsername && a.displayUsername !== '') {
                        a.displayUsername += ', ' + b.firstName + ' ' + b.lastName;
                    } else {
                        a.displayUsername = b.firstName + ' ' + b.lastName;
                    }
                }
            }
        }
    }

    selectClassroom($event: Classroom) {
        console.log($event);
        this.selectedClassroom = $event;
    }

    closeEdit() {
        this.selectedClassroom = null;
    }

    addClassroom() {
        this.selectedClassroom = new Classroom();
    }

    deleteClassroom(classroom: Classroom) {
        this.classroomService.delete(classroom).subscribe(resp => {
        });
        this.init();
    }

    hasChanges() {
        this.init();
    }
}

