import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Room } from '../../_models/room';
import { User } from '../../_models/user';
import { AuthService } from '../../auth.service';
import { Utils } from '../../core/utils';
import { ChatService } from '../../services/chat.service';

@Component({
    selector: 'app-chat-contact',
    templateUrl: './chat-contact.component.html',
    styleUrls: ['./chat-contact.component.scss']
})
export class ChatContactComponent {
    allContacts: User[] = [];
    searchForm: FormGroup;
    contacts$: Observable<User[]>;
    contacts: Array<User> = new Array<User>();
    currentContact$ = new BehaviorSubject<User[]>([]);
    activeContact = new User();
    currentRoom: Room;

    private saving: boolean;
    private rooms: any;

    constructor(private auth: AuthService,
                private chatService: ChatService,
                private fb: FormBuilder) {

        this.searchForm = this.fb.group({
            search: [null]
        });

        this.contacts$ = merge(
            this.chatService.getAllContacts().pipe(
                filter(v => v.length > 0),
                tap(v => {
                    this.contacts = this.removeCurrentUser(v);
                    console.log(this.contacts);
                    this.allContacts = [...this.contacts];
                    this.currentContact$.next(this.contacts);
                })
            ),
            this.currentContact$.pipe(switchMap(v => of(v)),
                tap(v => {
                    this.contacts = v;
                    this.setActiveContact(this.contacts.filter(r => r.firstName === 'General')[0]);
                })
            )
        );

        this.searchForm.valueChanges.subscribe((values) => {
            console.log(values);
            if (values.search) {
                this.filterContactsByName(values.search);
            } else {
                this.contacts = [...this.allContacts];
            }
        });
    }

    isNewRoom(users: User[]) {
        return this.chatService.directChatExists(users);
    }

    createDirectChatWithUser() {
        if (this.activeContact?._id) {
            const users = [this.auth.userValue, this.activeContact];
            this.isNewRoom(users).subscribe((result) => {
                if (result.length > 0) {
                    this.chatService.activeRoom.next(result[0]);
                } else {
                    const tmpRoom: Room = new Room();
                    tmpRoom.users = users;
                    tmpRoom.name = 'REPLACE';
                    tmpRoom.topic = this.activeContact.firstName + ' - ' + this.auth.userValue.firstName;
                    if (tmpRoom && (!tmpRoom?._id || !Utils.isEmpty(tmpRoom._id))) {
                        this.saving = true;
                        this.chatService.createRoom(tmpRoom).subscribe(res => {
                            this.currentRoom = res;
                            this.chatService.activeRoom.next(res);
                            this.saving = false;
                        }, error => {
                            this.saving = false;
                        });
                    }
                }
            });
        }
    }

    setActiveContact(contact: User) {
        this.activeContact = new User(contact);
        this.createDirectChatWithUser();
    }

    private removeCurrentUser(v: any) {
        return v.filter(u => u.id !== this.auth.userValue.id);
    }

    private filterContactsByName(name: string) {
        this.contacts = this.allContacts.filter(u => (u.firstName + ' ' + u.lastName).toLowerCase().indexOf(name.toLowerCase()) > -1);
    }
}
